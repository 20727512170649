import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormRadioInputProps } from './FormInputProps';

export const FormInputRadio = ({ name, control, disabled, options }: FormRadioInputProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {options.map((o, index) => (
              <FormControlLabel key={index} value={o.value} control={<Radio />} label={o.label} disabled={disabled} />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
