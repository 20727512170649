import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import i18next from 'i18next';

interface ConfirmationDialogProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  keepOpenOnConfirm?: boolean;
  [x: string]: any;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { title, children, open, setOpen, onConfirm, keepOpenOnConfirm, ...rest } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog" {...rest}>
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="secondary">
          {i18next.t('confirmation-dialog-decline')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (!keepOpenOnConfirm) {
              setOpen(false);
            }
            onConfirm();
          }}
          color="primary"
        >
          {i18next.t('confirmation-dialog-confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
