import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient, useQuery } from '@apollo/client';
import { Alert, Button, Grid, CircularProgress } from '@mui/material';

import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { extractClientErrors } from 'helper/apollo';
import { allCountriesSorted } from 'languages';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputFileUpload } from 'components/form/FormInputFileUpload';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import { GroupedSpaceSelectionInput } from 'components/security/GroupedSpaceSelectionInput';

import { CREATE_SPACE_MUTATION, REFETCH_SPACES_QUERIES, EVICT_SPACES_QUERIES, USERS_LIST_QUERY, SEMPLANS_LIST_QUERY } from '../gql';
import { ProvisionSpaceOutput, User, SEMPlan, ELicenseSEMPlan } from '__generated__/graphql';
import { userSelector } from 'helper/security';
import { RedirectError } from 'pages/error';
interface NewSpaceFormProps {
  users: User[];
  plans: SEMPlan[];
}

const validationSchema = yup.object().shape({
  parentSpaceId: yup.number().nullable(),
  spaceName: yup.string().required().label(i18next.t('newspace-spaceName')),
  spaceCountry: yup.string().required().label(i18next.t('newspace-spaceCuntry')),
  userId: yup.number(),
  userName: yup
    .string()
    .when('userId', {
      is: 0,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable(),
    })
    .label(i18next.t('newspace-userName')),
  userUsername: yup
    .string()
    .when('userId', {
      is: 0,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable(),
    })
    .label(i18next.t('newspace-userUsername')),
  userEmail: yup
    .string()
    .when('userId', {
      is: 0,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable(),
    })
    .label(i18next.t('newspace-userEmail')),
  userPassword: yup.string().nullable(),
  hotelName: yup.string().nullable().label(i18next.t('newspace-hotelName')),
  hotelRefCode: yup.string(),
  hotelLogoFile: yup.mixed(),
  plan: yup.string().nullable(),
  importDefaultEmailTemplates: yup.boolean().required(),
  importDefaultOfferTemplates: yup.boolean().required(),
  importDefaultProducts: yup.boolean().required(),
  importDefaultPriceslist: yup.boolean().required(),
});

function NewSpaceForm(props: NewSpaceFormProps) {
  const dispatch = useDispatch();
  const user = userSelector()!;

  const [createMutateFunction] = useMutation(CREATE_SPACE_MUTATION);

  const [provisionResult, setProvisionResult] = useState({
    importMsg: undefined as string | undefined,
    result: undefined as ProvisionSpaceOutput | undefined,
  });

  type NewSpaceFormType = yup.InferType<typeof validationSchema>;
  const emptyForm = {
    parentSpaceId: user.space ? user.space.id : null,
    spaceName: '',
    spaceCountry: 'AT',
    userId: user.space ? user.id : 0,
    userName: '',
    userUsername: '',
    userEmail: '',
    userPassword: '',
    hotelName: '',
    hotelRefCode: '',
    plan: 'STARTER',
    importDefaultEmailTemplates: false,
    importDefaultOfferTemplates: false,
    importDefaultProducts: false,
    importDefaultPriceslist: false,
  };

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: { ...emptyForm },
  });

  const onSubmit = async (values: NewSpaceFormType) => {
    try {
      const fileAny = values.hotelLogoFile as any;

      const res = await createMutateFunction({
        variables: {
          data: {
            parentSpaceId: values.parentSpaceId || null,
            spaceName: values.spaceName,
            spaceCountry: values.spaceCountry,
            userId: values.userId || null,
            user: values.userId
              ? null
              : {
                  userName: values.userName!,
                  userUsername: values.userUsername!,
                  userEmail: values.userEmail!,
                  userPassword: values.userPassword || null,
                },
            hotelName: values.hotelName || null,
            hotelRefCode: values.hotelRefCode || null,
            hotelLogoBase64: fileAny ? fileAny.content : '',
            semPlan: user.isRoot && values.plan ? (values.plan as ELicenseSEMPlan) : null,
            importDefaultEmailTemplates: !!values.importDefaultEmailTemplates,
            importDefaultOfferTemplates: !!values.importDefaultOfferTemplates,
            importDefaultProducts: !!values.importDefaultProducts,
            importDefaultPriceslist: !!values.importDefaultPriceslist,
          },
        },
        update: cache => EVICT_SPACES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_SPACES_QUERIES(),
      });
      reset({ ...emptyForm });
      setProvisionResult({
        importMsg: `${i18next.t('newspace-ready')}`,
        result: res.data?.provisionSpace as any,
      });
      dispatchMessage(dispatch, i18next.t('newspace-ready'));
    } catch (err) {
      dispatchException(dispatch, err);
      const errs = extractClientErrors(err);
      setProvisionResult({
        importMsg: errs.join('\r\n'),
        result: undefined,
      });
    }
  };

  if (provisionResult.result) {
    return (
      <>
        <Helmet>
          <title>{i18next.t('newspace-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <Grid item xs={12}>
            {provisionResult.importMsg}
          </Grid>
          {provisionResult.result.user && (
            <>
              <Grid item xs={12}>
                {i18next.t('newspace-result-username')}: {provisionResult.result.user.username}
              </Grid>
              <Grid item xs={12}>
                {i18next.t('newspace-result-email')}: {provisionResult.result.user.email}
              </Grid>
            </>
          )}
          {provisionResult.result.initialPassword && (
            <>
              <Grid item xs={12}>
                {i18next.t('newspace-result-password')}: {provisionResult.result.initialPassword}
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{i18next.t('newspace-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <Grid item xs={12} sm={4}>
            <FormInputText name="spaceName" control={control} label={i18next.t('newspace-spaceName')} required />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SpaceSelectionInput name="parentSpaceId" control={control} label={i18next.t('newspace-parentspace')!} checkAdmin allowEmptyForRoot />
          </Grid>
          <Grid item xs={12} sm={4}>
            {user.isRoot && (
              <FormInputDropdown
                name="plan"
                control={control}
                label={i18next.t('newspace-license')}
                options={[
                  {
                    label: <em>{i18next.t('generic-selection-empty')}</em>,
                    value: null,
                  },
                  ...props.plans.map(p => ({
                    value: p.plan,
                    label: p.label,
                  })),
                ]}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputDropdown
              name="spaceCountry"
              control={control}
              label={i18next.t('newspace-spaceCountry')}
              options={[...allCountriesSorted.map(c => (c ? { value: c.code, label: c.label } : ({ divider: true } as any)))]}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText name="hotelName" control={control} label={i18next.t('newspace-hotelName')} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText name="hotelRefCode" control={control} label={i18next.t('newspace-hotelRefCode')} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputFileUpload
              control={control}
              label={i18next.t('newspace-import-file')}
              name="hotelLogoFile"
              accept="image/png"
              binary={() => true}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <GroupedSpaceSelectionInput
              name="userId"
              control={control}
              label={i18next.t('newspace-userid')}
              emptyLabel={`${i18next.t('newspace-newuser')}`}
              list={props.users}
              mapElement={e => `${e.name} (${e.email})`}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}></Grid>
          {watch('userId') == 0 && (
            <>
              <Grid item xs={12} sm={3}>
                <FormInputText name="userName" control={control} label={i18next.t('newspace-userName')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="userUsername" control={control} label={i18next.t('newspace-userUsername')} required />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="userEmail" control={control} label={i18next.t('newspace-userEmail')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="userPassword" control={control} label={i18next.t('newspace-userPassword')} />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={3}>
            <FormInputCheckbox name="importDefaultOfferTemplates" control={control} label={i18next.t('newspace-importDefaultOfferTemplates')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputCheckbox name="importDefaultEmailTemplates" control={control} label={i18next.t('newspace-importDefaultEmailTemplates')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputCheckbox name="importDefaultProducts" control={control} label={i18next.t('newspace-importDefaultProducts')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputCheckbox name="importDefaultPriceslist" control={control} label={i18next.t('newspace-importDefaultPriceslist')} />
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
              disabled={!isDirty || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('newspace-save')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default function NewSpace() {
  const usersQuery = useQuery(USERS_LIST_QUERY);
  const plansQuery = useQuery(SEMPLANS_LIST_QUERY);

  const loading = usersQuery.loading || plansQuery.loading;
  const error = usersQuery.error || plansQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <NewSpaceForm users={usersQuery.data!.listUsers as User[]} plans={plansQuery.data!.listSEMPlans as SEMPlan[]} />;
}
