import React from 'react';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

import i18next from 'i18next';

import SimpleTable from 'components/table/SimpleTable';
import FormatPrice from 'components/Price';
import { ProductPriceListOutput } from '__generated__/graphql';

interface PriceTableProps {
  sku: string;
  prices: ProductPriceListOutput[];
}

export default function PriceTable(props: PriceTableProps) {
  const { prices, sku } = props;
  return (
    <SimpleTable
      headers={[
        i18next.t('productprices-pricelist'),
        i18next.t('productprices-version'),
        i18next.t('productprices-ispublished'),
        i18next.t('productprices-selector'),
        i18next.t('productprices-price'),
      ]}
      rows={prices.map(price => [
        <Link to={`/pricelists/pricelists/${price.selector.priceList.id}`}>{price.selector.priceList.name}</Link>,
        price.selector.priceList.version,
        price.selector.priceList.isPublished && <CheckIcon />,
        <Link to={`/pricelists/pricelists/${price.selector.priceList.id}/selectors/${price.selector.id}#${sku}`}>{price.selector.name}</Link>,
        price.price !== undefined && price.price !== null && <FormatPrice price={price.price!} currency={price.selector.priceList.currency} />,
      ])}
    />
  );
}
