import React from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';

export const FormInputCheckbox = ({ name, control, label, disabled, readOnly }: FormInputProps) => {
  return label ? (
    <FormControl>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => <Checkbox checked={!!value} onChange={onChange} disabled={disabled || readOnly} />}
          />
        }
        label={label}
        disabled={disabled || readOnly}
      />
    </FormControl>
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => <Checkbox checked={!!value} onChange={onChange} disabled={disabled || readOnly} />}
    />
  );
};
