import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Grid, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { SERVICETYPES_LIST_QUERY } from '../gql';

import { ServiceTypeCopyButton, ServiceTypeDeleteButton } from './servicetype';

export default function ServiceTypesList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(SERVICETYPES_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('servicetypes-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  hotelId: (filter && filter.hotelId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('servicetypes-list-header-type'),
              i18next.t('servicetypes-list-header-sku'),
              i18next.t('servicetypes-list-header-sequence'),
              i18next.t('servicetypes-list-header-ispublished'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              '',
            ]}
            rows={(data?.listServiceTypes || []).map(row => [
              <Link to={`/servicetypes/${row.id}`}>{row.name}</Link>,
              row.sku,
              row.sequence,
              row.isPublished && <CheckIcon />,
              ...(user.isSingleSpace ? [] : [row.space.name]),
              <ButtonGroup>
                <ServiceTypeCopyButton id={row.id} spaceId={row.space.id} icon />
                <ServiceTypeDeleteButton id={row.id} spaceId={row.space.id} icon />
              </ButtonGroup>,
            ])}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/servicetypes/_create')}>
              {i18next.t('servicetypes-list-add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
