import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';

import Availability from './availability';
import HotelSelection from './hotelselection';

export default function AvailabilityIndex() {
  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route index element={<HotelSelection />} />
              <Route path="hotel/:id" Component={() => <Availability hotelId={parseInt(useParams().id!)} />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
