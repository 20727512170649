import React, { useEffect } from 'react';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import i18next from 'i18next';
import {
  CONTENTMEDIAS_LIST_QUERY,
  EVICT_CONTENTMEDIA_QUERIES,
  IMPORT_CONTENTMEDIA_MUTATION,
  REFETCH_CONTENTMEDIA_QUERIES,
  UPLOAD_CONTENTMEDIA_MUTATION,
} from 'pages/content/gql';
import { useMutation, useQuery } from '@apollo/client';
import SimpleTable from 'components/table/SimpleTable';
import FileUploadButton from 'components/FileUploadButton';
import { userSelector } from 'helper/security';
import { filterSelector } from 'helper/filter';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { useDispatch } from 'react-redux';
import UploadIcon from '@mui/icons-material/Upload';
import { ContentMediaListOutput } from '__generated__/graphql';

export interface MediaSelectionDialogOutput {
  id: number;
  name: string;
  previewUrl: string;
}

interface MediaSelectionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelect: (medias: MediaSelectionDialogOutput[]) => void;
  keepOpenOnConfirm?: boolean;
  [x: string]: any;
}

export default function MediaSelectionDialog(props: MediaSelectionDialogProps) {
  const { title, children, open, setOpen, onOk, keepOpenOnConfirm, onSelect, ...rest } = props;
  const mediaListQuery = useQuery(CONTENTMEDIAS_LIST_QUERY);

  const [selected, setSelected] = React.useState<MediaSelectionDialogOutput[]>([]);

  const filter = filterSelector();
  const user = userSelector()!;

  const canUpload = user.isAdmin;

  const [uploadMutation, { loading: uploadLoading }] = useMutation(UPLOAD_CONTENTMEDIA_MUTATION);
  const [importMutationFunction, { loading: importLoading }] = useMutation(IMPORT_CONTENTMEDIA_MUTATION);

  const importToSpaceId = (filter && filter.spaceId) || user.space?.id;

  const dispatch = useDispatch();

  const handleCheckboxChange = (id: number) => {
    if (selected.map(s => s.id).includes(id)) {
      setSelected(selected.filter(s => s.id !== id));
    } else {
      setSelected([
        ...selected,
        mediaListQuery.data?.listContentMedia
          .map(m => ({
            id: m.id,
            name: m.name,
            previewUrl: m.previewUrl,
          }))
          .find(m => m.id === id)!,
      ]);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog" {...rest}>
      <DialogTitle id="confirm-dialog">{i18next.t('media-selection-dialog-title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item xs={12}>
            {mediaListQuery.loading ? (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              children
            )}
            {mediaListQuery.error && (
              <Grid container justifyContent="center">
                <Button variant="contained" onClick={() => mediaListQuery.refetch()} color="primary">
                  {i18next.t('common.retry')}
                </Button>
              </Grid>
            )}
            {mediaListQuery.data && mediaListQuery.data.listContentMedia && mediaListQuery.data.listContentMedia.length === 0 && (
              <Grid container justifyContent="center">
                <Button variant="contained" onClick={() => mediaListQuery.refetch()} color="primary">
                  {i18next.t('common.retry')}
                </Button>
              </Grid>
            )}
            {mediaListQuery.data && mediaListQuery.data.listContentMedia && mediaListQuery.data.listContentMedia.length > 0 && (
              <Grid container justifyContent="center">
                <SimpleTable
                  headers={[i18next.t('contentmedia-name'), i18next.t('contentmedia-type'), '']}
                  rows={mediaListQuery.data.listContentMedia.map(item => [
                    <Checkbox checked={selected.map(s => s.id).includes(item.id)} onChange={() => handleCheckboxChange(item.id)} />,
                    item.name,
                    <img src={item.previewUrl} alt={item.name} style={{ width: 100 }} />,
                  ])}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            {canUpload && importToSpaceId && (
              <FileUploadButton
                name="importMedia"
                disabled={importLoading || !canUpload}
                startIcon={importLoading ? <CircularProgress size={24} /> : <UploadIcon />}
                binary={(name, type) => (type === 'application/json' ? false : true)}
                onChange={async (name, type, content) => {
                  try {
                    if (type === 'application/json') {
                      const res = await importMutationFunction({
                        variables: {
                          spaceId: importToSpaceId,
                          jsonText: content,
                        },
                        update: cache => EVICT_CONTENTMEDIA_QUERIES(cache),
                        awaitRefetchQueries: true,
                        refetchQueries: REFETCH_CONTENTMEDIA_QUERIES(),
                      });
                    } else {
                      const res = await uploadMutation({
                        variables: {
                          spaceId: importToSpaceId,
                          filename: name,
                          mimeType: type,
                          base64: content,
                        },
                        update: cache => EVICT_CONTENTMEDIA_QUERIES(cache),
                        awaitRefetchQueries: true,
                        refetchQueries: REFETCH_CONTENTMEDIA_QUERIES(),
                      });
                    }
                    dispatchMessage(dispatch, i18next.t('contentmedias-import-ready'));
                  } catch (err) {
                    dispatchException(dispatch, err);
                  }
                }}
              >
                {i18next.t('contentmedias-import-run')}
              </FileUploadButton>
            )}
            <Button variant="contained" onClick={() => mediaListQuery.refetch()} color="primary">
              {i18next.t('refresh')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={() => {
                if (!keepOpenOnConfirm) {
                  setOpen(false);
                }
                onSelect(selected);
                setSelected([]);
              }}
              color="primary"
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
