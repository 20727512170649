import React, { useEffect, useRef, useState } from 'react';
import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormRadioInputProps } from './FormInputProps';
import { Editor } from '@tinymce/tinymce-react';
import { FormLabel } from './FormLabel';
import { set } from 'lodash';
import { CONTENTMEDIAS_LIST_QUERY } from 'pages/content/gql';
import { useQuery } from '@apollo/client';

interface FormRichTextEditorProps {
  name: string;
  options: any;
  control: any;
  label: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
}

export const FormInputRichText = ({ name, control, label, disabled, options, placeholder }: FormRichTextEditorProps) => {
  const { watch, setValue, getValues } = useFormContext();
  const [value, setValueState] = useState<any>(watch(name));
  const [curPos, setCurPos] = useState<any>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const { loading: mediaLoading, error: mediaError, data: mediaData } = useQuery(CONTENTMEDIAS_LIST_QUERY, { variables: {} });

  useEffect(() => {
    if (editor && curPos) {
      editor.selection.moveToBookmark(curPos);
    }
  }, [value]);

  const [editor, setEditor] = useState<any>(null);

  if (mediaLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <br />
      <br />
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Editor
              disabled={disabled}
              tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.7.0/tinymce.min.js"
              onEditorChange={val => {
                if (editorLoaded) {
                  setCurPos(editor.selection.getBookmark(2));
                  setValue(name, val);
                  setValueState(val);
                  field.onChange(val);
                } else {
                  setEditorLoaded(true);
                }
              }}
              onChange={() => {
                setCurPos(editor.selection.getBookmark(2));
                setValue(name, editor.getContent());
                setValueState(editor.getContent());
                field.onChange(editor.getContent());
                if (editor.queryCommandState('ToggleToolbarDrawer')) {
                  editor.execCommand('ToggleToolbarDrawer');
                }
              }}
              initialValue={getValues(name) || ''}
              init={Object.assign(
                {},
                {
                  setup: (editor: any) => {
                    setEditor(editor);
                  },
                  toolbar_mode: 'wrap',
                  placeholder: placeholder,
                  language: 'de',
                  language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/de.js',
                  height: 350,
                  menubar: false,
                  remove_linebreaks: false,
                  cleanup: false,
                  verify_html: false,
                  allow_html_in_named_anchor: true,
                  valid_elements: '*[*]',
                  extended_valid_elements: '*[*]',
                  image_list: mediaData?.listContentMedia.map((media: any) => ({
                    title: media.name,
                    value: media.previewUrl,
                  })),
                  plugins: [
                    'code',
                    'table',
                    'link',
                    'image',
                    'insertdatetime',
                    'lists',
                    'advlist',
                    'autolink',
                    'table',
                    'textcolor',
                    'colorpicker',
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'formatselect | ' +
                    'bold italic underline forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | link image table | ' +
                    'code | removeformat',
                  content_style: 'body { font-family:Arial,sans-serif; font-size:14px }',
                },
                options,
              )}
            />
          </>
        )}
      />
    </FormControl>
  );
};
