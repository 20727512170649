import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, ListSubheader, Divider, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormSelectInputProps, FormSelectOption } from './FormInputProps';

export const buildMenuItemsFromOptions = (options: FormSelectOption[], onSelect?: (value?: any) => any) => {
  return options.map((option, index) => {
    if (option.divider) {
      return <ListSubheader key={index}>{option.label || <Divider />}</ListSubheader>;
    } else {
      return (
        <MenuItem
          key={index}
          disabled={!!option.disabled}
          value={option.value}
          {...(!option.disabled && onSelect ? { onClick: () => onSelect(option.value) } : {})}
        >
          {option.label}
        </MenuItem>
      );
    }
  });
};

export const FormInputDropdown = ({ name, control, label, disabled, options, helperText, onSelected }: FormSelectInputProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              fullWidth
              label={label}
              {...(typeof disabled !== 'undefined' ? { disabled } : {})}
              labelId={`${name}-label`}
              onChange={(...args) => {
                onChange(...args);
                if (onSelected) {
                  onSelected(args[0].target.value);
                }
              }}
              error={!!error}
              value={value}
            >
              {buildMenuItemsFromOptions(options)}
            </Select>
            <FormHelperText error={!!error}>{error ? error.message : helperText ? helperText : ' '}</FormHelperText>
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};

interface SimpleDropdownProps {
  value: any;
  name: string;
  label: string;
  disabled?: boolean;
  options: FormSelectOption[];
  helperText?: string;
  onChange: (value: any) => void;
}

export const SimpleDropdown = ({ value, name, label, disabled, options, helperText, onChange }: SimpleDropdownProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        fullWidth
        label={label}
        {...(typeof disabled !== 'undefined' ? { disabled } : {})}
        labelId={`${name}-label`}
        onChange={onChange}
        value={value}
      >
        {buildMenuItemsFromOptions(options)}
      </Select>
      <FormHelperText>{helperText || ' '}</FormHelperText>
    </FormControl>
  );
};
