import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Grid, CircularProgress, IconButton, Select, MenuItem } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoginIcon from '@mui/icons-material/Login';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { buildMenuItemsFromOptions } from 'components/form/FormInputDropdown';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';

import {
  USER_VIEW_QUERY,
  UPDATE_USER_MUTATION,
  CREATE_USER_MUTATION,
  REFETCH_USERS_QUERIES,
  EVICT_USERS_QUERIES,
  RESET_USERPASSWORD_MUTATION,
  SUDO_MUTATION,
} from '../gql';
import { ME_QUERY } from 'layout/login';

import { User } from '__generated__/graphql';
import { userSelector, user2IUser, canEditAdminRecord, initialSpaceId } from 'helper/security';
import { setUser } from 'store/userSlice';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';

interface UserProps {
  id: number;
}
interface UserCreateProps {}
interface UserFormProps {
  data: User;
}

const membershipsSchema = yup.object().shape({
  spaceId: yup.number().required().label(i18next.t('field-space')),
  isAdmin: yup.boolean().required(),
});

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  username: yup.string().required().label(i18next.t('user-username')),
  email: yup.string().required().label(i18next.t('user-email')),
  name: yup.string().required().label(i18next.t('user-name')),
  isAdmin: yup.boolean(),
  isLocked: yup.boolean(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  memberships: yup.array().required().of(membershipsSchema),
});

function UserForm(props: UserFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data) || props.data.id === user.id;

  const [updateMutateFunction] = useMutation(UPDATE_USER_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_USER_MUTATION);
  const [resetMutateFunction, { loading: resetLoading }] = useMutation(RESET_USERPASSWORD_MUTATION);
  const [sudoMutation] = useMutation(SUDO_MUTATION);
  const [meQuery] = useLazyQuery(ME_QUERY);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/settings/security/users/${createdId}`);
  }, [createdId]);

  type UserFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: User): UserFormType => ({
    ...obj,
    isCreate: props.data.id > 0 ? false : true,
    isAdmin: !!obj.isAdmin,
    isLocked: !!obj.isLocked,
    spaceId: obj.space ? obj.space.id : 0,
    memberships: obj.memberships
      .filter(m => obj.space && obj.space.id !== m.space.id)
      .map(m => ({
        spaceId: m.space.id,
        isAdmin: m.isAdmin,
      })),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client },
    defaultValues: toFormSchema((props.data || {}) as User),
  });
  const formValues = getValues();

  const {
    fields: membershipsField,
    append: membershipsAppend,
    remove: membershipsRemove,
  } = useFieldArray({
    control,
    name: 'memberships',
  });

  const onSubmit = async (values: UserFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              email: values.email,
              isAdmin: values.isAdmin,
              isLocked: values.isLocked,
              memberships: values.spaceId
                ? values.memberships.map(m => ({
                    spaceId: m.spaceId,
                    isAdmin: m.isAdmin,
                  }))
                : [],
            },
          },
          update: cache => EVICT_USERS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_USERS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateUser || {}) as User));
        dispatchMessage(dispatch, i18next.t('user-updated'));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId || null,
            data: {
              name: values.name,
              username: values.username,
              email: values.email,
              isAdmin: values.isAdmin,
              isLocked: values.isLocked,
              memberships: values.spaceId
                ? values.memberships.map(m => ({
                    spaceId: m.spaceId,
                    isAdmin: m.isAdmin,
                  }))
                : [],
            },
          },
          update: cache => EVICT_USERS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_USERS_QUERIES(),
        });
        reset(toFormSchema((res.data!.createUser || {}) as User));
        setCreatedId(res.data!.createUser.id);
        dispatchMessage(dispatch, i18next.t('user-created'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('users-list-page-title')} {props.data.id > 0 ? props.data.name : ''}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={4}>
          <FormInputText name="name" control={control} label={i18next.t('user-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputText name="username" control={control} label={i18next.t('user-username')} disabled={props.data.id > 0} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputText name="email" control={control} label={i18next.t('user-email')} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputCheckbox name="isAdmin" control={control} label={i18next.t('user-isadmin')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputCheckbox name="isLocked" control={control} label={i18next.t('user-islocked')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            required
          />
        </Grid>
        {!user.isSingleSpace && (canEdit || membershipsField.length > 0) && (
          <Grid item xs={12}>
            <CustomTabs
              headers={[i18next.t('user-memberships-tab')]}
              tabs={[
                <>
                  {membershipsField.length > 0 && (
                    <SimpleTable
                      headers={[i18next.t('user-membership-space'), i18next.t('user-membership-isadmin'), '']}
                      rows={membershipsField.map((field, index) => [
                        user.spaces.find(s => s.id === field.spaceId)?.name,
                        <FormInputCheckbox
                          key={`${field.id}.isAdmin`}
                          name={`memberships.${index}.isAdmin`}
                          control={control}
                          label={i18next.t('user-membership-isadmin')}
                          disabled={!canEdit}
                        />,
                        canEdit && (
                          <IconButton onClick={() => membershipsRemove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        ),
                      ])}
                    />
                  )}
                  {canEdit && (
                    <Select
                      value={0}
                      displayEmpty
                      onChange={event => {
                        if (!event.target.value) return;
                        if (props.data.space && props.data.space.id === event.target.value) return;
                        if (getValues('memberships').findIndex(m => m.spaceId === event.target.value) >= 0) return;
                        membershipsAppend({
                          spaceId: event.target.value as number,
                          isAdmin: true,
                        });
                      }}
                    >
                      <MenuItem value={0}>
                        <em>{i18next.t('user-membership-add')}</em>
                      </MenuItem>
                      {buildMenuItemsFromOptions(
                        buildSpaceSelectionOptions(user, true).map(s => {
                          if (s.value === watch('spaceId')) {
                            s.disabled = true;
                          }
                          return s;
                        }),
                      )}
                    </Select>
                  )}
                </>,
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {canEdit && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              confirmationQuestion={formValues.isLocked ? i18next.t('user-confirm-islocked') : null}
              confirmationTitle={formValues.isLocked ? i18next.t('user-confirm-islocked-title') : null}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t(props.data.id > 0 ? 'user-update' : 'user-create')}
            </ConfirmationButton>
          )}
          {props.data.id > 0 && canEdit && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={resetLoading ? <CircularProgress size={24} /> : <LockResetIcon />}
              disabled={resetLoading}
              confirmationQuestion={i18next.t('user-confirm-reset')}
              confirmationTitle={i18next.t('user-confirm-reset-title')}
              icon={false}
              onConfirm={async () => {
                try {
                  const res = await resetMutateFunction({
                    variables: {
                      id: props.data.id,
                    },
                  });
                  dispatchMessage(dispatch, i18next.t('user-resetted'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('user-reset')}
            </ConfirmationButton>
          )}
          {props.data.id > 0 && user.isRoot && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={<LoginIcon />}
              confirmationQuestion={i18next.t('user-confirm-sudo')}
              confirmationTitle={i18next.t('user-confirm-sudo-title')}
              onConfirm={async () => {
                try {
                  const res = await sudoMutation({
                    variables: { id: props.data.id },
                  });
                  const meRes = await meQuery({ fetchPolicy: 'network-only' });

                  if (res && res.data && meRes && meRes.data) {
                    if (res.data.sudo.success && res.data.sudo.user) {
                      const user = res.data.sudo.user;
                      dispatch(setUser(user2IUser(user, meRes.data.listSpaces, meRes.data.listHotels)));
                      await client.clearStore();
                      window.location.href = '/';
                    } else {
                      dispatchException(dispatch, `${i18next.t('user-sudo-failed')}`);
                    }
                  }
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('user-sudo')}
            </ConfirmationButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default function UserUpdate(props: UserProps) {
  const userQuery = useQuery(USER_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = userQuery.loading;
  const error = userQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <UserForm data={userQuery.data!.viewUser as User} />;
}

export function UserCreate(props: UserCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <UserForm
      data={{
        id: -1,
        username: '',
        name: '',
        email: '',
        isAdmin: false,
        isLocked: false,
        createdAt: null,
        updatedAt: null,
        memberships: [],
        space: { id: initialSpaceId(user, filter) } as any,
      }}
    />
  );
}
