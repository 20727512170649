import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, Grid, CircularProgress, Box } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import { userSelector, initialSpaceId } from 'helper/security';
import { HotelSelectionInput } from 'components/security/HotelSelectionInput';
import SimpleTable from 'components/table/SimpleTable';
import { filterSelector } from 'helper/filter';

import {
  INTEGRATION_HOTEL_CONNECTOPTIONS_QUERY,
  CREATE_INTEGRATION_HOTEL_MUTATION,
  REFETCH_INTEGRATIONS_HOTELS_QUERIES,
  EVICT_INTEGRATION_HOTELS_QUERIES,
  INTEGRATION_PROPERTIES_QUERY,
  INTEGRATION_SECRETS_LIST_QUERY,
} from './gql';
import { HOTEL_LIST_QUERY } from '../gql';
import yup from 'validation';
import { IntegrationSecretOutput, HotelListOutput } from '__generated__/graphql';
import { canPMSLinkProperty, canPMSLinkInventory } from './pmssupport';
import { RedirectError } from 'pages/error';

interface HotelConnectProps {
  secretId?: number | null;
  hotelId?: number | null;
}

interface HotelConnectFormProps {
  initSecretId?: number | null;
  initHotelId?: number | null;
  secrets: IntegrationSecretOutput[];
  hotels: HotelListOutput[];
}

const mappingSchema = yup.object().shape({
  include: yup.boolean().required(),
  name: yup.string().required(),
  extRefCode: yup.string().required(),
  extPricelistRefCode: yup
    .string()
    .label(i18next.t('integration-hotel-pms-pricelist'))
    .test((value, context) => {
      if (context.parent.include) return !!value;
      return true;
    }),
});

const validationSchema = yup
  .object()
  .shape({
    isNewHotel: yup.boolean().required(),
    spaceId: yup.number().nullable(),
    hotelId: yup.number().nullable(),
    secretId: yup.number().required().label(i18next.t('integration-hotel-secret')),
    extHotelRefCode: yup.string().nullable().label(i18next.t('integration-hotel-pmsproperty')),
    products: yup.array().of(mappingSchema),
    facilities: yup.array().of(mappingSchema),
  })
  .test(
    'integration-hotel-new',
    `${i18next.t('integration-hotel-new')}`,
    value => !!(value.isNewHotel && value.spaceId && value.spaceId > 0) || !!(!value.isNewHotel && value.hotelId && value.hotelId > 0),
  );

function HotelConnectForm(props: HotelConnectFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const [propertiesQuery, { data: propertiesData, loading: propertiesLoading }] = useLazyQuery(INTEGRATION_PROPERTIES_QUERY);
  const [optionsQuery, { data: optionsData, loading: optionsLoading }] = useLazyQuery(INTEGRATION_HOTEL_CONNECTOPTIONS_QUERY);
  const [createMutateFunction] = useMutation(CREATE_INTEGRATION_HOTEL_MUTATION);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/settings/integration/hotels/${createdId}`);
  }, [createdId]);

  type CreateFormType = yup.InferType<typeof validationSchema>;
  type MappingType = yup.InferType<typeof mappingSchema>;

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: {
      isNewHotel: !props.initHotelId,
      secretId: props.initSecretId || (props.secrets && props.secrets.length > 0 && props.secrets[0].id) || -1,
      hotelId: props.initHotelId || (props.hotels && props.hotels.length > 0 && props.hotels[0].id) || -1,
      spaceId: initialSpaceId(user, filter) || -1,
      extHotelRefCode: '',
      products: [] as MappingType[],
      facilities: [] as MappingType[],
    },
  });

  const _selectedSecretSystem = () => props.secrets.find(s => s.id === getValues('secretId'))?.system;

  const {
    fields: productsFields,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control,
    name: 'products',
  });
  const {
    fields: facilitiesFields,
    append: facilitiesAppend,
    remove: facilitiesRemove,
  } = useFieldArray({
    control,
    name: 'facilities',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    const secretId = getValues('secretId');
    if (!secretId) return;

    const secret = props.secrets.find(s => s.id === secretId);
    if (!secret || !canPMSLinkProperty(secret.system)) return;

    propertiesQuery({
      variables: { secretId },
      fetchPolicy: 'network-only',
    })
      .then(res => {
        if (res.error) {
          dispatchException(dispatch, res.error);
        } else if (res.data?.listPMSIntegrationProperties && res.data?.listPMSIntegrationProperties.length > 0) {
          setValue('extHotelRefCode', res.data?.listPMSIntegrationProperties[0].extRefCode);
        }
      })
      .catch(err => {
        dispatchException(dispatch, err);
      });
  }, [watch('secretId')]);

  useEffect(() => {
    const extHotelRefCode = getValues('extHotelRefCode');
    if (!extHotelRefCode) return;

    const secretId = getValues('secretId');
    if (!secretId) return;

    const secret = props.secrets.find(s => s.id === secretId);
    if (!secret || !canPMSLinkInventory(secret.system)) return;

    optionsQuery({
      variables: { secretId, extHotelRefCode },
    })
      .then(res => {
        if (res.error) {
          dispatchException(dispatch, res.error);
        } else {
          setValue(
            'products',
            res.data?.readHotelConnectOptions.products.map(p => ({
              include: p.extPricelistOptions && p.extPricelistOptions.length > 0,
              name: p.name,
              extRefCode: p.extRefCode,
              extPricelistRefCode: p.extPricelistOptions && p.extPricelistOptions.length > 0 ? p.extPricelistOptions[0].extRefCode : '',
            })) || [],
          );
          setValue(
            'facilities',
            res.data?.readHotelConnectOptions.facilities.map(f => ({
              include: f.extPricelistOptions && f.extPricelistOptions.length > 0,
              name: f.name,
              extRefCode: f.extRefCode,
              extPricelistRefCode: f.extPricelistOptions && f.extPricelistOptions.length > 0 ? f.extPricelistOptions[0].extRefCode : '',
            })) || [],
          );
          trigger();
        }
      })
      .catch(err => {
        dispatchException(dispatch, err);
      });
  }, [watch('extHotelRefCode')]);

  const onSubmit = async (values: CreateFormType) => {
    const selectedSystem = _selectedSecretSystem();
    const pmsLink = canPMSLinkProperty(selectedSystem);
    const invLink = canPMSLinkInventory(selectedSystem);

    try {
      const res = await createMutateFunction({
        variables: {
          extHotelRefCode: values.extHotelRefCode,
          secretId: values.secretId,
          ...(pmsLink ? (values.isNewHotel ? { spaceId: values.spaceId } : { hotelId: values.hotelId }) : {}),
          ...(!pmsLink ? { hotelId: values.hotelId } : {}),
          connectArgs: invLink
            ? {
                products: (values.products || [])
                  .filter(p => p.include)
                  .map(p => ({ extRefCode: p.extRefCode, extPricelistRefCode: p.extPricelistRefCode })),
                facilities: (values.facilities || [])
                  .filter(f => f.include)
                  .map(f => ({ extRefCode: f.extRefCode, extPricelistRefCode: f.extPricelistRefCode })),
              }
            : undefined,
        },
        update: cache => EVICT_INTEGRATION_HOTELS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_INTEGRATIONS_HOTELS_QUERIES(),
      });
      setCreatedId(res.data!.createIntegrationHotel.id);
      dispatchMessage(dispatch, i18next.t('integration-hotel-created'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const _mappingProductField = (id: string, index: number) => {
    const options = optionsData?.readHotelConnectOptions.products[index]?.extPricelistOptions || [];
    if (options.length > 0) {
      return (
        <FormInputDropdown
          key={`${id}.name`}
          name={`products.${index}.extPricelistRefCode`}
          control={control}
          label={i18next.t('integration-hotel-pms-pricelist')}
          options={options.map(p => ({
            value: p.extRefCode,
            label: p.name,
          }))}
          disabled={!watch(`products.${index}.include`)}
          required
        />
      );
    } else {
      return (
        <FormInputText
          key={`${id}.name`}
          name={`products.${index}.extPricelistRefCode`}
          control={control}
          label={i18next.t('integration-hotel-pms-pricelist')}
          disabled={!watch(`products.${index}.include`)}
          required
        />
      );
    }
  };
  const _mappingFacilityField = (id: string, index: number) => {
    const options = optionsData?.readHotelConnectOptions.facilities[index]?.extPricelistOptions || [];
    if (options.length > 0) {
      return (
        <FormInputDropdown
          key={`${id}.name`}
          name={`facilities.${index}.extPricelistRefCode`}
          control={control}
          label={i18next.t('integration-hotel-pms-pricelist')}
          options={options.map(p => ({
            value: p.extRefCode,
            label: p.name,
          }))}
          disabled={!watch(`facilities.${index}.include`)}
          required
        />
      );
    } else {
      return (
        <FormInputText
          key={`${id}.name`}
          name={`facilities.${index}.extPricelistRefCode`}
          control={control}
          label={i18next.t('integration-hotel-pms-pricelist')}
          disabled={!watch(`facilities.${index}.include`)}
          required
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{i18next.t('integration-hotels-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="secretId"
            control={control}
            label={i18next.t('integration-hotel-secret')}
            options={props.secrets.map(s => ({
              value: s.id,
              label: `${s.name} - ${i18next.t('enums-EIntSystem-' + s.system)}`,
            }))}
          />
        </Grid>
        {canPMSLinkProperty(_selectedSecretSystem()) && (
          <>
            <Grid item xs={12} sm={6}>
              {propertiesLoading && <CircularProgress />}
              {!propertiesLoading && (
                <FormInputDropdown
                  name="extHotelRefCode"
                  control={control}
                  label={i18next.t('integration-hotel-pmsproperty')}
                  options={(propertiesData?.listPMSIntegrationProperties || []).map(p => ({
                    value: p.extRefCode,
                    label: `${p.name} - ${p.extRefCode}`,
                  }))}
                  required
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormInputCheckbox name="isNewHotel" control={control} label={i18next.t('integration-hotel-new')} />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              {watch('isNewHotel') && (
                <SpaceSelectionInput
                  checkAdmin
                  name="spaceId"
                  control={control}
                  disabled={user.isSingleAdminSpace}
                  required
                  helperText={i18next.t('integration-hotel-space-help')}
                />
              )}
              {!watch('isNewHotel') && (
                <HotelSelectionInput
                  label={i18next.t('integration-hotel-hotel-select')}
                  name="hotelId"
                  control={control}
                  hotels={props.hotels}
                  helperText={i18next.t('integration-hotel-hotel-help')}
                />
              )}
            </Grid>
          </>
        )}
        {!canPMSLinkProperty(_selectedSecretSystem()) && (
          <>
            <Grid item xs={12} sm={6}>
              <HotelSelectionInput label={i18next.t('integration-hotel-hotel-select')} name="hotelId" control={control} hotels={props.hotels} />
            </Grid>
          </>
        )}

        {canPMSLinkInventory(_selectedSecretSystem()) && (
          <>
            <Grid item xs={12}>
              {optionsLoading && <CircularProgress />}
            </Grid>
            {!optionsLoading && (
              <>
                <Grid item sm={6}>
                  {productsFields.length > 0 && (
                    <SimpleTable
                      rows={productsFields.map((field, index) => [
                        <FormInputCheckbox
                          key={`${field.id}.include`}
                          name={`products.${index}.include`}
                          control={control}
                          label={i18next.t('integration-hotel-pms-include')}
                        />,
                        <FormInputText
                          key={`${field.id}.name`}
                          name={`products.${index}.name`}
                          control={control}
                          label={i18next.t('integration-hotel-pms-product-name')}
                          disabled
                        />,
                        _mappingProductField(field.id, index),
                      ])}
                    />
                  )}
                </Grid>
                <Grid item sm={6}>
                  {facilitiesFields.length > 0 && (
                    <SimpleTable
                      rows={facilitiesFields.map((field, index) => [
                        <FormInputCheckbox
                          key={`${field.id}.include`}
                          name={`facilities.${index}.include`}
                          control={control}
                          label={i18next.t('integration-hotel-pms-include')}
                        />,
                        <FormInputText
                          key={`${field.id}.name`}
                          name={`facilities.${index}.name`}
                          control={control}
                          label={i18next.t('integration-hotel-pms-facility-name')}
                          disabled
                        />,
                        _mappingFacilityField(field.id, index),
                      ])}
                    />
                  )}
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={isSubmitting || isValidating || optionsLoading}
            onClick={async () => {
              const valid = await trigger();
              if (valid) {
                handleSubmit(onSubmit)();
              }
            }}
          >
            {i18next.t('integration-hotel-create')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function HotelConnect(props: HotelConnectProps) {
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);
  const secretsQuery = useQuery(INTEGRATION_SECRETS_LIST_QUERY);

  const loading = secretsQuery.loading || hotelsQuery.loading;
  const error = secretsQuery.error || hotelsQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <HotelConnectForm
        initSecretId={props.secretId}
        secrets={secretsQuery.data!.listIntegrationSecrets as IntegrationSecretOutput[]}
        initHotelId={props.hotelId}
        hotels={hotelsQuery.data!.listHotels as HotelListOutput[]}
      />
    );
}
