import _ from 'lodash';

export type ProductDetailsSelectorInput = {
  sku: string;
  hotelId: number | null;
  position: string;
  language: string | null;
};

export interface IProductDetails {
  id: number;
  sku: string;
  position: string;
  hotelId: number | null;
  texts: IText[];
  medias: IMedia[];
  spaceId: number;
}
export interface IText {
  language: string | null;
  text: string;
}
export interface IMedia {
  language: string | null;
  mediaId: number;
  sequence: number;
  usage: string;
}

export type ProductTextSelectorType = ProductTextSelector<IProductDetails>;

export class ProductTextSelector<ProductDetailsType extends IProductDetails> {
  protected productDetails: ProductDetailsType[] | null = null;

  public hydrate(json: string) {
    const { productDetails } = JSON.parse(json);
    this.productDetails = productDetails;
  }
  public dehydrate(): string {
    return JSON.stringify({
      productDetails: this.productDetails,
    });
  }

  private _isEmpty = (str?: string | null) => str === undefined || str === null || str.trim() === '';

  public getProductMedias(input: ProductDetailsSelectorInput) {
    let mediaCandidates = _.takeWhile(this.productDetails!.filter(
      p => p.sku === input.sku && p.position === input.position && (input.hotelId ? !p.hotelId || p.hotelId === input.hotelId : true),
    ), (pd, i, a) => i === 0 ? true : a[i-1].spaceId === pd.spaceId)
      .map(c =>
        c.medias.filter(
          m => this._isEmpty(input.language) || this._isEmpty(m.language) || m.language!.toUpperCase() === input.language!.toUpperCase(),
        ),
      )
      .reduce((a, m) => [...a, ...m], []);

    return mediaCandidates;
  }

  public getProductText(input: ProductDetailsSelectorInput): string | null {
    let textCandidates = _.orderBy(
      _.takeWhile(this.productDetails!.filter(
        p => p.sku === input.sku && p.position === input.position && (input.hotelId ? !p.hotelId || p.hotelId === input.hotelId : true),
      ), (pd, i, a) => i === 0 ? true : a[i-1].spaceId === pd.spaceId),
      p => (p.hotelId ? 1 : 0),
      'desc',
    )
      .map(c =>
        c.texts.filter(
          m => this._isEmpty(input.language) || this._isEmpty(m.language) || m.language!.toUpperCase() === input.language!.toUpperCase(),
        ),
      )
      .reduce((a, m) => [...a, ...m], []);

    if (textCandidates.length > 0) {
      textCandidates = _.orderBy(textCandidates, t => (t.language ? 1 : 0), 'desc');
      if (textCandidates.length > 0) {
        return textCandidates[0].text;
      }
    }
    if (input.position === 'HEADER_SHORT') {
      return this.getProductText({
        ...input,
        position: 'HEADER'
      })
    }
    return null;
  }
}
