import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const INTEGRATION_SECRET_VIEW_FRAGMENT = gql(`
  fragment IntegrationSecretView on IntegrationSecretOutput {
    id
    name
    system
    key
    elitefo_htuser
    elitefo_htpasswd
    protelids_user
    protelids_passwd
    protelids_pushurl
    space { ...SpaceShort }
    hotelSecrets {
      id
      hotel {
        id name
      }
    }
  }
`);

export const INTEGRATION_SECRETS_LIST_QUERY = gql(`
  query ListIntegrationSecrets($spaceId: Int, $hotelId: Int) {
    listIntegrationSecrets(spaceId: $spaceId, hotelId: $hotelId) {
      ...IntegrationSecretView
    }
  }
`);

export const INTEGRATION_SECRET_VIEW_QUERY = gql(`
  query ViewIntegrationSecret($id: Int!) {
    viewIntegrationSecret(id: $id) {
      ...IntegrationSecretView
    }
  }
`);

export const INTEGRATION_PROPERTIES_QUERY = gql(`
  query ListPMSIntegrationProperties($secretId: Int, $secret: IntegrationSecretInput) {
    listPMSIntegrationProperties(secretId: $secretId, secret: $secret) {
      name
      extRefCode
      businessName
      businessAddress1
      businessAddress2
      businessAddress3
      businessAddress4
      businessCountry
      businessEmail
    }
  }
`);

export const CREATE_INTEGRATION_SECRET_MUTATION = gql(`
  mutation CreateIntegrationSecret($spaceId: Int!, $data: IntegrationSecretInput!) {
    createIntegrationSecret(spaceId: $spaceId, data: $data) {
      ...IntegrationSecretView
    }
  }
`);

export const UPDATE_INTEGRATION_SECRET_MUTATION = gql(`
  mutation UpdateIntegrationSecret($id: Int!, $data: IntegrationSecretInput!) {
    updateIntegrationSecret(id: $id, data: $data) {
      ...IntegrationSecretView
    }
  }
`);

export const DELETE_INTEGRATION_SECRET_MUTATION = gql(`
  mutation DeleteIntegrationSecret($id: Int!) {
    deleteIntegrationSecret(id: $id)
  }
`);

export const EVICT_INTEGRATION_SECRETS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listIntegrationSecrets' });
  cache.evict({ fieldName: 'listIntegrationHotels' });
  cache.gc();
};

export const REFETCH_INTEGRATIONS_SECRETS_QUERIES = (secretId?: number | undefined) => [
  ...(secretId
    ? [
        {
          query: INTEGRATION_SECRET_VIEW_QUERY,
          variables: { id: secretId },
        },
      ]
    : []),
];

export const INTEGRATION_HOTEL_VIEW_FRAGMENT = gql(`
  fragment IntegrationHotelView on IntegrationHotelOutput {
    id
    syncProperties
    syncProducts
    syncAvailability
    syncAvailabilityDays
    syncPrices
    syncPricesDays
    directBooking
    secret { id name system }
    hotel { id name refCode extRefCode }
    space { ...SpaceShort }
  }
`);

export const INTEGRATION_HOTELS_LIST_QUERY = gql(`
  query ListIntegrationHotels($spaceId: Int, $hotelId: Int) {
    listIntegrationHotels(spaceId: $spaceId, hotelId: $hotelId) {
      ...IntegrationHotelView
    }
  }
`);

export const INTEGRATION_HOTEL_VIEW_QUERY = gql(`
  query ViewIntegrationHotel($id: Int!) {
    viewIntegrationHotel(id: $id) {
      ...IntegrationHotelView
    }
  }
`);

export const INTEGRATION_HOTEL_CONNECTOPTIONS_QUERY = gql(`
  query ReadHotelConnectOptions($secretId: Int!, $extHotelRefCode: String!) {
    readHotelConnectOptions(secretId: $secretId, extHotelRefCode: $extHotelRefCode) {
      name
      extRefCode
      products {
        name
        extRefCode
        extPricelistOptions {
          name
          extRefCode
        }  
      }
      facilities {
        name
        extRefCode
        extPricelistOptions {
          name
          extRefCode
        }  
      }
    }
  }
`);

export const CREATE_INTEGRATION_HOTEL_MUTATION = gql(`
  mutation CreateIntegrationHotel($spaceId: Int, $secretId: Int!, $extHotelRefCode: String, $hotelId: Int, $connectArgs: IntegrationHotelConnectArgsInput) {
    createIntegrationHotel(spaceId: $spaceId, secretId: $secretId, extHotelRefCode: $extHotelRefCode, hotelId: $hotelId, connectArgs: $connectArgs) {
      ...IntegrationHotelView
    }
  }
`);

export const UPDATE_INTEGRATION_HOTEL_MUTATION = gql(`
  mutation UpdateIntegrationHotel($id: Int!, $data: IntegrationHotelInput!) {
    updateIntegrationHotel(id: $id, data: $data) {
      ...IntegrationHotelView
    }
  }
`);

export const DELETE_INTEGRATION_HOTEL_MUTATION = gql(`
  mutation DeleteIntegrationHotel($id: Int!) {
    deleteIntegrationHotel(id: $id)
  }
`);

export const EVICT_INTEGRATION_HOTELS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listIntegrationHotels' });
  cache.gc();
};

export const REFETCH_INTEGRATIONS_HOTELS_QUERIES = (hotelIntegrationId?: number | undefined) => [
  ...(hotelIntegrationId
    ? [
        {
          query: INTEGRATION_HOTEL_VIEW_QUERY,
          variables: { id: hotelIntegrationId },
        },
      ]
    : []),
];

export const INTEGRATION_HOTELLOG_VIEW_FRAGMENT = gql(`
  fragment IntegrationHotelLogView on IntegrationHotelLogOutput {
    id
    startDate
    endDate
    status
    trigger
    syncProperties
    syncProducts
    syncAvailability
    syncPrices
    syncBooking
    confirmBooking
    deleteBooking
    err
    integration { id }
    secret { id name system }
    hotel { id name extRefCode }
    offerVersion { id extRefCode offer { id refCode } }
    space { ...SpaceShort }
  }
`);

export const INTEGRATION_HOTELLOG_LIST_QUERY = gql(`
  query ListIntegrationHotelLogs($spaceId: Int!, $hotelId: Int, $offerId: Int, $secretId: Int, $integrationId: Int, $syncProperties: Boolean, $syncProducts: Boolean, $syncAvailability: Boolean, $syncPrices: Boolean, $syncBooking: Boolean, $deleteBooking: Boolean, $confirmBooking: Boolean, $skip: Int, $take: Int) {
    listIntegrationHotelLogs(spaceId: $spaceId, hotelId: $hotelId, offerId: $offerId, secretId: $secretId, integrationId: $integrationId, syncProperties: $syncProperties, syncProducts: $syncProducts, syncAvailability: $syncAvailability, syncPrices: $syncPrices, syncBooking: $syncBooking, deleteBooking: $deleteBooking, confirmBooking: $confirmBooking, skip: $skip, take: $take) {
      ...IntegrationHotelLogView
    }
  }
`);

export const INTEGRATION_HOTELLOG_VIEW_QUERY = gql(`
  query ViewIntegrationHotelLog($id: Int!) {
    viewIntegrationHotelLog(id: $id) {
      ...IntegrationHotelLogView
      result
    }
  }
`);

export const EVICT_INTEGRATION_HOTELLOG_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listIntegrationHotelLogs' });
  cache.gc();
};

export const TRIGGER_INTEGRATION_HOTEL_MUTATION = gql(`
  mutation TriggerIntegrationHotel($id: Int!) {
    triggerIntegrationHotel(id: $id) {
      ...IntegrationHotelLogView
    }
  }
`);

export const INTEGRATION_GROUPBOOKINGINFO_VIEW_QUERY = gql(`
  query IntegrationViewGroupBookingInfo($offerVersionId: Int!) {
    integrationViewGroupBookingInfo(offerVersionId: $offerVersionId) {
      href
    }
  }
`);

export const INTEGRATION_SYNCBOOKING_MUTATION = gql(`
  mutation IntegrationSyncBooking($offerVersionId: Int!) {
    integrationSyncBooking(offerVersionId: $offerVersionId) {
      ...IntegrationHotelLogView
    }
  }
`);

export const INTEGRATION_CONFIRMBOOKING_MUTATION = gql(`
  mutation IntegrationConfirmBooking($offerVersionId: Int!) {
    integrationConfirmBooking(offerVersionId: $offerVersionId) {
      ...IntegrationHotelLogView
    }
  }
`);

export const INTEGRATION_DELETEBOOKING_MUTATION = gql(`
  mutation IntegrationDeleteBooking($offerVersionId: Int!) {
    integrationDeleteBooking(offerVersionId: $offerVersionId) {
      ...IntegrationHotelLogView
    }
  }
`);
