import React from 'react';
import { TextField, styled } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

export const FormInputPercentage = ({ register, name, control, label, disabled, readOnly }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledTextField
          helperText={error ? error.message : ' '}
          error={!!error}
          label={label}
          onChange={onChange}
          value={value || ''}
          InputProps={{
            endAdornment: <>%</>,
            readOnly: readOnly,
          }}
          InputLabelProps={{
            ...(disabled ? { shrink: true } : {}),
          }}
          disabled={disabled}
          inputProps={{
            min: 0,
            max: 100,
            type: 'number',
          }}
        />
      )}
      {...register}
    />
  );
};
