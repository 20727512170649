import React from 'react';
import _ from 'lodash';
import { Alert, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

const _extract = (validationErrors: any) => {
  const errs = [] as string[];
  const _extractRec = (obj: any) => {
    for (const k of Object.keys(obj)) {
      const c = obj[k];
      if (c && _.isArray(c)) {
        c.filter(c => c).forEach((v: any) => _extractRec(v));
      } else if (c && _.isString(c) && k === 'message') {
        errs.push(c);
      } else if (c && _.isObject(c)) {
        _extractRec(c);
      }
    }
  };
  _extractRec(validationErrors);
  return errs;
};

export const showValidationAlert = (validationErrors: any, filterFunc?: any) => {
  if (!validationErrors || Object.keys(validationErrors).length === 0) return null;

  let errs = _extract(validationErrors);
  if (errs.length === 0) {
    errs.push(JSON.stringify(validationErrors));
  } else if (filterFunc) {
    errs = errs.filter(filterFunc);
  }

  return (
    <>
      {_.uniq(errs).map((err, i) => (
        <Alert key={i} severity="error">
          {err}
        </Alert>
      ))}
    </>
  );
};

export const hintValidationAlert = (validationErrors: any) => {
  if (!validationErrors || Object.keys(validationErrors).length === 0) return null;

  const errs = _extract(validationErrors);
  if (errs.length === 0) {
    errs.push(JSON.stringify(validationErrors));
  }

  return (
    <Tooltip title={_.uniq(errs).join(' ')}>
      <ErrorIcon style={{ color: 'red' }} />
    </Tooltip>
  );
};
