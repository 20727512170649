import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';

import SimpleTable from 'components/table/SimpleTable';
import { FormatDateTime } from 'components/DateTime';
import FileUploadButton from 'components/FileUploadButton';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import {
  OFFERTEMPLATES_LIST_QUERY,
  IMPORT_CONTENTOFFERTEMPLATE_MUTATION,
  EXPORT_CONTENTOFFERTEMPLATE_MUTATION,
  EVICT_OFFERTEMPLATES_QUERIES,
  REFETCH_OFFERTEMPLATES_QUERIES,
} from '../gql';

import { OfferTemplateCopyButton, OfferTemplateDeleteButton } from './offertemplate';
import { ErrorAlert } from 'pages/error';

export default function OfferTemplatesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(OFFERTEMPLATES_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });
  const [importMutationFunction, { loading: importLoading }] = useMutation(IMPORT_CONTENTOFFERTEMPLATE_MUTATION);
  const [exportMutateFunction, { loading: exportLoading }] = useMutation(EXPORT_CONTENTOFFERTEMPLATE_MUTATION);

  const importToSpaceId = (filter && filter.spaceId) || user.space?.id;

  return (
    <>
      <Helmet>
        <title>{i18next.t('offertemplates-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('offertemplates-list-header-name'),
                  i18next.t('offertemplates-list-header-type'),
                  ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                  i18next.t('offertemplates-list-header-title'),
                  i18next.t('offertemplates-list-header-updatedat'),
                  i18next.t('offertemplates-list-header-ispublished'),
                  '',
                ]}
                rows={data.listOfferTemplates.map(row => [
                  <Link to={`/content/templates/offertemplates/${row.id}`}>{row.name}</Link>,
                  i18next.t(`enums-EContentOfferTemplateType-${row.type}`),
                  ...(user.isSingleSpace ? [] : [row.space.name]),
                  row.title,
                  <FormatDateTime date={row.updatedAt} />,
                  row.isPublished ? <CheckIcon /> : null,
                  <ButtonGroup>
                    <OfferTemplateCopyButton id={row.id} spaceId={row.space.id} icon />
                    <OfferTemplateDeleteButton id={row.id} spaceId={row.space.id} icon />
                  </ButtonGroup>,
                ])}
              />
            </Grid>
            <Grid item xs={12}>
              {hasAnyAdminSpace(user) && (
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/content/templates/offertemplates/_create')}
                >
                  {i18next.t('offertemplates-list-add')}
                </Button>
              )}
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                color="secondary"
                startIcon={exportLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
                disabled={exportLoading}
                onClick={async () => {
                  try {
                    const res = await exportMutateFunction({
                      variables: {
                        spaceId: (filter && filter.spaceId) || null,
                      },
                    });
                    if (res.data?.exportOfferTemplates) {
                      exportToFile(res.data?.exportOfferTemplates, 'application/json', 'seminargo-offertemplates.json');
                    }
                  } catch (err) {
                    dispatchException(dispatch, err);
                  }
                }}
              >
                {i18next.t('offertemplates-export-run')}
              </Button>
              {user.isAdmin && importToSpaceId && (
                <FileUploadButton
                  name="importOfferTemplate"
                  accept="application/json"
                  disabled={importLoading}
                  binary={() => false}
                  startIcon={importLoading ? <CircularProgress size={24} /> : <UploadIcon />}
                  onChange={async (name, type, content) => {
                    try {
                      const res = await importMutationFunction({
                        variables: {
                          spaceId: importToSpaceId,
                          jsonText: content,
                        },
                        update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
                        awaitRefetchQueries: true,
                        refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(),
                      });
                      dispatchMessage(dispatch, i18next.t('offertemplates-import-ready'));
                    } catch (err) {
                      dispatchException(dispatch, err);
                    }
                  }}
                >
                  {i18next.t('offertemplates-import-run')}
                </FileUploadButton>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
