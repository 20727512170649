import React, { useRef, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToHashElementProps {
  id?: string | null;
}

const ScrollToHashElement = ({ id }: ScrollToHashElementProps) => {
  const location = useLocation();
  const aRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (aRef.current && location.hash && location.hash.slice(1) === id) {
      const y = aRef.current.getBoundingClientRect().top + window.pageYOffset - 100;

      document.querySelector('main')?.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [id]);

  if (id) return <span id={id} ref={aRef} />;
  else return null;
};

export default ScrollToHashElement;
