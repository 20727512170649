import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Alert, Button, Grid, CircularProgress, Box, Checkbox } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import SyncIcon from '@mui/icons-material/Sync';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HotelIcon from '@mui/icons-material/Hotel';

import SimpleTable from 'components/table/SimpleTable';
import PagedTable from 'components/table/PagedTable';
import InformationDialog from 'components/dialogs/InformationDialog';
import { FormatDateTime } from 'components/DateTime';
import CustomTabs from 'components/Tabs';
import { dispatchException } from 'helper/snackbar';

import { INTEGRATION_HOTELLOG_LIST_QUERY, INTEGRATION_HOTELLOG_VIEW_QUERY } from './gql';
import { EIntegrationLogStatus, ListIntegrationHotelLogsQueryVariables } from '__generated__/graphql';
import { ErrorAlert } from 'pages/error';

interface IntegrationLogsProps {
  variables: ListIntegrationHotelLogsQueryVariables;
}

export function IntegrationLogsTable(props: IntegrationLogsProps) {
  const dispatch = useDispatch();

  const [selectedActions, setSelectedActions] = useState([
    ...(props.variables.offerId ? [] : ['syncProperties', 'syncProducts', 'syncAvailability', 'syncPrices']),
    'syncBooking',
    'deleteBooking',
    'confirmBooking',
  ]);

  const [logDataQuery, { data: logData, loading: logDataLoading, error: logDataError }] = useLazyQuery(INTEGRATION_HOTELLOG_LIST_QUERY);
  const [viewQuery, { loading: viewLoading }] = useLazyQuery(INTEGRATION_HOTELLOG_VIEW_QUERY);

  const [viewLog, setViewLog] = useState({
    show: false,
    result: null as string | null,
  });
  const __formatResultDialog = (result?: string | null) => {
    if (!result) return <>Result empty</>;
    try {
      const syncData = JSON.parse(result);

      const syncRows = [
        syncData.hotel && { section: 'Property', link: syncData.hotel.id && `/settings/hotels/${syncData.hotel.id}`, ...syncData.hotel },
        ...(syncData.products || []).map((p: any) => ({ section: 'Product', link: p.id && `/products/products/${p.id}`, ...p })),
        ...(syncData.facilities || []).map((f: any) => ({ section: 'Facility', link: f.id && `/products/facilities/${f.id}`, ...f })),
        ...(syncData.productAvailability || []).map((p: any) => ({ section: 'Availability', link: p.id && `/products/products/${p.id}`, ...p })),
        ...(syncData.facilityAvailability || []).map((f: any) => ({ section: 'Availability', link: f.id && `/products/facilities/${f.id}`, ...f })),
        ...(syncData.pricelists || []).map((p: any) => ({ section: 'Price', link: p.id && `/pricelists/pricelists/${p.id}`, ...p })),
      ].filter(s => !!s);

      const syncRowsRender = () => (
        <SimpleTable
          headers={['', 'Name', 'Ref-Code', 'SKU', 'External Ref-Code', 'Added', 'Updated', 'Success', '']}
          rows={syncRows.map(r => [
            r.section,
            r.link ? (
              <Link to={r.link} target="_blank">
                {r.name}
              </Link>
            ) : (
              r.name
            ),
            r.refCode,
            r.sku,
            r.extRefCode,
            r.added ? <CheckIcon /> : <></>,
            r.updated ? <CheckIcon /> : <></>,
            r.success ? <CheckCircleIcon /> : <ErrorIcon />,
            r.err,
          ])}
        />
      );

      const syncLogs = syncData.logs || [];
      const syncLogsRender = () => <pre>{syncLogs.join('\n')}</pre>;

      const syncRawRender = () => <pre>{JSON.stringify(syncData, null, 2)}</pre>;

      if (syncRows.length > 0 && syncLogs.length > 0) {
        return (
          <CustomTabs headers={['Synchronized Data', 'Log Output', 'Raw Output']} tabs={[syncRowsRender(), syncLogsRender(), syncRawRender()]} />
        );
      } else if (syncRows.length > 0) {
        return <CustomTabs headers={['Synchronized Data', 'Raw Output']} tabs={[syncRowsRender(), syncRawRender()]} />;
      } else if (syncLogs.length > 0) {
        return <CustomTabs headers={['Log Output', 'Raw Output']} tabs={[syncLogsRender(), syncRawRender()]} />;
      } else {
        return syncRawRender();
      }
    } catch (err) {
      return <pre>{result}</pre>;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <PagedTable
            filterKey={selectedActions.join()}
            filter={[
              ...(props.variables.offerId
                ? []
                : [
                    ['syncProperties', <HotelIcon color="primary" />, <HotelIcon color="disabled" />],
                    ['syncProducts', <ShoppingCartIcon color="primary" />, <ShoppingCartIcon color="disabled" />],
                    ['syncAvailability', <EventAvailableIcon color="primary" />, <EventAvailableIcon color="disabled" />],
                    ['syncPrices', <CreditCardIcon color="primary" />, <CreditCardIcon color="disabled" />],
                  ]),
              ['syncBooking', <LocalOfferIcon color="primary" />, <LocalOfferIcon color="disabled" />],
              ['deleteBooking', <DeleteIcon color="primary" />, <DeleteIcon color="disabled" />],
              ['confirmBooking', <CheckBoxIcon color="primary" />, <CheckBoxIcon color="disabled" />],
            ].map((s, index) => (
              <Checkbox
                key={index}
                checked={selectedActions.includes(s[0] as string)}
                onChange={(e, checked) => {
                  if (checked) {
                    setSelectedActions([...selectedActions, s[0] as string]);
                  } else {
                    setSelectedActions(selectedActions.filter(a => a !== s[0]));
                  }
                }}
                checkedIcon={s[1]}
                icon={s[2]}
              />
            ))}
            headers={[
              '',
              i18next.t('integration-hotel-log-status'),
              i18next.t('integration-hotel-log-action'),
              i18next.t('integration-hotel-log-trigger'),
              i18next.t('integration-hotel-log-offer'),
              i18next.t('integration-hotel-log-startdate'),
              i18next.t('integration-hotel-log-enddate'),
              '',
              '',
            ]}
            hidden={[false, false, false, false, !!props.variables.offerId, false, false, false, false]}
            refetchPage={(skip, take) =>
              logDataQuery({
                variables: {
                  ...props.variables,
                  ...(props.variables.offerId
                    ? {}
                    : {
                        syncProperties: selectedActions.includes('syncProperties'),
                        syncProducts: selectedActions.includes('syncProducts'),
                        syncAvailability: selectedActions.includes('syncAvailability'),
                        syncPrices: selectedActions.includes('syncPrices'),
                      }),
                  syncBooking: selectedActions.includes('syncBooking'),
                  deleteBooking: selectedActions.includes('deleteBooking'),
                  confirmBooking: selectedActions.includes('confirmBooking'),
                  take: take,
                  skip: skip,
                },
                fetchPolicy: 'network-only',
              })
            }
            showRefetch
            rowsLoading={logDataLoading}
            rowsErr={logDataError}
            rows={
              logData && logData?.listIntegrationHotelLogs
                ? logData?.listIntegrationHotelLogs.map(row => [
                    <>
                      {row.status === EIntegrationLogStatus.PENDING && <PendingIcon />}
                      {row.status === EIntegrationLogStatus.RUNNING && <SyncIcon />}
                      {row.status === EIntegrationLogStatus.SUCCESS && <CheckCircleIcon color="success" />}
                      {row.status === EIntegrationLogStatus.FAILED && <ErrorIcon color="warning" />}
                    </>,
                    `${i18next.t('enums-EIntegrationLogStatus-' + row.status)}`,
                    [
                      row.syncProperties && <HotelIcon key={0} />,
                      row.syncAvailability && <EventAvailableIcon key={1} />,
                      row.syncProducts && <ShoppingCartIcon key={2} />,
                      row.syncPrices && <CreditCardIcon key={3} />,
                      row.syncBooking && <LocalOfferIcon key={4} />,
                      row.deleteBooking && <DeleteIcon key={5} />,
                      row.confirmBooking && <CheckBoxIcon key={6} />,
                    ].filter(s => s),
                    `${i18next.t('enums-EIntegrationLogTrigger-' + row.trigger)}`,
                    row.offerVersion ? (
                      <Link to={`/offers/${row.offerVersion.offer.id}/${row.offerVersion.id}`}>{row.offerVersion.offer.refCode}</Link>
                    ) : null,
                    <FormatDateTime date={row.startDate} />,
                    <FormatDateTime date={row.endDate} />,
                    (row.status === EIntegrationLogStatus.FAILED || row.status === EIntegrationLogStatus.SUCCESS) && (
                      <Button
                        variant="text"
                        disabled={viewLoading}
                        onClick={async () => {
                          try {
                            const r = await viewQuery({ variables: { id: row.id } });
                            setViewLog({ show: true, result: r.data?.viewIntegrationHotelLog?.result || null });
                          } catch (err) {
                            dispatchException(dispatch, err);
                          }
                        }}
                      >
                        {i18next.t('integration-hotel-log-showresult')}
                      </Button>
                    ),
                    row.err && <ErrorAlert err={row.err} />,
                  ])
                : logDataLoading
                  ? [[...Array(5).keys()].map(d => <CircularProgress />)]
                  : logDataError
                    ? [[{ span: 5, cell: <ErrorAlert err={logDataError} /> }]]
                    : []
            }
          />
        </Grid>
      </Grid>
      <InformationDialog
        title={i18next.t('integration-hotel-log-showresult-title')}
        open={viewLog.show}
        onConfirm={() => setViewLog({ show: false, result: null })}
        maxWidth="lg"
        fullWidth
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              {__formatResultDialog(viewLog.result)}
            </Grid>
          </Grid>
        </Box>
      </InformationDialog>
    </>
  );
}
