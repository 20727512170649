import React from 'react';
import { Container, Paper, Toolbar, Box } from '@mui/material';
import { Routes, Route, Outlet } from 'react-router-dom';

import SpaceSettingsCi from './ci';

export default function SpaceIndex() {
  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route index element={<SpaceSettingsCi />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
