import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const OFFERVERSION_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferVersionListShort on WLOfferVersionListOutput {
    id
    createdAt
    updatedAt
    version
    extRefCode
    status
    type
    offerDate
    startDate
    hotel {
      id
      name
      spaceId
      integrations { id secret { id system } }
    }
    space { ...SpaceShort }
  }
`);

export const OFFERVERSION_LIST_VIEW_FRAGMENT = gql(`
  fragment WLOfferVersionListView on WLOfferVersionListOutput {
    id
    createdAt
    updatedAt
    version
    extRefCode
    extRefData
    status
    offerDate
    startDate
    title
    type
    filename
    cssStyles
    marginTop
    marginBottom
    marginLeft
    marginRight
    includeEmptyLineItems
    headerTemplate
    footerTemplate
    totalPriceNet
    totalPriceGross
    additionalInfo1
    additionalInfo2
    additionalInfo3
    space { ...SpaceShort }
    hotel {
      id
      name
      spaceId
      businessName
      businessAddress1
      businessAddress2
      businessAddress3
      integrations { id secret { id system } }
    }
    offer {
      id
      input {
        id
        serviceType {
          id
          sku
          name
          header
          details
          spaceId
        }
      }
    }
    priceList {
      id
      name
      currency
      isPricesNet
    }
    client {
      id
      firstname
      lastname
      company
      email
      address
      city
      zip
      country
      phone
      billingCompany
      billingFirstname
      billingLastname
      billingAddress
      billingCity
      billingZip
      billingCountry
    }
    contentBlocks {
      id
      type
      template
      sequence
      blockDescription
      inputDay {
        id
        day
        addons {
          id
          count
          header
          details
          sku
          spaceId
        }
        occupancy {
          id
          room
          occupancy
          seating
        }
        overnightGuests
        totalGuests
      }
      formFields {
        id
        type
        name
        label
        value
        sequence
      }
      lineItems {
        id
        day
        sku
        spaceId
        header
        details
        count
        priceItem
        priceNet
        priceGross
        sequence
        components {
          price
          type {
            id
            name
          }
        }
        taxes {
          price
          type {
            id
            name
          }
          rate {
            id
            rate
          }
        }
      }
    }
    taxes {
      price
      type {
        id
        name
      }        
      rate {
        id
        rate
      }
    }
  }
`);

export const OFFER_HISTORY_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferHistoryListShort on WLOfferHistoryListOutput {
    id
    createdAt
    status
    extraInfo
    offerVersion {
      id
      version
      status
    }
    user {
      id
      name
    }
  }
`);

export const OFFER_LIST_SHORT_FRAGMENT = gql(`
  fragment WLOfferListShort on WLOfferListOutput {
    id
    createdAt
    updatedAt
    status
    language
    refCode
    deleteReason
    space { ...SpaceShort }
    hotel {
      id
      name
      spaceId
      integrations { id }
    }
    client {
      id
      firstname
      lastname
      company
      email
      address
      city
      zip
      country
      phone
      billingCompany
      billingFirstname
      billingLastname
      billingAddress
      billingCity
      billingZip
      billingCountry
    }
    latestVersion {
      ...WLOfferVersionListShort
    }
    input {
      id
      startDate
      endDate
    }
  }
`);

export const OFFER_LIST_VIEW_FRAGMENT = gql(`
  fragment WLOfferListView on WLOfferListOutput {
    id
    createdAt
    status
    language
    refCode
    space { ...SpaceShort }
    hotel {
      id
      name
      spaceId
      integrations { id }
    }
    client {
      id
      firstname
      lastname
      company
      email
      address
      city
      zip
      country
      phone
      billingCompany
      billingFirstname
      billingLastname
      billingAddress
      billingCity
      billingZip
      billingCountry
    }
    latestVersion {
      ...WLOfferVersionListShort
    }
    versions {
      ...WLOfferVersionListShort
    }
    history {
      ...WLOfferHistoryListShort
    }
    emailHistory {
      ...EmailHistoryOutputShort
    }
    input {
      id
      startDate
      endDate
      language
      serviceType {
        id
        sku
        name
        header
        details
        spaceId
      }
      prevdayGuests
      days {
        id
        addons {
          id
          count
          sku
          product { id name }
          facility { id name }
          spaceId
        }
        day
        occupancy {
          id
          room
          occupancy
          seating
        }
        overnightGuests
        totalGuests
      }
      comment
    }
  }
`);

export const OFFER_LIST_QUERY_OPEN = gql(`
  query ListWlOffersOpen($spaceId: Int, $hotelId: Int) {
    listWlOffers(spaceId: $spaceId, hotelId: $hotelId, status: [CLIENTVERIFICATION, PENDING]) {
      ...WLOfferListShort
    }
  }
`);

export const OFFER_LIST_QUERY_CLOSED = gql(`
  query ListWlOffersClosed($spaceId: Int, $hotelId: Int) {
    listWlOffers(spaceId: $spaceId, hotelId: $hotelId, status: [APPROVED, REJECTED]) {
      ...WLOfferListShort
    }
  }
`);

export const OFFER_LIST_QUERY_DELETED = gql(`
  query ListWlOffersDeleted($spaceId: Int, $hotelId: Int) {
    listWlOffers(spaceId: $spaceId, hotelId: $hotelId, status: [DELETED]) {
      ...WLOfferListShort
    }
  }
`);

export const OFFER_VIEW_QUERY = gql(`
  query ViewWlOffer($id: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
  }
`);

export const OFFER_AND_VERSION_VIEW_QUERY = gql(`
  query ViewWlOfferAndVersion($id: Int!, $versionId: Int!) {
    viewWlOffer(id: $id) {
      ...WLOfferListView
    }
    viewWlOfferVersion(id: $versionId) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_VIEW_QUERY = gql(`
  query ViewWlOfferVersion($id: Int!) {
    viewWlOfferVersion(id: $id) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_QUICKPRICE_QUERY = gql(`
  query OfferVersionQuickPriceDehydrate($id: Int!) {
    offerVersionQuickPriceDehydrate(id: $id)
  }
`);

export const OFFERVERSION_PRODUCTTEXT_QUERY = gql(`
  query OfferVersionProductTextDehydrate($id: Int!) {
    offerVersionProductTextDehydrate(id: $id)
  }
`);

export const OFFERVERSION_UPSERT_MUTATION = gql(`
  mutation UpsertOfferVersion($id: Int, $createNew: Boolean!, $data: WLOfferVersionInput!, $process: Boolean!) {
    upsertOfferVersion(id: $id, createNew: $createNew, data: $data, process: $process) {
      ...WLOfferVersionListView
    }
  }
`);

export const OFFERVERSION_APPROVE_MUTATION = gql(`
  mutation ApproveWlOfferVersion($offerVersionId: Int!) {
    approveOfferVersion(offerVersionId: $offerVersionId) {
      status
      offerVersion {
        ...WLOfferVersionListView
      }
    }
  }
`);

export const OFFERVERSION_REJECT_MUTATION = gql(`
  mutation RejectWlOfferVersion($offerVersionId: Int!) {
    rejectOfferVersion(offerVersionId: $offerVersionId) {
      status
    }
  }
`);

export const OFFERVERSION_DELETE_MUTATION = gql(`
  mutation DeleteWlOfferVersion($offerVersionId: Int!, $deleteReason: String!) {
    deleteOfferVersion(offerVersionId: $offerVersionId, deleteReason: $deleteReason) {
      status
    }
  }
`);

export const OFFER_SEND_REMINDER_CLIENT_MUTATION = gql(`
  mutation SendOfferReminderEmailClient($offerVersionId: Int!) {
    sendOfferReminderEmailClient(offerVersionId: $offerVersionId) {
      status
    }
  }
`);

export const OFFER_CREATE_MUTATION = gql(`
  mutation CreateOffer($hotelId: Int!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
    createOffer(hotelId: $hotelId, input: $input, client: $client) {
      ...WLOfferListShort
    }
  }
`);

export const OFFER_PREVIEW_HTML_QUERY = gql(`
  query OfferQuickInputHtmlPreview($hotelId: Int!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
    offerQuickInputHtmlPreview(hotelId: $hotelId, input: $input, client: $client) {
      html
    }
  }
`);

export const QUICKPRICE_DEHYDRATE_QUERY = gql(`
  query QuickPriceDehydrate($hotelId: Int!) {
    quickPriceDehydrate(hotelId: $hotelId)
  }
`);

export const EVICT_OFFER_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({
    fieldName: 'listWlOffers({"status":["APPROVED","REJECTED"]})',
  });
  cache.evict({
    fieldName: 'listWlOffers({"status":["CLIENTVERIFICATION","PENDING"]})',
  });
  cache.evict({ fieldName: 'listWlOffers({"status":["DELETED"]})' });
  cache.gc();
};

export const REFETCH_OFFER_QUERIES = (offerId?: number | undefined, offerVersionId?: number | undefined) => [
  ...(offerId
    ? [
        {
          query: OFFER_VIEW_QUERY,
          variables: { id: offerId },
        },
      ]
    : []),
  ...(offerVersionId
    ? [
        {
          query: OFFERVERSION_VIEW_QUERY,
          variables: { id: offerVersionId },
        },
      ]
    : []),
];
