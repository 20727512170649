import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { deDE } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Palette {
    disabled: PaletteColorOptions;
  }
  interface PaletteOptions {
    disabled: PaletteColorOptions;
  }
}

// A custom theme for this app
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#ac2a6e',
      },
      secondary: {
        main: '#fff',
      },
      disabled: {
        main: '#999',
      },
      error: {
        main: red.A400,
      },
    },
  },
  deDE,
);

export default theme;
