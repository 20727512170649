import React, { useMemo } from 'react';
import i18next from 'i18next';
import { FormInputDropdown } from '../form/FormInputDropdown';
import { FormSelectOption } from '../form/FormInputProps';
import { userSelector, canEditAdminSpace } from 'helper/security';
import { IUser, ISpace } from 'types';

interface SpaceSelectionProps {
  checkAdmin?: boolean;
  allowEmptyForRoot?: boolean;
  baseEnabledSpaceId?: number;
  name: string;
  label?: string | undefined;
  emptyLabel?: string | undefined;
  emptyValue?: any | undefined;
  control: any;
  onSpaceSelected?: (space: ISpace) => void;
  [key: string]: any;
}

export const unicodeIndent = (level: number) => {
  if (level <= 0) return '';
  else return '⌞' + '_'.repeat(level - 1);
};

export const buildSpaceSelectionOptions = (user: IUser, checkAdmin?: boolean, baseEnabledSpaceId?: number) => {
  const _addSpace = (space: ISpace, level: number = 0, branchEnabled: boolean = false) => {
    const childOptions = [] as FormSelectOption[];

    childOptions.push({
      label: (
        <>
          {unicodeIndent(level)} {space.name}
        </>
      ),
      value: space.id,
      disabled: branchEnabled ? (checkAdmin ? !canEditAdminSpace(user, space) : false) : true,
    });
    if (space.children && space.children.length > 0) {
      const children = user.spaces.filter(fs => (space.children || []).findIndex(cc => fs.id == cc.id) >= 0);
      for (const child of children) {
        childOptions.push(..._addSpace(child, level + 1, branchEnabled ? true : (baseEnabledSpaceId !== undefined) ? child.id === baseEnabledSpaceId : true));
      }
    }
    if (childOptions.filter(c => !c.disabled).length === 0) return []
    return childOptions
  };
  const options: FormSelectOption[] = [];

  const rootSpaces = user.spaces.filter(s => !s.parent);
  for (const rootSpace of rootSpaces) {
    options.push(..._addSpace(rootSpace, 0, (baseEnabledSpaceId !== undefined) ? rootSpace.id === baseEnabledSpaceId : true));
  }

  return options;
};

export const SpaceSelectionInput = (props: SpaceSelectionProps) => {
  const { checkAdmin, allowEmptyForRoot, baseEnabledSpaceId, name, label, emptyLabel, emptyValue, control, onSpaceSelected, ...rest } = props;
  const user = userSelector()!;

  const options = useMemo(
    () => [
      ...(allowEmptyForRoot && user.space === null
        ? [
            {
              label: <em>{emptyLabel || i18next.t('generic-selection-empty')}</em>,
              value: emptyValue !== undefined ? emptyValue : null,
            },
          ]
        : []),
      ...buildSpaceSelectionOptions(user, checkAdmin, baseEnabledSpaceId),
    ],
    [user],
  );

  if (user.isSingleSpace) return null;
  else
    return (
      <FormInputDropdown
        name={name}
        control={control}
        label={label || i18next.t('field-space')}
        options={options}
        {...(onSpaceSelected
          ? {
              onSelected: (value?: any) => {
                if (value && user.spaces.find(s => s.id === value)) onSpaceSelected(user.spaces.find(s => s.id === value)!);
              },
            }
          : {})}
        {...rest}
      />
    );
};
