import { useEffect } from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import i18next from 'i18next';

export interface UnsavedChangesPromptProps {
  isDirty: boolean;
}

export const UnsavedChangesPrompt = ({ isDirty }: UnsavedChangesPromptProps) => {
  usePrompt({ message: i18next.t('generic-unsaved-changes'), when: isDirty });
  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = isDirty ? () => i18next.t('generic-unsaved-changes') : null;

    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [isDirty]);
  return null;
};
