import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Grid } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PRODUCTS_LIST_QUERY } from './gql';

import { ProductCopyButton, ProductDeleteButton } from './product';
import { ErrorAlert, RedirectError } from 'pages/error';

export default function ProductsList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(PRODUCTS_LIST_QUERY);
  if (error) return <RedirectError err={error} />

  return (
    <>
      <Helmet>
        <title>{i18next.t('products-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            headers={[
              i18next.t('products-list-header-product'),
              i18next.t('products-list-header-sku'),
              i18next.t('products-list-header-recurring'),
              i18next.t('products-list-header-isaddon'),
              i18next.t('products-list-header-isdeduction'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              '',
            ]}
            rows={(data?.listProducts || []).map((row, index) => [
              <Link to={`/products/products/${row.id}`}>
                {row.name}
                {row.extRefCode && ` (${row.extRefCode})`}
              </Link>,
              row.sku,
              `${i18next.t('enums-EProductPayment-' + row.recurring)}`,
              row.isAddon ? <CheckIcon /> : '',
              row.isDeduction ? <CheckIcon /> : '',
              ...(user.isSingleSpace ? [] : [row.space.name]),
              <ButtonGroup>
                <ProductCopyButton id={row.id} spaceId={row.space.id} icon />
                <ProductDeleteButton id={row.id} spaceId={row.space.id} icon />
              </ButtonGroup>,
            ])}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/products/products/_create')}>
              {i18next.t('products-list-add')}
            </Button>
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<ImportExportIcon />} onClick={() => navigate('/products/import')}>
              {i18next.t('products-list-import')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
