import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Grid, CircularProgress, Button, ButtonGroup } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';

import SimpleTable from 'components/table/SimpleTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { INTEGRATION_SECRETS_LIST_QUERY } from './gql';
import { SecretDeleteButton } from './secret';
import { ErrorAlert } from 'pages/error';

export default function IntegrationSecretsList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(INTEGRATION_SECRETS_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
    },
  });

  return (
    <>
      <Helmet>
        <title>{i18next.t('integration-secrets-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('integration-secrets-list-header-name'),
                  i18next.t('integration-secrets-list-header-system'),
                  i18next.t('integration-secrets-list-header-key'),
                  ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                  '',
                ]}
                rows={data.listIntegrationSecrets.map(row => [
                  <Link to={`/settings/integration/secrets/${row.id}`}>{row.name}</Link>,
                  `${i18next.t('enums-EIntSystem-' + row.system)}`,
                  row.key,
                  ...(user.isSingleSpace ? [] : [row.space.name]),
                  <ButtonGroup>
                    <SecretDeleteButton id={row.id} spaceId={row.space.id} icon />
                  </ButtonGroup>,
                ])}
              />
            </Grid>
            {hasAnyAdminSpace(user) && (
              <Grid item xs={12}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/settings/integration/secrets/_create')}>
                  {i18next.t('integration-secrets-list-add')}
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
