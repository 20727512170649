import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';
import MediaList from './medias';

export default function ProductsIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route index element={<MediaList />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
