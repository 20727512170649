import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { Alert, Button, IconButton, Tooltip, Grid, CircularProgress, Box } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray, FormProvider, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputRadio } from 'components/form/FormInputRadio';
import { FormInputCurrency } from 'components/form/FormInputCurrency';
import { FormInputMultiCheckbox } from 'components/form/FormInputMultiCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import ScrollToHashElement from 'components/ScrollToHashElement';
import { filterSelector } from 'helper/filter';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, filterForSelectableRecords } from 'helper/security';

import {
  PRICESELECTOR_VIEW_QUERY,
  PRICESELECTORBASE_VIEW_QUERY,
  PRICELIST_VIEW_QUERY,
  TAXTYPES_LIST_FULL_QUERY,
  UPDATE_PRICESELECTOR_MUTATION,
  CREATE_PRICESELECTOR_MUTATION,
  DELETE_PRICESELECTOR_MUTATION,
  COPY_PRICESELECTOR_MUTATION,
  REFETCH_PRICESELECTORS_QUERIES,
  EVICT_PRICESELECTORS_QUERIES,
} from './gql';
import { PRODUCTBUNDLES_COMPONENTS_QUERY, FACILITIES_COMPONENTS_QUERY, PRODUCTS_COMPONENTS_QUERY } from '../products/gql';

import {
  PriceSelectorListOutput,
  ProductBundleListOutput,
  FacilityListOutput,
  ProductListOutput,
  PriceListListOutput,
  TaxTypeListOutput,
  EPricingWeekDays,
} from '__generated__/graphql';
import { formatTaxTypeName } from 'components/tax/TaxComponentSelectionInput';
import { RedirectError } from 'pages/error';

interface PriceSelectorProps {
  priceListId: number;
  id: number;
}
interface PriceSelectorCreateProps {
  priceListId: number;
}
interface PriceSelectorFormProps {
  data: PriceSelectorListOutput;
  baseSelector: PriceSelectorListOutput;
  bundles: ProductBundleListOutput[];
  facilities: FacilityListOutput[];
  products: ProductListOutput[];
  taxTypes: TaxTypeListOutput[];
}

const componentsRequiredSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  price: yup.number().required().label(i18next.t('selector-multitax-price')),
});

const componentsOptionalSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  price: yup.number().nullable(),
});

const productsSchema = yup.object().shape({
  linkId: yup.number().required(),
  linkSpace: yup.string().required(),
  linkSpaceId: yup.number().required(),
  name: yup.string(),
  sku: yup.string(),
  price: yup.number().nullable(),
  multiTax: yup.boolean().required(),
  singleTaxTypeId: yup.number().nullable(),
  components: yup
    .array()
    .required()
    .when(['multiTax', 'price'], ([multiTax, price], schema) => {
      if (multiTax && price) {
        return schema.of(componentsRequiredSchema).required();
      } else {
        return schema.of(componentsOptionalSchema);
      }
    }),
});

type CollectionType = 'productsPrices' | 'bundlesPrices' | 'facilitiesPrices';

enum EBundleMode {
  NONE = 'NONE',
  PRODUCTS = 'PRODUCTS',
  PRICE = 'PRICE',
}

const bundlesSchema = yup.object().shape({
  linkId: yup.number().required(),
  linkSpace: yup.string().required(),
  linkSpaceId: yup.number().required(),
  name: yup.string(),
  sku: yup.string(),
  price: yup.number().nullable(),
  multiTax: yup.boolean().required(),
  singleTaxTypeId: yup.number().nullable(),
  mode: yup.string().required().oneOf(Object.keys(EBundleMode)),
  components: yup
    .array()
    .required()
    .when(['multiTax', 'mode'], ([multiTax, mode], schema) => {
      if (multiTax && mode === 'PRICE') {
        return schema.of(componentsRequiredSchema).required();
      } else {
        return schema.of(componentsOptionalSchema);
      }
    }),
});
const facilitiesSchema = yup.object().shape({
  linkId: yup.number().required(),
  linkSpace: yup.string().required(),
  linkSpaceId: yup.number().required(),
  name: yup.string(),
  sku: yup.string(),
  price: yup.number().nullable(),
  multiTax: yup.boolean().required(),
  singleTaxTypeId: yup.number().nullable(),
  components: yup
    .array()
    .required()
    .when(['multiTax', 'price'], ([multiTax, price], schema) => {
      if (multiTax && price) {
        return schema.of(componentsRequiredSchema).required();
      } else {
        return schema.of(componentsOptionalSchema);
      }
    }),
});

const validationSchema = yup.object().shape({
  name: yup.string().required().label(i18next.t('selector-name')),
  isBasePrice: yup.boolean().required(),
  minGuestCount: yup.number().nullable(),
  maxGuestCount: yup.number().nullable(),
  minItemCount: yup.number().nullable(),
  maxItemCount: yup.number().nullable(),
  validFrom: yup.date().nullable(),
  validTo: yup.date().nullable(),
  weekdays: yup
    .array()
    .nullable()
    .of(
      yup
        .mixed<EPricingWeekDays>()
        .required()
        .test(value => Object.values(EPricingWeekDays).includes(value as EPricingWeekDays)),
    ),
  productsPrices: yup.array().of(productsSchema).required(),
  bundlesPrices: yup.array().of(bundlesSchema).required(),
  facilitiesPrices: yup.array().of(facilitiesSchema).required(),
});
type PriceSelectorFormType = yup.InferType<typeof validationSchema>;

interface MultiTaxFormProps {
  taxTypes: TaxTypeListOutput[];
  country: string;
  collection: CollectionType;
  index: number;
  currency: string;
  disabled?: boolean;
  onPriceComponentChanged?: (collection: CollectionType, index: number, cIndex: number) => void;
}

interface MultiTaxItemFormProps extends MultiTaxFormProps {
  cIndex: number;
}

function MultiTaxItemForm(props: MultiTaxItemFormProps) {
  const { control, watch, getValues } = useFormContext<PriceSelectorFormType>();

  const priceComponentWatch = watch(`${props.collection}.${props.index}.components.${props.cIndex}.price`);
  useEffect(() => {
    if (props.onPriceComponentChanged) {
      props.onPriceComponentChanged(props.collection, props.index, props.cIndex);
    }
  }, [priceComponentWatch]);

  return (
    <FormInputCurrency
      name={`${props.collection}.${props.index}.components.${props.cIndex}.price`}
      control={control}
      label={formatTaxTypeName(props.taxTypes, getValues(`${props.collection}.${props.index}.components.${props.cIndex}.taxTypeId`), props.country)}
      currency={props.currency}
      disabled={props.disabled}
    />
  );
}

function MultiTaxForm(props: MultiTaxFormProps) {
  const { control } = useFormContext<PriceSelectorFormType>();

  const { fields: componentsFields } = useFieldArray({
    control: control,
    name: `${props.collection}.${props.index}.components`,
  });

  return (
    <Grid container spacing={3}>
      {componentsFields.map((field, cIndex) => (
        <Grid item key={cIndex}>
          <MultiTaxItemForm {...props} cIndex={cIndex} />
        </Grid>
      ))}
    </Grid>
  );
}

interface SingleTaxFormProps {
  collection: CollectionType;
  index: number;
  currency: string;
  taxTypes: TaxTypeListOutput[];
  country: string;
  disabled?: boolean;
  onPriceChanged?: (collection: CollectionType, index: number) => void;
}

function SingleTaxForm(props: SingleTaxFormProps) {
  const { control, watch, getValues } = useFormContext<PriceSelectorFormType>();

  const { fields: componentsFields } = useFieldArray({
    control: control,
    name: `${props.collection}.${props.index}.components`,
  });

  const priceWatch = watch(`${props.collection}.${props.index}.price`);
  useEffect(() => {
    if (props.onPriceChanged) {
      props.onPriceChanged(props.collection, props.index);
    }
  }, [priceWatch]);

  return (
    <Grid container spacing={3}>
      {componentsFields.map((field, cIndex) => (
        <Grid item key={cIndex}>
          <FormInputCurrency
            name={`${props.collection}.${props.index}.price`}
            control={control}
            label={formatTaxTypeName(props.taxTypes, getValues(`${props.collection}.${props.index}.components.${cIndex}.taxTypeId`), props.country)}
            currency={props.currency}
            disabled={props.disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
}

interface BundleModeListenerFormProps {
  index: number;
  onModeChanged: (index: number) => void;
}

function BundleModeListenerForm(props: BundleModeListenerFormProps) {
  const { control, watch, getValues } = useFormContext<PriceSelectorFormType>();

  const modeWatch = watch(`bundlesPrices.${props.index}.mode`);
  useEffect(() => {
    props.onModeChanged(props.index);
  }, [modeWatch]);

  return null;
}

function PriceSelectorForm(props: PriceSelectorFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [updateMutateFunction] = useMutation(UPDATE_PRICESELECTOR_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_PRICESELECTOR_MUTATION);

  const filter = filterSelector();
  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [updatedCycle, setUpdatedCycle] = useState(0);
  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/pricelists/pricelists/${props.data.priceList.id}/selectors/${createdId}`);
  }, [createdId]);

  const _filterPrices = (p: any) => {
    if (!filter || !filter.spaceId) return true;
    if (p.price !== null && p.price !== undefined) return true;
    if (p.linkSpaceId === filter.spaceId) return true;
    return false;
  };

  const toFormSchema = (obj: PriceSelectorListOutput): PriceSelectorFormType => ({
    ...obj,
    validFrom: obj.validFrom ? new Date(obj.validFrom) : null,
    validTo: obj.validTo ? new Date(obj.validTo) : null,
    weekdays: obj.weekdays ? [...obj.weekdays] : null,
    productsPrices: filterForSelectableRecords(user, props.products, props.data.space.id, false, true).map(product => {
      const price = obj.prices.find(p => p.product && p.product.id === product.id);
      return {
        linkId: product.id,
        linkSpace: product.space.name,
        linkSpaceId: product.space.id,
        name: product.name + (product.extRefCode ? ` (${product.extRefCode})` : ''),
        sku: product.sku,
        price: price ? price.price : null,
        multiTax: product.components.length > 1,
        singleTaxTypeId: product.components.length > 1 || product.components.length === 0 ? null : product.components[0].taxType.id,
        components: product.components.map(c => {
          const pc = price ? price.components.find(pc => pc.taxType.id === c.taxType.id) : null;
          return {
            taxTypeId: c.taxType.id,
            price: pc ? pc.price : null,
          };
        }),
      };
    }),
    bundlesPrices: filterForSelectableRecords(user, props.bundles, props.data.space.id, false, true).map(bundle => {
      const price = obj.prices.find(p => p.bundle && p.bundle.id === bundle.id);
      const mode = price ? (price.bundlePriceFromProduct ? EBundleMode.PRODUCTS : EBundleMode.PRICE) : EBundleMode.NONE;
      return {
        linkId: bundle.id,
        linkSpace: bundle.space.name,
        linkSpaceId: bundle.space.id,
        name: bundle.name + (bundle.extRefCode ? ` (${bundle.extRefCode})` : ''),
        sku: bundle.sku,
        mode: mode,
        price: price ? price.price : null,
        multiTax: mode === EBundleMode.PRODUCTS ? true : bundle.components.length > 1,
        singleTaxTypeId: bundle.components.length > 1 || bundle.components.length === 0 ? null : bundle.components[0].taxType.id,
        components: bundle.components.map(c => {
          const pc = price ? price.components.find(pc => pc.taxType.id === c.taxType.id) : null;
          return {
            taxTypeId: c.taxType.id,
            price: pc ? pc.price : null,
          };
        }),
      };
    }),
    facilitiesPrices: filterForSelectableRecords(user, props.facilities, props.data.space.id, false, true).map(facility => {
      const price = obj.prices.find(p => p.facility && p.facility.id === facility.id);
      return {
        linkId: facility.id,
        linkSpace: facility.space.name,
        linkSpaceId: facility.space.id,
        name: facility.name + (facility.extRefCode ? ` (${facility.extRefCode})` : ''),
        sku: facility.sku,
        price: price ? price.price : null,
        multiTax: facility.components.length > 1,
        singleTaxTypeId: facility.components.length > 1 || facility.components.length === 0 ? null : facility.components[0].taxType.id,
        components: facility.components.map(c => {
          const pc = price ? price.components.find(pc => pc.taxType.id === c.taxType.id) : null;
          return {
            taxTypeId: c.taxType.id,
            price: pc ? pc.price : null,
          };
        }),
      };
    }),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as PriceSelectorListOutput),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = form;

  const { fields: productsFields } = useFieldArray({
    control,
    name: 'productsPrices',
  });
  const { fields: bundlesFields } = useFieldArray({
    control,
    name: 'bundlesPrices',
  });
  const { fields: facilitiesFields } = useFieldArray({
    control,
    name: 'facilitiesPrices',
  });
  useEffect(() => {
    trigger();
  }, [trigger]);

  const onPriceComponentChanged = (collection: CollectionType, index: number, cIndex: number) => {
    const collectionItem = getValues(`${collection}.${index}`);
    if (collectionItem && collectionItem.components) {
      if (collectionItem.components.find(c => c.price !== null && c.price !== undefined)) {
        const price = collectionItem.components.reduce((s, c) => s + c.price, 0);
        setValue(`${collection}.${index}.price`, price);
      } else {
        setValue(`${collection}.${index}.price`, null);
      }
    }
    if (collection === 'productsPrices') {
      onProductPriceChanged(index);
    }
  };
  const onProductPriceChanged = (index: number) => {
    const bundlesPrices = getValues('bundlesPrices');
    const productsPrices = getValues('productsPrices');
    for (const [bundlePriceIndex, bundlePrice] of bundlesPrices.entries()) {
      const bundle = props.bundles.find(b => b.sku === bundlePrice.sku);

      if (!bundle || bundlePrice.mode !== EBundleMode.PRODUCTS) continue;

      let productPriceSum = 0;
      for (const productBundleItem of bundle.bundleItems) {
        const productPrice = productsPrices.find(p => p.sku === productBundleItem.product.sku);
        if (productPrice && productPrice.price !== null && productPrice.price !== undefined) {
          productPriceSum += productPrice.price * productBundleItem.includedCount;
        } else if (props.data.id !== props.baseSelector.id) {
          const baseProductPrice = props.baseSelector.prices.find(p => p.product && p.product.sku === productBundleItem.product.sku);
          if (baseProductPrice && baseProductPrice.price !== null && baseProductPrice.price !== undefined) {
            productPriceSum += baseProductPrice.price * productBundleItem.includedCount;
          }
        }
      }
      setValue(`bundlesPrices.${bundlePriceIndex}.price`, productPriceSum);
    }
  };

  const onSubmit = async (values: PriceSelectorFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              minGuestCount: values.minGuestCount,
              maxGuestCount: values.maxGuestCount,
              minItemCount: values.minItemCount,
              maxItemCount: values.maxItemCount,
              validFrom: values.validFrom,
              validTo: values.validTo,
              weekdays: values.weekdays,
              prices: [
                ...values.productsPrices
                  .filter(p => p.price !== null && p.price !== undefined)
                  .map(p => ({
                    productId: p.linkId,
                    price: p.price!,
                    components:
                      (p.multiTax &&
                        p.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.facilitiesPrices
                  .filter(p => p.price !== null && p.price !== undefined)
                  .map(p => ({
                    facilityId: p.linkId,
                    price: p.price!,
                    components:
                      (p.multiTax &&
                        p.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.bundlesPrices
                  .filter(b => b.mode === EBundleMode.PRICE && b.price !== null && b.price !== undefined)
                  .map(b => ({
                    bundleId: b.linkId,
                    price: b.price,
                    bundlePriceFromProduct: false,
                    components:
                      (b.multiTax &&
                        b.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.bundlesPrices
                  .filter(b => b.mode === EBundleMode.PRODUCTS)
                  .map(b => ({
                    bundleId: b.linkId,
                    price: null,
                    bundlePriceFromProduct: true,
                    components: [],
                  })),
              ],
            },
          },
          update: cache => EVICT_PRICESELECTORS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRICESELECTORS_QUERIES(props.data.priceList.id, props.data.id),
        });
        reset(toFormSchema((res.data!.updatePriceSelector || {}) as PriceSelectorListOutput));
        setUpdatedCycle(updatedCycle + 1);
      } else {
        const res = await createMutateFunction({
          variables: {
            data: {
              priceListId: props.data.priceList.id,
              name: values.name,
              minGuestCount: values.minGuestCount,
              maxGuestCount: values.maxGuestCount,
              minItemCount: values.minItemCount,
              maxItemCount: values.maxItemCount,
              validFrom: values.validFrom,
              validTo: values.validTo,
              weekdays: values.weekdays,
              prices: [
                ...values.productsPrices
                  .filter(p => p.price !== null && p.price !== undefined)
                  .map(p => ({
                    productId: p.linkId,
                    price: p.price!,
                    components:
                      (p.multiTax &&
                        p.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.facilitiesPrices
                  .filter(p => p.price !== null && p.price !== undefined)
                  .map(p => ({
                    facilityId: p.linkId,
                    price: p.price!,
                    components:
                      (p.multiTax &&
                        p.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.bundlesPrices
                  .filter(b => b.mode === EBundleMode.PRICE && b.price !== null && b.price !== undefined)
                  .map(b => ({
                    bundleId: b.linkId,
                    price: b.price,
                    bundlePriceFromProduct: false,
                    components:
                      (b.multiTax &&
                        b.components?.map(c => ({
                          price: c.price,
                          taxTypeId: c.taxTypeId,
                        }))) ||
                      [],
                  })),
                ...values.bundlesPrices
                  .filter(b => b.mode === EBundleMode.PRODUCTS)
                  .map(b => ({
                    bundleId: b.linkId,
                    price: null,
                    bundlePriceFromProduct: true,
                    components: [],
                  })),
              ],
            },
          },
          update: cache => EVICT_PRICESELECTORS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRICESELECTORS_QUERIES(props.data.priceList.id),
        });
        reset(toFormSchema((res.data!.createPriceSelector || {}) as PriceSelectorListOutput));
        setCreatedId(res.data!.createPriceSelector.id);
      }
      dispatchMessage(dispatch, i18next.t('selector-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const initialPricesTabSelected = location.hash ? (location.hash.startsWith('#PRF') ? 2 : location.hash.startsWith('#PRB') ? 1 : 0) : 0;

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('pricelist-selector-page-title')} {props.data.id > 0 ? props.data.name : ''}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <FormProvider {...form}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <Grid item xs={12}>
            <Link to={`/pricelists/pricelists/${props.data.priceList.id}`}>{i18next.t('selector-backlink')}</Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText name="name" control={control} label={i18next.t('selector-name')} required disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputCheckbox name="isBasePrice" control={control} label={i18next.t('selector-isbaseprice')} disabled />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputNumber name="minGuestCount" control={control} label={i18next.t('selector-minguestcount')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputNumber name="maxGuestCount" control={control} label={i18next.t('selector-maxguestcount')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputNumber name="minItemCount" control={control} label={i18next.t('selector-minitemcount')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputNumber name="maxItemCount" control={control} label={i18next.t('selector-maxitemcount')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputDate name="validFrom" control={control} label={i18next.t('selector-validfrom')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputDate name="validTo" control={control} label={i18next.t('selector-validto')} disabled={!canEdit} />
          </Grid>
          <Grid item xs={12}>
            <FormInputMultiCheckbox
              name="weekdays"
              control={control}
              label={i18next.t('selector-weekdays')}
              options={[
                EPricingWeekDays.MON,
                EPricingWeekDays.TUE,
                EPricingWeekDays.WED,
                EPricingWeekDays.THU,
                EPricingWeekDays.FRI,
                EPricingWeekDays.SAT,
                EPricingWeekDays.SUN,
              ].map(w => ({
                label: i18next.t(`enums-EPricingWeekDays-${w}`),
                value: w,
              }))}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTabs
              headers={[i18next.t('selector-products-tab'), i18next.t('selector-bundles-tab'), i18next.t('selector-facilities-tab')]}
              initialSelected={initialPricesTabSelected}
              icons={[
                validationErrors.productsPrices ? <ErrorIcon /> : undefined,
                validationErrors.bundlesPrices ? <ErrorIcon /> : undefined,
                validationErrors.facilitiesPrices ? <ErrorIcon /> : undefined,
              ]}
              tabs={[
                <SimpleTable
                  filter={productsFields.map((field, index) => _filterPrices(field))}
                  rows={productsFields.map((field, index) => [
                    <Link to={`/products/products/${field.linkId}`}>
                      {field.name}
                      <ScrollToHashElement id={watch(`productsPrices.${index}.sku`)} />
                    </Link>,
                    ...(user.isSingleSpace ? [] : [field.linkSpace]),
                    <FormInputCurrency
                      key={`${field.id}.price`}
                      name={`productsPrices.${index}.price`}
                      control={control}
                      label={i18next.t('selector-product-price')}
                      currency={props.data.priceList.currency}
                      disabled
                    />,
                    <>
                      {watch(`productsPrices.${index}.multiTax`) && (
                        <MultiTaxForm
                          key={`${field.id}.productsPrices`}
                          collection="productsPrices"
                          index={index}
                          onPriceComponentChanged={onPriceComponentChanged}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          disabled={!canEdit}
                        />
                      )}
                      {!watch(`productsPrices.${index}.multiTax`) && (
                        <SingleTaxForm
                          key={`${field.id}.productsPrices`}
                          collection="productsPrices"
                          index={index}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          onPriceChanged={(collection, index) => onProductPriceChanged(index)}
                          disabled={!canEdit}
                        />
                      )}
                    </>,
                  ])}
                />,
                <SimpleTable
                  key={`${updatedCycle}.bundles`}
                  filter={bundlesFields.map((field, index) => _filterPrices(field))}
                  rows={bundlesFields.map((field, index) => [
                    <Link to={`/products/bundles/${field.linkId}`}>
                      {field.name}
                      <ScrollToHashElement id={watch(`bundlesPrices.${index}.sku`)} />
                    </Link>,
                    ...(user.isSingleSpace ? [] : [field.linkSpace]),
                    <FormInputRadio
                      key={`${field.id}.mode`}
                      name={`bundlesPrices.${index}.mode`}
                      control={control}
                      options={[
                        {
                          label: i18next.t('enums-EBundleMode-NONE'),
                          value: EBundleMode.NONE,
                        },
                        {
                          label: i18next.t('enums-EBundleMode-PRODUCTS'),
                          value: EBundleMode.PRODUCTS,
                        },
                        {
                          label: i18next.t('enums-EBundleMode-PRICE'),
                          value: EBundleMode.PRICE,
                        },
                      ]}
                      disabled={!canEdit}
                    />,
                    watch(`bundlesPrices.${index}.mode`) !== EBundleMode.NONE && (
                      <>
                        <FormInputCurrency
                          key={`${field.id}.price`}
                          name={`bundlesPrices.${index}.price`}
                          control={control}
                          label={i18next.t('selector-bundle-price')}
                          currency={props.data.priceList.currency}
                          disabled
                        />
                        <BundleModeListenerForm index={index} onModeChanged={() => onProductPriceChanged(0)} />
                      </>
                    ),
                    <Box sx={{ width: 210 }}>
                      {watch(`bundlesPrices.${index}.mode`) === EBundleMode.PRICE && watch(`bundlesPrices.${index}.multiTax`) && (
                        <MultiTaxForm
                          key={`${field.id}.bundlesPrices`}
                          collection="bundlesPrices"
                          index={index}
                          onPriceComponentChanged={onPriceComponentChanged}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          disabled={!canEdit}
                        />
                      )}
                      {watch(`bundlesPrices.${index}.mode`) === EBundleMode.PRICE && !watch(`bundlesPrices.${index}.multiTax`) && (
                        <SingleTaxForm
                          key={`${field.id}.bundlesPrices`}
                          collection="bundlesPrices"
                          index={index}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          disabled={!canEdit}
                        />
                      )}
                    </Box>,
                  ])}
                />,
                <SimpleTable
                  filter={facilitiesFields.map((field, index) => _filterPrices(field))}
                  rows={facilitiesFields.map((field, index) => [
                    <Link to={`/products/facilities/${field.linkId}`}>
                      {field.name}
                      <ScrollToHashElement id={watch(`facilitiesPrices.${index}.sku`)} />
                    </Link>,
                    ...(user.isSingleSpace ? [] : [field.linkSpace]),
                    <FormInputCurrency
                      key={`${field.id}.price`}
                      name={`facilitiesPrices.${index}.price`}
                      control={control}
                      label={i18next.t('selector-facility-price')}
                      currency={props.data.priceList.currency}
                      disabled
                    />,
                    <>
                      {watch(`facilitiesPrices.${index}.multiTax`) && (
                        <MultiTaxForm
                          key={`${field.id}.facilitiesPrices`}
                          collection="facilitiesPrices"
                          index={index}
                          onPriceComponentChanged={onPriceComponentChanged}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          disabled={!canEdit}
                        />
                      )}
                      {!watch(`facilitiesPrices.${index}.multiTax`) && (
                        <SingleTaxForm
                          key={`${field.id}.facilitiesPrices`}
                          collection="facilitiesPrices"
                          index={index}
                          taxTypes={props.taxTypes}
                          country={props.data.priceList.space.country}
                          currency={props.data.priceList.currency}
                          disabled={!canEdit}
                        />
                      )}
                    </>,
                  ])}
                />,
              ]}
            />
          </Grid>
          {canEdit && (
            <Grid item xs={12}>
              {showValidationAlert(validationErrors)}
            </Grid>
          )}
          <Grid item xs={12}>
            {canEdit && (
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
                onClick={async () => {
                  const valid = await trigger();
                  if (valid) {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                {i18next.t('selector-save')}
              </Button>
            )}
            {canEdit && !props.data.isBasePrice && props.data.id > 0 && (
              <PriceSelectorDeleteButton id={props.data.id} priceListId={props.data.priceList.id} spaceId={props.data.space.id} icon={false} />
            )}
            {canEdit && props.data.id > 0 && (
              <PriceSelectorCopyButton id={props.data.id} priceListId={props.data.priceList.id} spaceId={props.data.space.id} icon={false} />
            )}
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}

export default function PriceSelector(props: PriceSelectorProps) {
  const priceSelectorQuery = useQuery(PRICESELECTOR_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const baseSelectorQuery = useQuery(PRICESELECTORBASE_VIEW_QUERY, {
    variables: { priceListId: props.priceListId },
  });
  const productsQuery = useQuery(PRODUCTS_COMPONENTS_QUERY);
  const productBundlesQuery = useQuery(PRODUCTBUNDLES_COMPONENTS_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_COMPONENTS_QUERY);
  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);

  const loading =
    baseSelectorQuery.loading ||
    productsQuery.loading ||
    productBundlesQuery.loading ||
    facilitiesQuery.loading ||
    taxTypesQuery.loading ||
    priceSelectorQuery.loading;
  const error =
    baseSelectorQuery.error ||
    productsQuery.error ||
    productBundlesQuery.error ||
    facilitiesQuery.error ||
    taxTypesQuery.error ||
    priceSelectorQuery.error;

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;
  else {
    return (
      <PriceSelectorForm
        data={priceSelectorQuery.data!.viewPriceSelector! as PriceSelectorListOutput}
        baseSelector={baseSelectorQuery.data!.viewBasePriceSelector! as PriceSelectorListOutput}
        products={productsQuery.data!.listProducts as ProductListOutput[]}
        bundles={productBundlesQuery.data!.listProductBundles as ProductBundleListOutput[]}
        facilities={facilitiesQuery.data!.listFacilities as FacilityListOutput[]}
        taxTypes={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
  }
}

export function PriceSelectorCreate(props: PriceSelectorCreateProps) {
  const priceListQuery = useQuery(PRICELIST_VIEW_QUERY, {
    variables: { id: props.priceListId },
  });
  const baseSelectorQuery = useQuery(PRICESELECTORBASE_VIEW_QUERY, {
    variables: { priceListId: props.priceListId },
  });
  const productsQuery = useQuery(PRODUCTS_COMPONENTS_QUERY);
  const productBundlesQuery = useQuery(PRODUCTBUNDLES_COMPONENTS_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_COMPONENTS_QUERY);
  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);

  const loading =
    priceListQuery.loading ||
    baseSelectorQuery.loading ||
    productsQuery.loading ||
    productBundlesQuery.loading ||
    facilitiesQuery.loading ||
    taxTypesQuery.loading;

  const error =
    priceListQuery.error ||
    baseSelectorQuery.error ||
    productsQuery.error ||
    productBundlesQuery.error ||
    facilitiesQuery.error ||
    taxTypesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else {
    return (
      <PriceSelectorForm
        data={{
          id: -1,
          name: '',
          isBasePrice: false,
          priceList: priceListQuery.data?.viewPriceList! as PriceListListOutput,
          prices: [],
          weekdays: [],
          space: priceListQuery.data!.viewPriceList!.space as any,
        }}
        baseSelector={baseSelectorQuery.data!.viewBasePriceSelector! as PriceSelectorListOutput}
        products={productsQuery.data!.listProducts as ProductListOutput[]}
        bundles={productBundlesQuery.data!.listProductBundles as ProductBundleListOutput[]}
        facilities={facilitiesQuery.data!.listFacilities as FacilityListOutput[]}
        taxTypes={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
  }
}

interface PriceSelectorCopyButtonProps {
  id: number;
  priceListId: number;
  spaceId: number;
  icon: boolean;
}
export function PriceSelectorCopyButton(props: PriceSelectorCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isAdmin && user.space;

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_PRICESELECTOR_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id },
        update: cache => EVICT_PRICESELECTORS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRICESELECTORS_QUERIES(props.priceListId),
      });
      dispatchMessage(dispatch, i18next.t('selector-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <IconButton disabled={!canEdit || copyMutateLoading} onClick={__do}>
        <Tooltip title={i18next.t('selector-copy')}>
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    );
  } else {
    return (
      <Button
        sx={{ marginRight: 2 }}
        variant="contained"
        color="secondary"
        disabled={!canEdit || copyMutateLoading}
        startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
        onClick={__do}
      >
        {i18next.t('selector-copy')}
      </Button>
    );
  }
}

interface PriceSelectorDeleteButtonProps {
  id: number;
  priceListId: number;
  spaceId: number;
  icon: boolean;
}
export function PriceSelectorDeleteButton(props: PriceSelectorDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_PRICESELECTOR_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('selector-confirm-delete')}
      confirmationTitle={i18next.t('selector-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_PRICESELECTORS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_PRICESELECTORS_QUERIES(props.priceListId),
          });
          navigate(`/pricelists/pricelists/${props.priceListId}`);
          dispatchMessage(dispatch, i18next.t('selector-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('selector-delete')}
    </ConfirmationButton>
  );
}
