export function exportBase64ToFile(fileContent: string, mimeType: string, filename: string) {
  const fileUrl = 'data:' + mimeType + ';base64,' + fileContent;
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = filename;
      link.href = url;
      link.click();
    });
}

export function exportToFile(fileContent: string, mimeType: string, filename: string) {
  const blob = new Blob([fileContent], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
}

export function getFullBackendUrl(path: string = '', absolute: boolean = false) {
  if (process.env.REACT_APP_BACKEND_API) {
    return `${process.env.REACT_APP_BACKEND_API}${path.startsWith('/') ? '' : '/'}${path}`;
  } else if (absolute) {
    const url = new URL(window.location.href);
    const base = url.href.replace(url.pathname, '');
    return `${base}${path.startsWith('/') ? '' : '/'}${path}`;
  } else {
    return path;
  }
}
