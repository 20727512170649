import React from 'react';
import moment from 'moment';

interface DateProps {
  date: any;
}

interface DateRangeProps {
  from?: any | null;
  to?: any | null;
}

const dateFormat = new Intl.DateTimeFormat(undefined, { dateStyle: 'medium' });
const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
  timeStyle: 'medium',
});

export function formatDate(date: any) {
  if (date) return dateFormat.format(moment(date).toDate());
  else return null;
}

export function FormatDate(props: DateProps) {
  const { date } = props;

  if (date) return <>{dateFormat.format(moment(date).toDate())}</>;
  else return null;
}

export function FormatDateRange(props: DateRangeProps) {
  const { from, to } = props;

  return (
    <>
      {from ? <FormatDate date={from} /> : '*'} - {to ? <FormatDate date={to} /> : '*'}
    </>
  );
}

export function FormatDateTime(props: DateProps) {
  const { date } = props;
  if (date) return <>{dateTimeFormat.format(moment(date).toDate())}</>;
  else return null;
}

export function FormatFromNow(props: DateProps) {
  const { date } = props;
  if (date) return <>{moment(date).fromNow()}</>;
  else return null;
}
