import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormTextInputProps } from './FormInputProps';

export const FormInputPassword = (props: FormTextInputProps) => {
  return <FormInputText {...props} textFieldProps={{ ...props.textFieldProps, type: 'password' }} />;
};

export const FormInputText = ({ register, name, control, label, readOnly, disabled, textFieldProps, inputProps, helperText }: FormTextInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : helperText ? helperText : ' '}
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          InputLabelProps={{
            ...(disabled ? { shrink: true } : {}),
            ...(textFieldProps && textFieldProps.InputLabelProps ? textFieldProps.InputLabelProps : {}),
          }}
          label={label}
          {...(typeof disabled !== 'undefined' ? { disabled } : {})}
          inputProps={{
            ...(typeof readOnly !== 'undefined' ? { readOnly } : {}),
            ...inputProps,
          }}
          {...textFieldProps}
        />
      )}
      {...register}
    />
  );
};
