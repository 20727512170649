import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormTextInputProps } from './FormInputProps';
import { FormControl, FormLabel, styled } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';

export const FormColorPicker = ({ name, control, label, disabled, readOnly, textFieldProps }: FormTextInputProps) => {
  const { watch, setValue } = useFormContext();
  const [value, setValueState] = useState<any>(watch(name));

  return (
    <>
      <FormControl fullWidth>
        {label && (
          <FormLabel>
            {label}
            <br />
          </FormLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MuiColorInput
              disabled={disabled}
              value={value}
              onChange={val => {
                setValue(name, val);
                onChange(val);
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
};
