import { EIntSystem, WLOfferVersionListOutput } from '__generated__/graphql';

export const canPMSTestSecret = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canPMSLinkProperty = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canPMSLinkInventory = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canPMSSyncProperty = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canPMSSyncInventory = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canPMSSyncPrices = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  if (system === 'ELITEFO') return true;
  if (system === 'PROTELIDS') return true;
  return false;
};

export const canPMSSyncAvailability = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  if (system === 'ELITEFO') return true;
  if (system === 'PROTELIDS') return true;
  return false;
};

export const canPMSSyncBookings = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  if (system === 'ELITEFO') return true;
  if (system === 'PROTELIDS') return true;
  return false;
};

export const canPMSConfirmBookings = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  if (system === 'ELITEFO') return true;
  return false;
};

export const canPMSTriggerSync = (system: EIntSystem | undefined) => {
  if (system === 'DUMMY') return true;
  if (system === 'APALEO') return true;
  return false;
};

export const canBookingOpenPMS = (offerVersion: WLOfferVersionListOutput) => {
  const integration = offerVersion.hotel.integrations[0];
  if (!integration) return false;

  if (integration.secret.system === EIntSystem.ELITEFO || integration.secret.system === EIntSystem.PROTELIDS) return false;
  else return !!offerVersion.extRefCode;
};
export const canBookingSendPMS = (offerVersion: WLOfferVersionListOutput) => {
  const integration = offerVersion.hotel.integrations[0];
  if (!integration) return false;

  return true;
};
export const canBookingConfirmPMS = (offerVersion: WLOfferVersionListOutput) => {
  const integration = offerVersion.hotel.integrations[0];
  if (!integration) return false;

  if (integration.secret.system === EIntSystem.ELITEFO) return true;
  else return !!offerVersion.extRefCode;
};
export const canBookingDeletePMS = (offerVersion: WLOfferVersionListOutput) => {
  const integration = offerVersion.hotel.integrations[0];
  if (!integration) return false;

  if (integration.secret.system === EIntSystem.ELITEFO || integration.secret.system === EIntSystem.PROTELIDS) return true;
  else return !!offerVersion.extRefCode;
};
