import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Routes, Route, Outlet, useParams } from 'react-router-dom';
import ContentProductsList from './products';
import ContentProduct, { ContentProductCreate } from './product';

export default function ProductsIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <Routes>
              <Route index element={<ContentProductsList />} />
              <Route path="_create" element={<ContentProductCreate />} />
              <Route path=":id" Component={() => <ContentProduct id={parseInt(useParams().id!)} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
