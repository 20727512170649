import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Grid, CircularProgress, Button, IconButton, Tooltip, Card, CardContent, CardHeader } from '@mui/material';
import _ from 'lodash';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';
import { allLanguages } from 'languages';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId } from 'helper/security';
import { filterSelector } from 'helper/filter';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';

import {
  EMAILTEMPLATE_VIEW_QUERY,
  UPDATE_EMAILTEMPLATE_MUTATION,
  CREATE_EMAILTEMPLATE_MUTATION,
  DELETE_EMAILTEMPLATE_MUTATION,
  COPY_EMAILTEMPLATE_MUTATION,
  PREVIEW_EMAILTEMPLATE_QUERY,
  CONTENTMEDIAS_LIST_QUERY,
  EXPORT_EMAILTEMPLATE_MUTATION,
  EVICT_EMAILTEMPLATES_QUERIES,
  REFETCH_EMAILTEMPLATES_QUERIES,
} from '../gql';

import { EmailTemplateListOutput, EContentEmailTemplate } from '__generated__/graphql';
import { RedirectError } from 'pages/error';

interface EmailTemplateProps {
  id: number;
}
interface EmailTemplateCreateProps {}
interface EmailTemplateFormProps {
  data: EmailTemplateListOutput;
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  usage: yup.string().required(),
  language: yup.string().nullable(),
  subject: yup.string().required().label(i18next.t('emailtemplate-subject')),
  body: yup.string().required().label(i18next.t('emailtemplate-body')),
});

function EmailTemplateForm(props: EmailTemplateFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [previewResult, setPreviewResult] = useState({
    show: false,
    subject: undefined as string | undefined,
    body: undefined as string | undefined,
  });

  const [updateMutateFunction] = useMutation(UPDATE_EMAILTEMPLATE_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_EMAILTEMPLATE_MUTATION);
  const [exportMutateFunction, { loading: exportLoading }] = useMutation(EXPORT_EMAILTEMPLATE_MUTATION);
  const [previewQuery, { loading: previewLoading }] = useLazyQuery(PREVIEW_EMAILTEMPLATE_QUERY);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/content/templates/emailtemplates/${createdId}`);
  }, [createdId]);

  type EmailTemplateFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: EmailTemplateListOutput): EmailTemplateFormType => ({
    isCreate: props.data.id > 0 ? false : true,
    spaceId: obj.space.id,
    usage: obj.usage,
    language: obj.language,
    subject: obj.subject,
    body: obj.body,
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    formState: { isDirty, isValidating, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as EmailTemplateListOutput),
  });
  const formValues = getValues();

  const onSubmit = async (values: EmailTemplateFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              language: values.language || null,
              subject: values.subject,
              body: values.body,
            },
          },
          update: cache => EVICT_EMAILTEMPLATES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateEmailTemplate || {}) as EmailTemplateListOutput));
        dispatchMessage(dispatch, i18next.t('emailtemplate-updated'));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              usage: values.usage as EContentEmailTemplate,
              language: values.language || null,
              subject: values.subject,
              body: values.body,
            },
          },
          update: cache => EVICT_EMAILTEMPLATES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(),
        });
        reset(toFormSchema((res.data!.createEmailTemplate || {}) as EmailTemplateListOutput));
        setCreatedId(res.data!.createEmailTemplate.id);
        dispatchMessage(dispatch, i18next.t('emailtemplate-created'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('emailtemplates-list-page-title')} {props.data.id > 0 ? i18next.t('enums-EContentEmailTemplate-' + props.data.usage) : ''}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="usage"
            control={control}
            label={i18next.t('emailtemplate-usage')}
            options={_.sortBy(
              Object.keys(EContentEmailTemplate).map(p => ({
                value: p,
                label: `${i18next.t('enums-EContentEmailTemplate-' + p)}`,
              })),
              ['label'],
            )}
            disabled={!canEdit || props.data.id > 0}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="language"
            control={control}
            label={i18next.t('emailtemplate-language')}
            options={[
              {
                label: <em>{i18next.t('generic-selection-empty')}</em>,
                value: '',
              },
              ...allLanguages.map(l => (l ? { value: l.code, label: l.label } : ({ divider: true } as any))),
            ]}
            required
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText name="subject" control={control} label={i18next.t('emailtemplate-subject')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name="body"
            control={control}
            label={i18next.t('emailtemplate-body')}
            textFieldProps={{ fullWidth: true, multiline: true, rows: 10 }}
            required
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating || !isValid}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('emailtemplate-save')}
            </Button>
          )}
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={previewLoading ? <CircularProgress size={24} /> : <PreviewIcon />}
            disabled={previewLoading}
            onClick={async () => {
              try {
                const res = await previewQuery({
                  variables: {
                    spaceId: formValues.spaceId,
                    usage: formValues.usage as EContentEmailTemplate,
                    subject: formValues.subject,
                    body: formValues.body,
                  },
                  fetchPolicy: 'network-only',
                });
                if (res.error) {
                  dispatchException(dispatch, res.error);
                } else {
                  dispatchMessage(dispatch, i18next.t('emailtemplate-preview-created'));
                  setPreviewResult({
                    show: true,
                    subject: res.data?.previewEmailTemplate?.subject,
                    body: res.data?.previewEmailTemplate?.body,
                  });
                }
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {i18next.t('emailtemplate-preview')}
          </Button>
          {canEdit && props.data.id > 0 && <EmailTemplateDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <EmailTemplateCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={exportLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
              disabled={exportLoading}
              onClick={async () => {
                try {
                  const res = await exportMutateFunction({
                    variables: {
                      id: props.data.id,
                    },
                  });
                  if (res.data?.exportEmailTemplates) {
                    exportToFile(res.data?.exportEmailTemplates, 'application/json', 'seminargo-emailtemplate.json');
                  }
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('emailtemplates-export-run')}
            </Button>
          )}
        </Grid>
        {previewResult.show && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`${i18next.t('emailtemplate-subject')}: ${previewResult.subject}`} />
              <CardContent>
                <iframe srcDoc={previewResult.body} width={1000} height={600}></iframe>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default function Hotel(props: EmailTemplateProps) {
  const templateQuery = useQuery(EMAILTEMPLATE_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = templateQuery.loading;
  const error = templateQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <EmailTemplateForm data={templateQuery.data!.viewEmailTemplate as EmailTemplateListOutput} />;
}

export function EmailTemplateCreate(props: EmailTemplateCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <EmailTemplateForm
      data={
        {
          id: -1,
          usage: EContentEmailTemplate.BOOKING_RECEIVED_HOTEL,
          subject: '',
          body: '',
          medias: [],
          space: { id: initialSpaceId(user, filter) } as any,
        } as any
      }
    />
  );
}

interface EmailTemplateCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function EmailTemplateCopyButton(props: EmailTemplateCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isAdmin && user.space;

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_EMAILTEMPLATE_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id },
        update: cache => EVICT_EMAILTEMPLATES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('emailtemplate-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <IconButton disabled={!canEdit || copyMutateLoading} onClick={__do}>
        <Tooltip title={i18next.t('emailtemplate-copy')}>
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    );
  } else {
    return (
      <Button
        sx={{ marginRight: 2 }}
        variant="contained"
        color="secondary"
        disabled={!canEdit || copyMutateLoading}
        startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
        onClick={__do}
      >
        {i18next.t('emailtemplate-copy')}
      </Button>
    );
  }
}

interface EmailTemplateDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function EmailTemplateDeleteButton(props: EmailTemplateDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_EMAILTEMPLATE_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('emailtemplate-confirm-delete')}
      confirmationTitle={i18next.t('emailtemplate-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_EMAILTEMPLATES_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(),
          });
          navigate('/content/templates/emailtemplates');
          dispatchMessage(dispatch, i18next.t('emailtemplate-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('emailtemplate-delete')}
    </ConfirmationButton>
  );
}
