import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Typography } from '@mui/material';

import moment from 'moment';

import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CheckIcon from '@mui/icons-material/Check';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { extractClientErrors } from 'helper/apollo';
import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputFileUpload } from 'components/form/FormInputFileUpload';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import SimpleTable from 'components/table/SimpleTable';
import { filterSelector } from 'helper/filter';
import { userSelector, initialSpaceId } from 'helper/security';

import { IMPORT_PRICES_MUTATION, REFETCH_PRICELISTS_QUERIES, EVICT_PRICELISTS_QUERIES } from './gql';
import {
  ImportPriceListOutput,
  PriceListListOutput,
  ProductListOutput,
  PriceListValidationMissingProductComponentOutput,
  PriceListValidationMissingFacilityComponentOutput,
  PriceListValidationMissingBundleComponentOutput,
  ProductBundleListOutput,
  FacilityListOutput,
} from '__generated__/graphql';

interface ImportFormProps {}

const validationSchema = yup.object().shape({
  name: yup.string(),
  version: yup.string(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  file: yup.mixed().required(),
});

export function ImportForm(props: ImportFormProps) {
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const [importMutateFunction] = useMutation(IMPORT_PRICES_MUTATION);
  // const [exportMutateFunction] = useMutation(EXPORT_PRODUCTS_MUTATION)

  const [importResult, setImportResult] = useState({
    importMsg: undefined as string | undefined,
    results: [] as ImportPriceListOutput[],
  });

  type ImportFormType = yup.InferType<typeof validationSchema>;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: 'Preisliste',
      version: moment().format('YYYY-DD-MM HH:mm:ss'),
      spaceId: initialSpaceId(user, filter) || 0,
    },
  });

  const onSubmit = async (values: ImportFormType) => {
    try {
      const fileAny = values.file as any;

      const res = await importMutateFunction({
        variables: {
          spaceId: values.spaceId,
          hotelId: (filter && filter.hotelId) || null,
          name: values.name,
          version: values.version,
          jsonText: fileAny.name.endsWith('.json') ? fileAny.content : null,
          excelBase64: fileAny.name.endsWith('.xlsx') ? fileAny.content : null,
        },
        update: cache => EVICT_PRICELISTS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRICELISTS_QUERIES(),
      });
      reset({
        version: moment().format('YYYY-DD-MM HH:mm:ss'),
        file: null,
      } as any);
      setImportResult({
        importMsg: `${i18next.t('prices-import-ready')}`,
        results:
          res.data?.importPriceList.map(p => ({
            priceList: p.priceList as PriceListListOutput,
            validation: {
              isComplete: p.validation.isComplete,
              productsWithoutPrice: (p.validation.productsWithoutPrice as ProductListOutput[]) || [],
              productsWithoutPriceComponents:
                (p.validation.productsWithoutPriceComponents as PriceListValidationMissingProductComponentOutput[]) || [],
              facilitiesWithoutPrice: (p.validation.facilitiesWithoutPrice as FacilityListOutput[]) || [],
              facilitiesWithoutPriceComponents:
                (p.validation.facilitiesWithoutPriceComponents as PriceListValidationMissingFacilityComponentOutput[]) || [],
              bundlesWithoutPrice: (p.validation.bundlesWithoutPrice as ProductBundleListOutput[]) || [],
              bundlesWithoutPriceComponents: (p.validation.bundlesWithoutPriceComponents as PriceListValidationMissingBundleComponentOutput[]) || [],
            },
          })) || [],
      });
      dispatchMessage(dispatch, i18next.t('prices-import-ready'));
    } catch (err) {
      dispatchException(dispatch, err);
      const errs = extractClientErrors(err);
      setImportResult({
        importMsg: errs.join('\r\n'),
        results: [],
      });
    }
  };

  if (importResult.importMsg) {
    return (
      <>
        <Helmet>
          <title>{i18next.t('prices-import-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{i18next.t('prices-import-result-header')}</Typography>
          </Grid>
          <Grid item xs={12}>
            {importResult.importMsg}
          </Grid>
          <Grid item xs={12}>
            <SimpleTable
              headers={[
                i18next.t('prices-import-result-pricelist'),
                i18next.t('prices-import-result-version'),
                ,
                i18next.t('prices-import-result-complete'),
                '',
              ]}
              rows={importResult.results.map(row => [
                <Link to={`/pricelists/pricelists/${row.priceList.id}`}>{row.priceList.name}</Link>,
                row.priceList.version,
                row.validation.isComplete && <CheckIcon />,
                <Grid container>
                  {row.validation.productsWithoutPrice.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-productnoprice')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.productsWithoutPrice.map((p, index) => (
                          <Link
                            key={index}
                            to={`/pricelists/pricelists/${row.priceList.id}/selectors/${row.priceList.basePriceSelector!.id}#${p.sku}`}
                          >
                            {p.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                  {row.validation.productsWithoutPriceComponents.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-productnocomponents')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.productsWithoutPriceComponents.map((p, index) => (
                          <Link key={index} to={`/pricelists/pricelists/${row.priceList.id}/selectors/${p.selector.id}#${p.product.sku}`}>
                            {p.product.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                  {row.validation.bundlesWithoutPrice.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-bundlesnoprice')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.bundlesWithoutPrice.map((p, index) => (
                          <Link
                            key={index}
                            to={`/pricelists/pricelists/${row.priceList.id}/selectors/${row.priceList.basePriceSelector!.id}#${p.sku}`}
                          >
                            {p.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                  {row.validation.bundlesWithoutPriceComponents.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-bundlesnocomponents')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.bundlesWithoutPriceComponents.map((p, index) => (
                          <Link key={index} to={`/pricelists/pricelists/${row.priceList.id}/selectors/${p.selector.id}#${p.bundle.sku}`}>
                            {p.bundle.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                  {row.validation.facilitiesWithoutPrice.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-facilitiesnoprice')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.facilitiesWithoutPrice.map((p, index) => (
                          <Link
                            key={index}
                            to={`/pricelists/pricelists/${row.priceList.id}/selectors/${row.priceList.basePriceSelector!.id}#${p.sku}`}
                          >
                            {p.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                  {row.validation.facilitiesWithoutPriceComponents.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        {i18next.t('prices-import-result-facilitiesnocomponents')}
                      </Grid>
                      <Grid item xs={12}>
                        {row.validation.facilitiesWithoutPriceComponents.map((p, index) => (
                          <Link key={index} to={`/pricelists/pricelists/${row.priceList.id}/selectors/${p.selector.id}#${p.facility.sku}`}>
                            {p.facility.name}
                          </Link>
                        ))}
                      </Grid>
                    </>
                  )}
                </Grid>,
              ])}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<ImportExportIcon />}
              onClick={async () => {
                setImportResult({
                  importMsg: undefined,
                  results: [],
                });
              }}
            >
              {i18next.t('prices-import-start')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{i18next.t('prices-import-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormInputText name="name" control={control} label={i18next.t('prices-import-name')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputText name="version" control={control} label={i18next.t('prices-import-version')} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SpaceSelectionInput checkAdmin name="spaceId" control={control} disabled={user.isSingleAdminSpace} required />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={9}>
            <FormInputFileUpload
              control={control}
              label={i18next.t('prices-import-file')}
              name="file"
              accept=".json,.xlsx"
              binary={name => (name.endsWith('xlsx') ? true : false)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <ImportExportIcon />}
              disabled={!isDirty || isSubmitting || isValidating || !((filter && filter.spaceId) || user.space?.id)}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('prices-import-run')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default function Import() {
  return <ImportForm />;
}
