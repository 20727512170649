import moment from 'moment';

export type GetTaxRateInput = {
  taxTypeId: number;
  country: string;
  date: Date;
};

export interface ITaxRate {
  id: number;
  taxTypeId: number;
  country: string | null;
  validFrom: Date | null;
  validTo: Date | null;
  rate: number;
}
export interface ITaxType {
  id: number;
  name: string;
  sequence: number;
}

export class TaxRateSelector<TaxRateType extends ITaxRate, TaxTypeType extends ITaxType> {
  public taxRates: TaxRateType[] = [];
  public taxTypes: TaxTypeType[] = [];

  public hydrate(json: string) {
    const { taxRates, taxTypes } = JSON.parse(json);
    this.taxRates = taxRates;
    this.taxTypes = taxTypes;
  }
  public dehydrate(): string {
    return JSON.stringify({
      taxRates: this.taxRates,
      taxTypes: this.taxTypes,
    });
  }

  public getTaxType(taxTypeId: number): TaxTypeType | null {
    return this.taxTypes.find(t => t.id == taxTypeId) || null;
  }

  public getTaxRate(input: GetTaxRateInput): TaxRateType | null {
    const dateMom = moment(input.date);

    const _filterCountry = (t: ITaxRate) => (t.country ? t.country === input.country : true);
    const _filterDate = (t: ITaxRate) =>
      (t.validFrom ? moment(t.validFrom).startOf('day').isSameOrBefore(dateMom) : true) && (t.validTo ? moment(t.validTo).endOf('day').isSameOrAfter(dateMom) : true);

    const rates = this.taxRates?.filter(r => r.taxTypeId === input.taxTypeId && _filterCountry(r) && _filterDate(r));
    if (rates.length === 0) {
      return null;
    } else if (rates.length === 1) {
      return rates[0];
    } else {
      const [maxFilterCount, maxIndex] = rates.reduce(
        (agg, r, i) => {
          const filterCount = (r.country ? 1 : 0) + (r.validFrom ? 1 : 0) + (r.validTo ? 1 : 0);
          if (agg[0] < filterCount) return [filterCount, i];
          else return agg;
        },
        [Number.MIN_VALUE, -1],
      );
      return rates[maxIndex];
    }
  }
}
