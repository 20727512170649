import _ from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { enqueueSnackbar as enqueueSnackbarAction, EnqueueSnackbarArgs } from 'store/snackbarSlice';
import { extractClientErrors } from './apollo';

export function dispatchMessage(dispatch: Dispatch<AnyAction>, message: string) {
  dispatch(enqueueSnackbarAction({ message }));
}

export function dispatchException(dispatch: Dispatch<AnyAction>, err: any) {
  const errs = _.isString(err) ? [err] : extractClientErrors(err);
  for (const e of errs) {
    dispatch(
      enqueueSnackbarAction({
        message: `${e}`,
        options: {
          variant: 'error',
        },
      }),
    );
  }
}
