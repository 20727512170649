import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, CircularProgress, Button, ButtonGroup, Alert } from '@mui/material';
import _, { get } from 'lodash';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import SimpleTable from 'components/table/SimpleTable';
import { FormatDateTime } from 'components/DateTime';
import FileUploadButton from 'components/FileUploadButton';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';
import { allLanguagesByCode } from 'languages';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { EMAILTEMPLATES_LIST_QUERY, EXPORT_EMAILTEMPLATE_MUTATION, IMPORT_EMAILTEMPLATE_MUTATION, REFETCH_EMAILTEMPLATES_QUERIES } from '../gql';
import { EContentEmailTemplate } from '__generated__/graphql';

import { EmailTemplateCopyButton, EmailTemplateDeleteButton } from './emailtemplate';
import { ErrorAlert } from 'pages/error';
import { isProductionRelease } from 'helper/deployment';

export default function EmailTemplatesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(EMAILTEMPLATES_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });
  const [importMutationFunction, { loading: importLoading }] = useMutation(IMPORT_EMAILTEMPLATE_MUTATION);
  const [exportMutateFunction, { loading: exportLoading }] = useMutation(EXPORT_EMAILTEMPLATE_MUTATION);

  const getReceiver = (templateUsage: EContentEmailTemplate) => {
    const templateUsageString = templateUsage.toString();
    if (templateUsageString.includes('HOTEL')) {
      return i18next.t('emailtemplates-list-receiver-hotel');
    } else if (templateUsageString.includes('CLIENT')) {
      return i18next.t('emailtemplates-list-receiver-client');
    } else if (templateUsageString.includes('SEMINARGO')) {
      return i18next.t('emailtemplates-list-receiver-seminargo');
    } else if (templateUsageString.includes('USER')) {
      return i18next.t('emailtemplates-list-receiver-user');
    } else if (templateUsageString === 'RESET_PASSWORD') {
      return i18next.t('emailtemplates-list-receiver-user');
    }
    return null;
  };

  const getMissingTemplates = () => {
    const allTemplates = Object.keys(EContentEmailTemplate).filter(t => isProductionRelease() && t.includes('LISTER') ? false : true);
    return allTemplates.filter(t => !data?.listEmailTemplates.some(et => et.usage === t));
  }

  const importToSpaceId = (filter && filter.spaceId) || user.space?.id;

  return (
    <>
      <Helmet>
        <title>{i18next.t('emailtemplates-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            {getMissingTemplates().length > 0 &&
              <Grid item xs={12}>
                <Alert severity='warning'>Achtung: Es gibt neue E-mail Vorlagen. Wenn du eine gewisse Vorlage inhaltlich ändern möchtest, aber nicht findest, dann musst du diese neu erstellen. <br /><br />
                Folgende Vorlagen werden als Standardvorlagen vom System geholt, anstatt von der unten angeführten Liste: {getMissingTemplates().map(t => <li key={t}>{i18next.t('enums-EContentEmailTemplate-' + t)}</li>)}
                </Alert>
              </Grid>
            }
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('emailtemplates-list-header-usage'),
                  i18next.t('emailtemplates-list-header-receiver'),
                  i18next.t('emailtemplates-list-header-language'),
                  i18next.t('emailtemplates-list-header-subject'),
                  ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                  i18next.t('emailtemplates-list-header-updatedat'),
                  '',
                ]}
                rows={_.sortBy(data.listEmailTemplates, [l => i18next.t('enums-EContentEmailTemplate-' + l.usage), 'language']).filter(t => isProductionRelease() && t.usage.includes('LISTER') ? false : true).map(row => [
                  <Link to={`/content/templates/emailtemplates/${row.id}`}>{i18next.t('enums-EContentEmailTemplate-' + row.usage)}</Link>,
                  getReceiver(row.usage),
                  row.language && (allLanguagesByCode[row.language] || { label: null }).label,
                  row.subject,
                  ...(user.isSingleSpace ? [] : [row.space.name]),
                  <FormatDateTime date={row.updatedAt} />,
                  ,
                  <ButtonGroup>
                    <EmailTemplateCopyButton id={row.id} spaceId={row.space.id} icon />
                    <EmailTemplateDeleteButton id={row.id} spaceId={row.space.id} icon />
                  </ButtonGroup>,
                ])}
              />
            </Grid>
            <Grid item xs={12}>
              {hasAnyAdminSpace(user) && (
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/content/templates/emailtemplates/_create')}
                >
                  {i18next.t('emailtemplates-list-add')}
                </Button>
              )}
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                color="secondary"
                startIcon={exportLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
                disabled={exportLoading}
                onClick={async () => {
                  try {
                    const res = await exportMutateFunction({
                      variables: {
                        spaceId: (filter && filter.spaceId) || null,
                      },
                    });
                    if (res.data?.exportEmailTemplates) {
                      exportToFile(res.data?.exportEmailTemplates, 'application/json', 'seminargo-emailtemplates.json');
                    }
                  } catch (err) {
                    dispatchException(dispatch, err);
                  }
                }}
              >
                {i18next.t('emailtemplates-export-run')}
              </Button>
              {user.isAdmin && importToSpaceId && (
                <FileUploadButton
                  name="importEmailTemplate"
                  accept="application/json"
                  disabled={importLoading}
                  binary={() => false}
                  startIcon={importLoading ? <CircularProgress size={24} /> : <UploadIcon />}
                  onChange={async (name, type, content) => {
                    try {
                      const res = await importMutationFunction({
                        variables: {
                          spaceId: importToSpaceId,
                          jsonText: content,
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(),
                      });
                      dispatchMessage(dispatch, i18next.t('emailtemplates-import-ready'));
                    } catch (err) {
                      dispatchException(dispatch, err);
                    }
                  }}
                >
                  {i18next.t('emailtemplates-import-run')}
                </FileUploadButton>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
