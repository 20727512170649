import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';

import Secret, { SecretCreate } from './secret';
import SecretsList from './secrets';

import HotelConnect from './hotelconnect';
import HotelEdit from './hotel';
import HotelsList from './hotels';

export default function IntegrationsIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs
            value={
              location.pathname === '/settings/integration' ? '/settings/integration/secrets' : location.pathname.split('/').slice(0, 4).join('/')
            }
          >
            <Tab
              value="/settings/integration/secrets"
              label={i18next.t('integration-tabs-header-secrets')}
              component={Link}
              to="/settings/integration/secrets"
            />
            <Tab
              value="/settings/integration/hotels"
              label={i18next.t('integration-tabs-header-hotels')}
              component={Link}
              to="/settings/integration/hotels"
            />
          </Tabs>
          <Box p={2}>
            <Routes>
              <Route index element={<SecretsList />} />
              <Route path="secrets/_create" Component={() => <SecretCreate />} />
              <Route path="secrets/:id" Component={() => <Secret id={parseInt(useParams().id!)} />} />
              <Route path="secrets" Component={() => <SecretsList />} />
              <Route path="hotels/_create" Component={() => <HotelConnect />} />
              <Route path="hotels/:id" Component={() => <HotelEdit id={parseInt(useParams().id!)} />} />
              <Route path="hotels" Component={() => <HotelsList />} />
            </Routes>
            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
