import i18next from 'i18next';
import * as yup from 'yup';
import moment from 'moment';

import { languages } from './languages';
i18next.init(languages);

import yupLocaleDe from './yupLocaleDe';
yup.setLocale(yupLocaleDe);

import 'moment/locale/de';
moment.locale('de');

export default yup;
