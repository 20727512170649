import React, { ReactNode } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

export interface FormLabelProps {
  name: string;
  control: any;
  register?: RegisterOptions | null;
  render?: (value: any) => ReactNode | null | undefined;
}

export const FormLabel = ({ register, name, control, render }: FormLabelProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <>
          {render && render(value)}
          {!render && <>{`${value}`}</>}
        </>
      )}
      {...register}
    />
  );
};
