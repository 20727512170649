import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, IconButton, Box } from '@mui/material';
import { Grid, Paper, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ErrorAlert } from 'pages/error';

interface PagedTableCell {
  span?: number | null;
  cell: React.ReactNode | null;
}

interface PagedTableProps {
  filter?: React.ReactNode | null;
  filterKey?: React.Key | null;
  headers: React.ReactNode[];
  rows?: (React.ReactNode | PagedTableCell | null)[][] | null;
  rowsLoading?: boolean;
  rowsErr?: any | null;
  refetchPage: (skip: number, take: number) => void;
  showRefetch?: boolean;
  hidden?: (boolean | null)[] | null;
  tableProps?: {
    [key: string]: any;
  };
}

export default function PagedTable(props: PagedTableProps) {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  useEffect(() => {
    props.refetchPage(page * take, take + 1);
  }, [page, take]);
  useEffect(() => {
    if (props.filterKey) props.refetchPage(page * take, take + 1);
  }, [props.filterKey]);

  let rows: (React.ReactNode | PagedTableCell | null)[][];
  let count: number = -1;

  if (props.rowsLoading) {
    rows = [...[...Array(take).keys()].map(i => [{ span: props.headers.length, cell: <CircularProgress size={24} /> }])];
  } else if (props.rowsErr) {
    rows = [[{ span: props.headers.length, cell: <ErrorAlert err={props.rowsErr}/> }]];
  } else if (props.rows) {
    rows = props.rows.slice(0, take);
    if (props.rows.length <= take) {
      count = page * take + props.rows.length;
    }
  } else {
    rows = [];
  }

  const _pager = () => (
    <>
      {!props.rowsLoading && !props.rowsErr && props.rows && props.rows.length > 0 && (page !== 0 || count < 0) && (
        <TablePagination
          component="div"
          count={count}
          rowsPerPageOptions={[10, 20, 50]}
          rowsPerPage={take}
          page={page}
          onRowsPerPageChange={event => {
            setTake(parseInt(event.target.value));
            setPage(0);
          }}
          onPageChange={(event, p) => {
            setPage(p);
          }}
          showFirstButton
          nextIconButtonProps={{
            disabled: !props.rows || props.rows.length <= take,
          }}
        />
      )}
      {props.showRefetch && (
        <IconButton onClick={() => props.refetchPage(page * take, take + 1)}>
          <RefreshIcon />
        </IconButton>
      )}
    </>
  );

  return (
    <Grid container>
      {props.filter && (
        <>
          <Grid item xs={12} sm={6}>
            {props.filter}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-end">
              {_pager()}
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table {...props.tableProps}>
            {props.headers.length > 0 && (
              <TableHead>
                <TableRow>
                  {props.headers
                    .filter((h, index) => (props.hidden ? !props.hidden[index] : true))
                    .map((h, i) => (
                      <TableCell key={i}>{h}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {row
                    .filter((r, index) => (props.hidden ? !props.hidden[index] : true))
                    .map((cell, index) => (
                      <TableCell key={index} {...(cell && (cell as any)['span'] ? { colSpan: (cell as any).span } : {})}>
                        {(cell && (cell as any).cell) || cell}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <Box display="flex" justifyContent="flex-end">
          {_pager()}
        </Box>
      </Grid>
    </Grid>
  );
}
