import _ from 'lodash';
import { Country, countries, getEmojiFlag } from 'countries-list';

const regionNamesInGerman = new Intl.DisplayNames(['de'], { type: 'region' });
const languageNamesInGerman = new Intl.DisplayNames(['de'], {
  type: 'language',
});

export const allLanguages = [
  {
    code: 'DE',
    name: languageNamesInGerman.of('de'),
    label: `${getEmojiFlag('DE')} ${languageNamesInGerman.of('de')}`,
  },
  {
    code: 'EN',
    name: languageNamesInGerman.of('en'),
    label: `${getEmojiFlag('GB')} ${languageNamesInGerman.of('en')}`,
  },
  {
    code: 'FR',
    name: languageNamesInGerman.of('fr'),
    label: `${getEmojiFlag('FR')} ${languageNamesInGerman.of('fr')}`,
  },
];
export const allLanguagesByCode: {
  [code: string]: (typeof allLanguages)[0];
} = allLanguages.reduce((agg, c) => ({ ...agg, [c.code]: c }), {});

export const allCountries = Object.keys(countries).map(code => {
  const country: Country = (countries as any)[code];

  return {
    code,
    name: regionNamesInGerman.of(code),
    native: country.native,
    label: `${getEmojiFlag(code)} ${regionNamesInGerman.of(code)}`,
    emoji: getEmojiFlag(code),
  };
});

export const allCountriesByCode: {
  [code: string]: (typeof allCountries)[0];
} = allCountries.reduce((agg, c) => ({ ...agg, [c.code]: c }), {});

export const allCountriesSorted = [
  allCountries.find(c => c.code === 'AT')!,
  allCountries.find(c => c.code === 'DE')!,
  allCountries.find(c => c.code === 'CH')!,
  null,
  ..._.sortBy(
    allCountries.filter(c => c.code !== 'AT' && c.code !== 'DE' && c.code !== 'CH'),
    c => c.name,
  ),
];

function _getCurrencyName(locale: string, currencyCode: string) {
  const formattedCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'name',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0);
  const currencyName = formattedCurrency.replace(/[0,.]/g, '').trim();
  return currencyName || '?';
}

export const allCurrencies = [
  {
    code: 'EUR',
    name: _getCurrencyName('de', 'EUR'),
    label: `🇪🇺 ${_getCurrencyName('de', 'EUR')}`,
    symbol: '€',
  },
  {
    code: 'USD',
    name: _getCurrencyName('de', 'USD'),
    label: `${getEmojiFlag('US')} ${_getCurrencyName('de', 'USD')}`,
    symbol: '$',
  },
  {
    code: 'CHF',
    name: _getCurrencyName('de', 'CHF'),
    label: `${getEmojiFlag('CH')} ${_getCurrencyName('de', 'CHF')}`,
    symbol: 'CHF',
  },
  {
    code: 'ITL',
    name: _getCurrencyName('de', 'ITL'),
    label: `${getEmojiFlag('IT')} ${_getCurrencyName('de', 'ITL')}`,
    symbol: '₤',
  },
  {
    code: 'FRF',
    name: _getCurrencyName('de', 'FRF'),
    label: `${getEmojiFlag('FR')} ${_getCurrencyName('de', 'FRF')}`,
    symbol: '₣',
  },
  {
    code: 'GBP',
    name: _getCurrencyName('de', 'GBP'),
    label: `${getEmojiFlag('GB')} ${_getCurrencyName('de', 'GBP')}`,
    symbol: '£',
  },
];
export const allCurrenciesByCode: {
  [code: string]: (typeof allCurrencies)[0];
} = allCurrencies.reduce((agg, c) => ({ ...agg, [c.code]: c }), {});

export const languages = {
  lng: 'de',
  debug: true,
  resources: {
    de: {
      translation: {
        // Sidebar
        'sidebar-wloffers': 'Buchungen',
        'sidebar-catalog': 'Leistungskonfiguration',
        'sidebar-products': 'Leistungen',
        'sidebar-product-bundles': 'Leistungspakete',
        'sidebar-pricelists': 'Preislisten',
        'sidebar-availability': 'Verfügbarkeit',
        'sidebar-content': 'Inhaltsverwaltung',
        'sidebar-security': 'Benutzer / Rechte',
        'sidebar-text': 'Textbausteine',
        'sidebar-media': 'Medien',
        'sidebar-settings': 'Einstellungen',
        'sidebar-templates': 'Vorlagen',
        'sidebar-hotels': 'Hotels',
        'sidebar-integration': 'Schnittstellen',
        'sidebar-space': 'Corporate Identity',
        'sidebar-help': 'Hilfe',

        'confirmation-dialog-decline': 'Abbrechen',
        'confirmation-dialog-confirm': 'Fortsetzen',
        'information-dialog-confirm': 'Fenster schließen',

        // Enums
        'enums-EProductPayment-BYCOUNT': 'Nach Anzahl und Tag',
        'enums-EProductPayment-BYGUEST': 'Pro Gast (einmalig)',
        'enums-EProductPayment-BYGUESTANDDAY': 'Pro Gast und Tag',
        'enums-EProductPayment-BYROOM': 'Pro Zimmer (einmalig)',
        'enums-EProductPayment-BYROOMANDDAY': 'Pro Zimmer und Tag',
        'enums-EFacilityPayment-BYCOUNT': 'Nach Anzahl',
        'enums-EFacilityPayment-BYDAY': 'Pro Tag',
        'enums-EFacilityPayment-BYGUEST': 'Pro Gast (einmalig)',

        'enums-EOfferRoomOccupancy-FULLDAY': 'Ganztägig',
        'enums-EOfferRoomOccupancy-MORNING': 'Vormittag',
        'enums-EOfferRoomOccupancy-AFTERNOON': 'Nachmittag',

        'enums-EOfferRoomSeating-UFORM': 'U-Form',
        'enums-EOfferRoomSeating-THEATER': 'Theater',
        'enums-EOfferRoomSeating-PARLAMENT': 'Parlament',
        'enums-EOfferRoomSeating-CIRCLE': 'Kreis',
        'enums-EOfferRoomSeating-BANKETT': 'Bankett',
        'enums-EOfferRoomSeating-COCKTAIL': 'Cocktail',
        'enums-EOfferRoomSeating-BLOCK': 'Block',
        'enums-EOfferRoomSeating-EMPTY': 'keine Bestuhlung',

        'enums-EPricingWeekDays-MON': 'Montag',
        'enums-EPricingWeekDays-TUE': 'Dienstag',
        'enums-EPricingWeekDays-WED': 'Mittwoch',
        'enums-EPricingWeekDays-THU': 'Donnerstag',
        'enums-EPricingWeekDays-FRI': 'Freitag',
        'enums-EPricingWeekDays-SAT': 'Samstag',
        'enums-EPricingWeekDays-SUN': 'Sonntag',

        'enums-EBundleMode-NONE': 'Kein Preis',
        'enums-EBundleMode-PRODUCTS': 'Summe Einzelleistungen',
        'enums-EBundleMode-PRICE': 'Pauschalpreis',

        'enums-EProductImportMergeMode-CLEANUP': 'Leistungskatalog löschen und neu importieren (VORSICHT!!!)',
        'enums-EProductImportMergeMode-IGNORE': 'Nur neue Leistungen importieren',
        'enums-EProductImportMergeMode-UPDATE': 'Gleichnamige Leistungen überschreiben',

        'enums-EContentProductDetailPosition-HEADER': 'Bezeichnung',
        'enums-EContentProductDetailPosition-HEADER-tooltip': 'Wird als Überschrift der Position in den Angebotszeilen verwendet',
        'enums-EContentProductDetailPosition-HEADER_SHORT': 'Bezeichnung (Kurzform)',
        'enums-EContentProductDetailPosition-HEADER_SHORT-tooltip':
          'Wird als Bezeichnung im SEM Widget verwendet (falls unterschiedlich zur Bezeichnung in den Angebotszeilen)',
        'enums-EContentProductDetailPosition-DETAILS': 'Beschreibungstext',
        'enums-EContentProductDetailPosition-DETAILS-tooltip': 'Verwendung als Beschreibungstext der Angebotszeilen',
        'enums-EContentProductDetailPosition-GROUP': 'Gruppierung (nur für White Label)',
        'enums-EContentProductDetailPosition-GROUP-tooltip': 'Gruppierung (nur für White Label)',

        'enums-EContentEmailTemplate-USER_PASSWORD': 'Passwort-Änderung',
        'enums-EContentEmailTemplate-USER_WELCOME': 'Willkommens-E-Mail an neue Benutzer',
        'enums-EContentEmailTemplate-RESET_PASSWORD': 'Passwort Vergessen',
        'enums-EContentEmailTemplate-CLIENT_VERIFICATION': 'Verifizierung Kunden-E-Mail-Adresse (an Kunde)',
        'enums-EContentEmailTemplate-OFFER_REMINDER_HOTEL': 'Erinnerung Reservierung (an Hotel)',
        'enums-EContentEmailTemplate-OFFER_REMINDER_CLIENT': 'Erinnerung Reservierung (an Kunde)',
        'enums-EContentEmailTemplate-OFFER_RECEIVED_CLIENT': 'Reservierungsbestätigung (an Kunde)',
        'enums-EContentEmailTemplate-OFFER_CHANGED_RECEIVED_CLIENT': 'Reservierungsbestätigung geändertes Angebot (an Kunde)',
        'enums-EContentEmailTemplate-BOOKING_RECEIVED_HOTEL': 'Neue Seminaranfrage (an Hotel)',
        'enums-EContentEmailTemplate-BOOKING_RECEIVED_CLIENT': 'Anfrage ist in Bearbeitung',
        'enums-EContentEmailTemplate-BOOKING_REJECTED_OVERFLOW_CLIENT': 'Ablehnung der Verfügbarkeit',
        'enums-EContentEmailTemplate-BOOKING_APPROVED_CLIENT': 'Buchung von Kunde bestätigt',
        'enums-EContentEmailTemplate-BOOKING_REJECTED_CLIENT': 'Buchung von Kunde abgelehnt',
        'enums-EContentEmailTemplate-BOOKING_APPROVED_HOTEL': 'Buchung von Kunde bestätigt',
        'enums-EContentEmailTemplate-BOOKING_REJECTED_HOTEL': 'Buchung von Kunde abgelehnt',
        'enums-EContentEmailTemplate-BOOKING_OVERFLOW_APPROVED_CLIENT': 'Übergabe an seminargo bestätigt',
        'enums-EContentEmailTemplate-BOOKING_OVERFLOW_APPROVED_HOTEL': 'Übergabe an seminargo bestätigt',
        'enums-EContentEmailTemplate-BOOKING_OVERFLOW_REJECTED_CLIENT': 'Übergabe an seminargo abgelehnt',
        'enums-EContentEmailTemplate-BOOKING_OVERFLOW_APPROVED_SEMINARGO': 'Übergabe an seminargo',
        'enums-EContentEmailTemplate-DEPOSIT_REMINDER_HOTEL': 'Erinnerung Deposit-Rechnung',
        'enums-EContentEmailTemplate-DEPOSIT_REMINDER_CHECK_HOTEL': 'Erinnerung Deposit-Eingang',
        'enums-EContentEmailTemplate-LISTER_BOOKING_DIRECT_CLIENT': 'Bestätigung Direktbuchung über Hotellister (an Kunde)',
        'enums-EContentEmailTemplate-LISTER_BOOKING_DIRECT_HOTEL': 'Bestätigung Direktbuchung über Hotellister (an Hotel)',
        'enums-EContentEmailTemplate-LISTER_CLIENT_VERIFICATION': 'Kundenverifzierung über Hotellister (an Kunde)',
        'enums-EContentEmailTemplate-BOOKING_DIRECT_CLIENT': 'Bestätigung Direktbuchung über Whitelabel (an Kunde)',
        'enums-EContentEmailTemplate-BOOKING_DIRECT_HOTEL': 'Bestätigung Direktbuchung über Whitelabel (an Hotel)',
        'enums-EContentEmailTemplate-BOOKING_CHANGED_CLIENT': 'Bestätigung geänderte Reservierung (an Kunde)',

        'enums-EContentOfferTemplateType-OFFER': 'Angebotsvorlage',
        'enums-EContentOfferTemplateType-RESERVATION_CONFIRMATION': 'Reservierungsbestätigung',

        'enums-EOfferStatus-CLIENTVERIFICATION': 'Warten auf E-Mail-Verifizierung',
        'enums-EOfferStatus-PENDING': 'In Bearbeitung',
        'enums-EOfferStatus-APPROVED': 'Bestätigt',
        'enums-EOfferStatus-REJECTED': 'Abgelehnt',
        'enums-EOfferStatus-DELETED': 'Gelöscht',

        'enums-EOfferVersionStatus-CLIENTVERIFICATION': 'Warten auf E-Mail-Verifizierung',
        'enums-EOfferVersionStatus-EDITING': 'In Bearbeitung',
        'enums-EOfferVersionStatus-WAITFORHOTELAPPROVAL': 'Hotel-Bestätigung ausstehend',
        'enums-EOfferVersionStatus-WAITFORCLIENTAPPROVAL': 'Kunden-Bestätigung ausstehend',
        'enums-EOfferVersionStatus-APPROVED': 'Bestätigt',
        'enums-EOfferVersionStatus-APPROVED_EDITING': 'In Bearbeitung (nach Bestätigung)',
        'enums-EOfferVersionStatus-REJECTED_CLIENT': 'Abgelehnt durch Kunde',
        'enums-EOfferVersionStatus-REJECTED_HOTEL': 'Abgelehnt durch Hotel',
        'enums-EOfferVersionStatus-DELETED': 'Gelöscht',

        'enums-EOfferVersionHistoryStatus-CLIENTVERIFICATION_OPEN': 'Warten auf E-Mail-Verifizierung',
        'enums-EOfferVersionHistoryStatus-CLIENTVERIFICATION_DONE': 'E-Mail-Verifizierung abgeschlossen',
        'enums-EOfferVersionHistoryStatus-CLIENT_REMINDER_SENT': 'Erinnerung an Kunde gesendet',
        'enums-EOfferVersionHistoryStatus-HOTEL_REMINDER_SENT': 'Erinnerung an Hotel gesendet',
        'enums-EOfferVersionHistoryStatus-WAITFORCLIENTAPPROVAL': 'Kunden-Bestätigung ausstehend',
        'enums-EOfferVersionHistoryStatus-APPROVED_CLIENT': 'Kunde hat bestätigt',
        'enums-EOfferVersionHistoryStatus-APPROVED_HOTEL': 'Hotel hat bestätigt',
        'enums-EOfferVersionHistoryStatus-REJECTED_CLIENT': 'Kunde hat abgelehnt',
        'enums-EOfferVersionHistoryStatus-REJECTED_HOTEL': 'Hotel hat abgelehnt',
        'enums-EOfferVersionHistoryStatus-DELETED': 'Gelöscht',
        'enums-EOfferVersionHistoryStatus-EDITING': 'Angebot unter Bearbeitung',
        'enums-EOfferVersionHistoryStatus-APPROVED_EDITING': 'Reservierungsbestätigung unter Bearbeitung (nach Bestätigung)',
        'enums-EOfferVersionHistoryStatus-WAITFORHOTELAPPROVAL': 'Hotel-Bestätigung ausstehend',
        'enums-EOfferVersionHistoryStatus-OVERFLOW_SENT': 'Overflow-Benachrichtung versendet',
        'enums-EOfferVersionHistoryStatus-OVERFLOW_APPROVED': 'Kunde hat Overflow bestätigt',
        'enums-EOfferVersionHistoryStatus-OVERFLOW_REJECTED': 'Kunde hat Overflow abgelehnt',
        'enums-EOfferVersionHistoryStatus-DEPOSIT_REMINDER_SENT': 'Erinnerung Deposit-Rechnung an Hotel gesendet',
        'enums-EOfferVersionHistoryStatus-DEPOSIT_CHECK_REMINDER_SENT': 'Erinnerung Deposit-Eingang an Hotel gesendet',
        'enums-EOfferVersionHistoryStatus-INTEGRATION_BOOKING_CREATED': 'PMS-Buchung angelegt',
        'enums-EOfferVersionHistoryStatus-INTEGRATION_BOOKING_CONFIRMED': 'PMS-Buchung bestätigt',
        'enums-EOfferVersionHistoryStatus-INTEGRATION_BOOKING_CANCELED': 'PMS-Buchung storniert',
        'enums-EOfferVersionHistoryStatus-INTEGRATION_BOOKING_DELETED': 'PMS-Buchung gelöscht',
        'enums-EOfferVersionHistoryStatus-PROCESS_FAILURE': 'Prozessfehler',

        'enums-EOfferVersionContentBlockFormFieldType-TEXT': 'Einzeiliger Text',
        'enums-EOfferVersionContentBlockFormFieldType-TEXTAREA_PLAINTEXT': 'Mehrzeiliger Text',
        'enums-EOfferVersionContentBlockFormFieldType-TEXTAREA_RICHTEXT': 'Formatierter Text',

        'enums-EIntSystem-DUMMY': 'Testdaten (Musterhotel)',
        'enums-EIntSystem-APALEO': 'Apaleo',
        'enums-EIntSystem-ELITEFO': 'Elite Hotelsoftware',
        'enums-EIntSystem-PROTELIDS': 'Protel IDS',

        'enums-EIntBookingMode-SEM': 'Keine Direktbuchung (Bearbeitung in SEM)',
        'enums-EIntBookingMode-DIRECT_OFFER': 'Direktbuchung als Reservierung (Bearbeitung im PMS)',
        'enums-EIntBookingMode-DIRECT_CONFIRM': 'Direktbuchung mit automatischer Bestätigung',

        'enums-EIntegrationLogStatus-PENDING': 'Offen',
        'enums-EIntegrationLogStatus-RUNNING': 'Läuft',
        'enums-EIntegrationLogStatus-SUCCESS': 'Erfolgreich',
        'enums-EIntegrationLogStatus-FAILED': 'Fehler',
        'enums-EIntegrationLogStatus-SKIPPED': 'Übersprungen',

        'enums-EIntegrationLogTrigger-INIT': 'Erstabgleich',
        'enums-EIntegrationLogTrigger-SCHEDULED': 'Zeitgesteuert',
        'enums-EIntegrationLogTrigger-USER': 'Benutzer',
        'enums-EIntegrationLogTrigger-PMS': 'PMS',
        'enums-EIntegrationLogTrigger-PROCESS': 'Automatisiert',

        'enums-EContentHotelAttribute-CATEGORY': 'Kategorie',
        'enums-EContentHotelAttribute-CATEGORY_ONE_STARS': '*',
        'enums-EContentHotelAttribute-CATEGORY_TWO_STARS': '**',
        'enums-EContentHotelAttribute-CATEGORY_THREE_STARS': '***',
        'enums-EContentHotelAttribute-CATEGORY_FOUR_STARS': '****',
        'enums-EContentHotelAttribute-CATEGORY_FIVE_STARS': '*****',
        'enums-EContentHotelAttribute-LOCATION': 'Lage',
        'enums-EContentHotelAttribute-LOCATION_CITY': 'Stadt',
        'enums-EContentHotelAttribute-LOCATION_COUNTRY': 'Im Grünen',
        'enums-EContentHotelAttribute-ROOM': 'Zimmer',
        'enums-EContentHotelAttribute-ROOM_AIRCONDITIONER': 'Klimaanlage',
        'enums-EContentHotelAttribute-ROOM_BALCONY': 'Balkon',
        'enums-EContentHotelAttribute-ROOM_BARRIER_FREE': 'Barrierefrei',
        'enums-EContentHotelAttribute-ROOM_HAIRDRYER': 'Föhn',
        'enums-EContentHotelAttribute-ROOM_MINIBAR': 'Minibar',
        'enums-EContentHotelAttribute-ROOM_SAFE': 'Safe',
        'enums-EContentHotelAttribute-ROOM_TV': 'TV',
        'enums-EContentHotelAttribute-ROOM_INTERNET_WIFI': 'WLAN',
        'enums-EContentHotelAttribute-ROOM_INTERNET_FIBER': 'Glasfaser',
        'enums-EContentHotelAttribute-ROOM_SMOKING': 'Raucherzimmer',
        'enums-EContentHotelAttribute-ROOM_WORKTABLE': 'Arbeitstisch',
        'enums-EContentHotelAttribute-ROOM_TRAINERROOM': 'Trainerzimmer',
        'enums-EContentHotelAttribute-SEMINAR': 'Seminarraum',
        'enums-EContentHotelAttribute-SEMINAR_ACCESSABLE_BY_CAR': 'Zufahrt mit PKW',
        'enums-EContentHotelAttribute-SEMINAR_AIRCONDITIONER': 'Klimaanlage',
        'enums-EContentHotelAttribute-SEMINAR_DAYLIGHT': 'Tageslicht',
        'enums-EContentHotelAttribute-SEMINAR_INTERNET_WIFI': 'WLAN',
        'enums-EContentHotelAttribute-SEMINAR_INTERNET_FIBER': 'Glasfaser',
        'enums-EContentHotelAttribute-SEMINAR_BREAK_AREA': 'Pausenbereich',
        'enums-EContentHotelAttribute-SEMINAR_GROUPWORK': 'Gruppenarbeit',
        'enums-EContentHotelAttribute-DESIGN': 'Design',
        'enums-EContentHotelAttribute-DESIGN_MODERN': 'Modern',
        'enums-EContentHotelAttribute-DESIGN_BUSINESS': 'Business',
        'enums-EContentHotelAttribute-DESIGN_TRADITIONAL': 'Traditionell',
        'enums-EContentHotelAttribute-DESIGN_RURAL': 'Ländlich',
        'enums-EContentHotelAttribute-ACTIVITY': 'Aktivitäten',
        'enums-EContentHotelAttribute-ACTIVITY_BIKE_RENTAL': 'Fahrradverleih',
        'enums-EContentHotelAttribute-ACTIVITY_GOLF': 'Golf',
        'enums-EContentHotelAttribute-ACTIVITY_MINIGOLF': 'Minigolf',
        'enums-EContentHotelAttribute-ACTIVITY_BOWLING': 'Bowling',
        'enums-EContentHotelAttribute-ACTIVITY_TENNIS': 'Tennis',
        'enums-EContentHotelAttribute-ACTIVITY_RUNNING': 'Laufen',
        'enums-EContentHotelAttribute-ACTIVITY_BILLIARD': 'Billard',
        'enums-EContentHotelAttribute-ACTIVITY_FITNESS': 'Fitnesscenter',
        'enums-EContentHotelAttribute-CATERING': 'Verpflegung',
        'enums-EContentHotelAttribute-CATERING_LUNCH': 'Mittagsmenü',
        'enums-EContentHotelAttribute-CATERING_DINNER': 'Abendmenü',
        'enums-EContentHotelAttribute-CATERING_BREAKFAST': 'Frühstück',
        'enums-EContentHotelAttribute-CATERING_HALFBOARD': 'Halbpension',
        'enums-EContentHotelAttribute-CATERING_FULLBOARD': 'Vollpension',
        'enums-EContentHotelAttribute-CATERING_OUTDOOR': 'Außengastronomie',
        'enums-EContentHotelAttribute-CATERING_RESTAURANT': 'Restaurant',
        'enums-EContentHotelAttribute-WELLNESS': 'Wellness',
        'enums-EContentHotelAttribute-WELLNESS_INDOOR_POOL': 'Innenpool',
        'enums-EContentHotelAttribute-WELLNESS_OUTDOOR_POOL': 'Außenpool',
        'enums-EContentHotelAttribute-WELLNESS_THERMAL': 'Therme',
        'enums-EContentHotelAttribute-WELLNESS_MASSAGE': 'Massage',
        'enums-EContentHotelAttribute-WELLNESS_WHIRLPOOL': 'Whirlpool',
        'enums-EContentHotelAttribute-HOTELFACILITY': 'Hotelausstattung',
        'enums-EContentHotelAttribute-HOTELFACILITY_24_ROOMSERVICE': '24h Zimmerservice',
        'enums-EContentHotelAttribute-HOTELFACILITY_BARRIER_FREE': 'Barrierefrei',
        'enums-EContentHotelAttribute-HOTELFACILITY_CLOTHES_CLEANING': 'Wäscherei',
        'enums-EContentHotelAttribute-HOTELFACILITY_COPY_FAX': 'Kopieren/Fax',
        'enums-EContentHotelAttribute-HOTELFACILITY_ELECTRIC_CHARGING_STATION': 'Elektroauto-Ladestation',
        'enums-EContentHotelAttribute-HOTELFACILITY_ELEVATOR': 'Fahrstuhl',
        'enums-EContentHotelAttribute-HOTELFACILITY_GREEN_AREA': 'Grünanlage',
        'enums-EContentHotelAttribute-HOTELFACILITY_INTERNET_WIFI': 'WLAN',
        'enums-EContentHotelAttribute-HOTELFACILITY_IT_ROOM': 'IT-Raum',
        'enums-EContentHotelAttribute-HOTELFACILITY_PRESS_ROOM': 'Presse-Raum',
        'enums-EContentHotelAttribute-HOTELFACILITY_PUBLIC_INTERNET': 'Öffentliches Internet',
        'enums-EContentHotelAttribute-HOTELFACILITY_SECRETARY': 'Sekretariat',
        'enums-EContentHotelAttribute-HOTELFACILITY_SHUTTLE': 'Shuttle-Service',
        'enums-EContentHotelAttribute-HOTELFACILITY_SMOKING_AREA': 'Raucherbereich',
        'enums-EContentHotelAttribute-HOTELFACILITY_WARDROBE': 'Garderobe',

        'enums-EHotelWidgetModeType-SLIM': 'Schmales Widget (nur Button)',
        'enums-EHotelWidgetModeType-EXPANDED': 'Aufgeklapptes Widget',

        'enums-EContentHotelCancellationDetailRule-BEFORE': 'Vor',
        'enums-EContentHotelCancellationDetailRule-AFTER': 'Nach',

        'enums-EContentHotelTextType-HOTEL_DESCRIPTION': 'Hotelbeschreibung',
        'enums-EContentHotelTextType-ARRIVAL_CAR': 'Anreise mit dem Auto',
        'enums-EContentHotelTextType-ARRIVAL_TRAIN': 'Anreise mit dem Zug',
        'enums-EContentHotelTextType-ARRIVAL_FLIGHT': 'Anreise mit dem Flugzeug',


        // Error codes
        'graphqlerror-PERMISSION_DENIED': 'Ihnen fehlen die erforderlichen Berechtigungen',
        'graphqlerror-FOREIGN_KEY_CONSTRAINT_FAILED':
          'Es wurden für den Speichervorgang erforderliche Datensätze nicht gefunden. Möglicherweise wurden von einem anderen Benutzer in der Zwischenzeit Änderungen vorgenommen. Bitte laden Sie das Browser-Fenster neu.',
        'graphqlerror-CONTENT_SPACE_SUCCESSOR': 'Es ist nur die Auswahl eines Eintrags auf einem untergeordneten Verwaltungsbereichs erlaubt',
        'graphqlerror-CONTENT_SPACE_ANCESTOR': 'Es ist nur die Auswahl eines Eintrags auf einem übergeordnetem Verwaltungsbereichs erlaubt',

        // Dashboard

        'dashboard-offerlist-chart-header': 'Buchungsverlauf',
        'dashboard-offerlist-chart-pending': 'In Bearbeitung',
        'dashboard-offerlist-chart-approved': 'Bestätigt',
        'dashboard-offerlist-chart-rejected': 'Abgelehnt',
        'dashboard-offerlist-chart-week': 'Woche',
        'dashboard-offerlist-chart-month': 'Monat',
        'dashboard-offerlist-chart-halfyear': 'Halbjahr',
        'dashboard-offerlist-chart-year': 'Jahr',
        'dashboard-offerlist-chart-count': 'Anzahl',
        'dashboard-offerlist-chart-price': 'Summe',
        'dashboard-offerlist-chart-space-all': 'Alle Verwaltungsbereiche',
        'dashboard-offerlist-chart-hotels-all': 'Alle Hotels',
        'dashboard-offerlist-chart-tooltip-header': 'Buchungsdatum: {{date}}',
        'dashboard-offerlist-chart-tooltip-count': '{{count}} Buchung(en)',
        'dashboard-offerlist-chart-tooltip-price': '{{price}}',
        'dashboard-offerlist-export': 'Dateiexport Buchungsliste (*.xlsx)',

        // Content Texts

        'content-product-header-position': 'Position',
        'content-product-header-hotel': 'Hotel',
        'content-product-header-language': 'Sprache',
        'content-product-header-text': 'Text',
        'content-product-add': 'Neuen Textbaustein anlegen',
        'graphqlerror-CONTENT_LANGUAGE_UNIQUE':
          'Die Kombination aus Position, Hotel, Sprache und Verwaltungsbereich muss eindeutig sein. Es wurden andere Datensätze gefunden, auf die dieses zutrifft.',

        'content-tabs-header-products': 'Textbausteine',
        'content-tabs-header-medias': 'Mediendateien',
        'content-tabs-header-offertemplates': 'Pdf/Html-Vorlagen',
        'content-tabs-header-hotels': 'Hotels',
        'content-tabs-header-emailtemplates': 'E-Mail-Vorlagen',

        'contentproducts-list-page-title': 'Textbausteine',
        'contentproducts-list-header-sku': 'SKU',
        'contentproducts-list-header-product': 'Leistung',
        'contentproducts-list-header-position': 'Textposition',
        'contentproducts-list-header-hotel': 'Hotel',
        'contentproducts-list-add': 'Textbaustein hinzufügen',

        'contentproduct-sku': 'SKU',
        'contentproduct-position': 'Textposition',
        'contentproduct-hotel': 'Hotel',
        'contentproduct-texts-tab': 'Texte',
        'contentproduct-text-language': 'Sprache',
        'contentproduct-text-text': 'Text',
        'contentproduct-text-add': 'Text hinzufügen',
        'contentproduct-save': 'Textbaustein speichern',
        'contentproduct-saved': 'Textbaustein gespeichert',

        'hotels-list-page-title': 'Hotels',
        'hotels-list-header-name': 'Name',
        'hotels-list-header-businesscountry': 'Land',
        'hotels-list-header-refcode': 'Referenzcode',
        'hotels-list-header-integration': 'PMS-Verknüpfung',
        'hotels-list-test-url': 'White Label Seite testen',
        'hotels-list-test-url-no-rooms': 'Ohne Zimmerbuchung',
        'hotels-list-add': 'Hotel anlegen',

        'hotel-name': 'Name',
        'hotel-refcode': 'Referenzcode',
        'hotel-extrefcode': 'Externer Referenzcode',
        'hotel-details': 'Details',
        'hotel-rating': 'Bewertung',
        'hotel-location-latitude': 'Koordinaten (Breitengrad)',
        'hotel-location-longitude': 'Koordinaten (Längengrad)',
        'hotel-showinlister': 'Im öffentlichen Hotel-Lister Verzeichnis anzeigen',
        'hotel-businessname': 'Offizieller Firmenname',
        'hotel-businessaddress1': 'Geschäftsadresse 1',
        'hotel-businessaddress2': 'Geschäftsadresse 2',
        'hotel-businessaddress3': 'Geschäftsadresse 3',
        'hotel-businessaddress4': 'Geschäftsadresse 4',
        'hotel-businesscountry': 'Land',
        'hotel-businessemail': 'Geschäftliche E-Mail-Adresse',
        'hotel-notificationemail': 'E-Mail-Adresse für Benachrichtigungen',
        'hotel-location-fetch-coords': 'Koordinaten von Adresse automatisch ermitteln',
        'hotel-location-openmap': 'In Karte öffnen',

        'hotel-settings-location-tab': 'Standort',

        'hotel-widget-display-mode': 'Widget-Anzeigemodus',

        'hotel-settings-texts-tab': 'Textbausteine',
        'hotel-settings-texts-type': 'Typ',
        'hotel-settings-texts-details': 'Text',
        'hotel-settings-texts-add': 'Textbaustein hinzufügen',
        'hotel-settings-texts-language': 'Sprache',


        'hotel-settings-meeting-rooms-tab': 'Seminarräume',
        'hotel-settings-meeting-rooms-add': 'Seminarraum hinzufügen',
        'hotel-settings-meeting-rooms-name': 'Name',
        'hotel-settings-meeting-rooms-area': 'Fläche',
        'hotel-settings-meeting-rooms-capacityUForm': 'U-Form',
        'hotel-settings-meeting-rooms-capacityTheater': 'Theater',
        'hotel-settings-meeting-rooms-capacityParlament': 'Parlament',
        'hotel-settings-meeting-rooms-capacityCircle': 'Kreis',
        'hotel-settings-meeting-rooms-capacityBankett': 'Bankett',
        'hotel-settings-meeting-rooms-capacityCocktail': 'Cocktail',
        'hotel-settings-meeting-rooms-capacityBlock': 'Block',

        'hotel-settings-attributes-tab': 'Attribute',
        'hotel-settings-cancellation-details-tab': 'Stornierungsbedingungen',
        'hotel-settings-cancellation-details-rule': 'Regel',
        'hotel-settings-cancellation-details-cancellation-days': 'Stornierungsfrist (Tage)',
        'hotel-settings-cancellation-details-rate': 'Stornorate',
        'hotel-settings-cancellation-details-add': 'Stornierungsbedingung hinzufügen',

        'hotel-settings-media-tab': 'Medien',
        'hotel-settings-medias-name': 'Name',
        'hotel-settings-medias-add': 'Medium hinzufügen',

        'hotel-widget-status-READY': 'SEM-Widget vollständig konfiguriert',
        'hotel-widget-status-NO_TAX_RATES': 'SEM-Widget unvollständig: Steuersätze wurden das Land nicht festgelegt',
        'hotel-widget-status-NO_SERVICE_TYPES': 'SEM-Widget unvollständig: Keine veröffentlichte Leistungen zugeordnet',
        'hotel-widget-status-NO_PRICELIST': 'SEM-Widget unvollständig: Keine veröffentlichte Preisliste zugeordnet',
        'hotel-widget-status-PRICELIST_INCOMPLETE': 'SEM-Widget unvollständig: Preisliste unvollständig',

        'hotel-widget-code': 'Code Snippet zum Einbinden in die Webseite',
        'hotel-widgeturl': 'White Label URL',
        'hotel-widget-preview': 'Vorschau',
        'hotel-widgeturl-helper':
          'Die URL, unter der das White Label Widget erreichbar ist. Links in E-Mails verweisen auf die URL anstatt auf die URL von SEM.',
        'hotel-widget-no-rooms': 'Ohne Zimmerbuchung',
        'hotel-widget-no-rooms-helper': 'Wenn aktiviert, wird die Zimmerbuchung im White Label Widget nicht angezeigt.',
        'hotel-widget-privacy-url': 'Datenschutz URL',
        'hotel-widget-privacy-url-helper': 'Wenn angegeben, wird ein Link zum Datenschutz im White Label Widget verlinkt.',
        'hotel-widget-terms-url': 'AGB URL',
        'hotel-widget-terms-url-helper': 'Wenn angegeben, wird ein Link zu den AGB im White Label Widget verlinkt.',
        'hotel-widget-color': 'Widget Primärfarbe',
        'hotel-widget-color-helper': 'Die Primärfarbe wird für die Buttons im White Label Widget verwendet.',
        'hotel-widget-color-enabled': 'Setze Widget Primärfarbe',
        'hotel-widget-slim-mode': 'Nur als Button anzeigen (für schmale Bereiche)',
        'hotel-widget-slim-mode-helper': 'Wenn aktiviert, wird das Widget nie aufgeklappt.',
        'hotel-widget-expanded-mode': 'Aufgeklapptes Widget (wenn es sich von der Browserbreite ausgeht)',
        'hotel-widget-expanded-mode-helper': 'Wenn aktiviert, wird das Widget schon beim ersten Erscheinen aufgeklappt.',
        'hotel-offertemplate': 'Angebotsvorlage',
        'hotel-offerexpirationdays': 'Angebotslaufzeit (Tage)',
        'hotel-offernotapprovedreminderclient': 'Versende Erinnerung an Kunden (nach Tagen)',
        'hotel-offernotapprovedreminderhotel': 'Versende Erinnerung an Hotel (nach Tage)',
        'hotel-depositreminderhotel': 'Deposit-Rechnung Erinnerung (Tage vor Beginn)',
        'hotel-depositremindercheckhotel': 'Deposit-Eingang Erinnerung (nach Tage)',
        'hotel-enableavailabilitycheck': 'Verfügbarkeitsprüfung aktivieren',
        'hotel-servicetypes': 'Verfügbare Leistungspakete',
        'hotel-copy': 'Hotel duplizieren',
        'hotel-save': 'Hotel-Einstellungen speichern',
        'hotel-updated': 'Hotel-Einstellungen gespeichert',
        'hotel-created': 'Hotel angelegt',
        'hotel-copied': 'Hotel wurde dupliziert',

        'hotel-confirm-delete':
          'Das Hotel sowie dafür erstellte Textbausteine werden unwiderruflich gelöscht und können nicht wiederhergestellt werden.',
        'hotel-confirm-delete-title': 'Hotel löschen',
        'hotel-delete': 'Hotel löschen',
        'hotel-deleted': 'Hotel wurde gelöscht',
        'graphqlerror-HOTEL_DELETE_NOT_POSSIBLE_STILL_ASSIGNED': 'Das Hotel {{name}} kann nicht gelöscht werden, da es noch in Verwendung ist.',

        'hotel-settings-tab': 'Stammdaten',
        'hotel-offers-tab': 'Angebote',
        'hotel-notifications-tab': 'Benachrichtigungen',
        'hotel-service-tab': 'Leistungen',
        'hotel-widget-tab': 'SEM-Widget Einstellungen',

        'availability-hotels-list-page-title': 'Verfügbarkeit',
        'availability-list-page-title': 'Verfügbarkeit',

        'contentmedias-list-page-title': 'Mediendateien',
        'contentmedias-list-header-name': 'Dateiname',
        'contentmedias-list-header-mimetype': 'Mime-Type',
        'contentmedias-list-header-updatedat': 'Letzte Änderung',
        'contentmedias-export-run': 'Export',
        'contentmedias-import-run': 'Import',
        'contentmedias-import-ready': 'Mediendateien wurden importiert',

        'contentmedia-confirm-delete': 'Die Datei wird unwiderruflich gelöscht und kann nicht wiederhergestellt werden.',
        'contentmedia-confirm-delete-title': 'Datei löschen',
        'contentmedia-deleted': 'Datei wurde gelöscht',
        'graphqlerror-MEDIA_DELETE_NOT_POSSIBLE_STILL_ASSIGNED': 'Die Datei {{name}} kann nicht gelöscht werden, da sie noch in Verwendung ist.',

        'emailtemplates-list-page-title': 'E-Mail-Vorlagen',
        'emailtemplates-list-header-usage': 'Verwendung',
        'emailtemplates-list-header-subject': 'Betreff',
        'emailtemplates-list-header-language': 'Sprache',
        'emailtemplates-list-header-receiver': 'Empfänger',
        'emailtemplates-list-header-updatedat': 'Letzte Änderung',
        'emailtemplates-list-add': 'E-Mail-Vorlage anlegen',
        'emailtemplates-export-run': 'Export',
        'emailtemplates-import-run': 'Import',
        'emailtemplates-import-ready': 'E-Mail-Vorlagen wurden importiert',
        'emailtemplates-list-receiver-client': 'Kunde',
        'emailtemplates-list-receiver-hotel': 'Hotel',
        'emailtemplates-list-receiver-seminargo': 'seminargo',
        'emailtemplates-list-receiver-user': 'SEM-Benutzer',

        'emailtemplate-usage': 'Verwendung',
        'emailtemplate-subject': 'Betreff',
        'emailtemplate-language': 'Sprache',
        'emailtemplate-body': 'Inhalt',
        'emailtemplate-medias': 'Mediendateien',
        'emailtemplate-save': 'E-Mail-Vorlage speichern',
        'emailtemplate-updated': 'E-Mail-Vorlage gespeichert',
        'emailtemplate-created': 'E-Mail-Vorlage angelegt',
        'emailtemplate-preview': 'Vorschau anzeigen',
        'emailtemplate-preview-created': 'Vorschau wird angezeigt',
        'emailtemplate-confirm-delete': 'Die E-Mail-Vorlage wird gelöscht und kann nicht mehr verwendet werden.',
        'emailtemplate-confirm-delete-title': 'E-Mail-Vorlage löschen',
        'emailtemplate-delete': 'E-Mail-Vorlage löschen',
        'emailtemplate-deleted': 'E-Mail-Vorlage gelöscht',
        'emailtemplate-copy': 'E-Mail-Vorlage duplizieren',
        'emailtemplate-copied': 'E-Mail-Vorlage wurde dupliziert',

        'offertemplates-list-page-title': 'PDF/HTML-Vorlagen',
        'offertemplates-list-header-name': 'Bezeichnung',
        'offertemplates-list-header-title': 'Dokumententitel',
        'offertemplates-list-header-type': 'Vorlagen-Typ',
        'offertemplates-list-header-updatedat': 'Letzte Änderung',
        'offertemplates-list-header-ispublished': 'Veröffentlicht',
        'offertemplates-list-add': 'Vorlage anlegen',
        'offertemplates-export-run': 'Export',
        'offertemplates-import-run': 'Import',
        'offertemplates-import-ready': 'Vorlagen wurden importiert',

        'offertemplate-data-tab': 'Basis-Einstellungen',
        'offertemplate-template-tab': 'HTML Layout',
        'offertemplate-name': 'Bezeichnung',
        'offertemplate-ispublished': 'Veröffentlicht',
        'offertemplate-includeemptylineitems': 'Leere Angebotszeilen übernehmen',

        'offertemplate-section-offer': 'Anfragedaten',
        'offertemplate-startdate': 'Startdatum',
        'offertemplate-offerdate': 'Angebotsdatum',
        'offertemplate-version': 'Versionskommentar',
        'offertemplate-section-document': 'Dokumenteneinstellungen',
        'offertemplate-title': 'Dokumententitel',
        'offertemplate-filename': 'Dateiname (ohne Endung)',
        'offertemplate-section-header': 'Kopf- und Fußzeilen',
        'offertemplate-header': 'Vorlage Kopfzeile',
        'offertemplate-footer': 'Vorlage Fußzeile',
        'offertemplate-section-page': 'Seiteneinstellungen',
        'offertemplate-marginTop': 'Seitenabstand oben (px)',
        'offertemplate-marginBottom': 'Seitenabstand unten (px)',
        'offertemplate-marginLeft': 'Seitenabstand links (px)',
        'offertemplate-marginRight': 'Seitenabstand rechts (px)',
        'offertemplate-section-css': 'CSS Stylesheet',
        'offertemplate-type': 'Vorlagen-Typ',
        'offertemplate-css': 'CSS Stylesheet',
        'offertemplate-save': 'Vorlage speichern',
        'offertemplate-updated': 'Vorlage gespeichert',
        'offertemplate-created': 'Vorlage angelegt',
        'offertemplate-confirm-delete': 'Die Vorlage wird gelöscht und kann nicht mehr verwendet werden.',
        'offertemplate-confirm-delete-title': 'Vorlage löschen',
        'offertemplate-delete': 'Vorlage löschen',
        'offertemplate-deleted': 'Vorlage gelöscht',
        'graphqlerror-OFFERTEMPLATE_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Die Vorlage {{name}} kann nicht gelöscht werden, da sie noch in Verwendung ist.',

        'offertemplate-copy': 'Vorlage duplizieren',
        'offertemplate-copied': 'Vorlage wurde dupliziert',

        'offertemplate-section-client': 'Kundendaten',
        'offertemplate-section-client-company': 'Firmenadresse',
        'offertemplate-section-client-billing': 'Rechnungsadresse',
        'offertemplate-client-company-firstname': 'Vorname',
        'offertemplate-client-company-lastname': 'Nachname',
        'offertemplate-client-company-company': 'Firma',
        'offertemplate-client-company-address': 'Adresse',
        'offertemplate-client-company-zip': 'PLZ',
        'offertemplate-client-company-city': 'Ort',
        'offertemplate-client-company-country': 'Land',
        'offertemplate-client-phone': 'Telefon',
        'offertemplate-client-email': 'E-Mail',
        'offertemplate-client-billing-company': 'Firma',
        'offertemplate-client-billing-firstname': 'Vorname',
        'offertemplate-client-billing-lastname': 'Nachname',
        'offertemplate-client-billing-address': 'Adresse',
        'offertemplate-client-billing-zip': 'PLZ',
        'offertemplate-client-billing-city': 'Ort',
        'offertemplate-client-billing-country': 'Land',

        'offertemplate-section-additional-info': 'Zusatzinfo / Kommentar',
        'offertemplate-additionalinfo1': 'Zusatzinfo 1',
        'offertemplate-additionalinfo2': 'Zusatzinfo 2',
        'offertemplate-additionalinfo3': 'Zusatzinfo 3',

        'offertemplate-layout': 'HTML Inhalt',
        'offerblock-plainhtml-header': 'Textblock',
        'offerblock-plainhtml-hint': 'Statischer Text (HTML)',
        'offerblock-plainhtml-html': 'HTML Inhalt',
        'offerblock-lineitems-header': 'Angebotszeilen (pro Tag)',
        'offerblock-lineitems-header-day': 'Angebotszeilen',
        'offerblock-lineitems-hint': 'Dynamischer Text (HTML)',
        'offerblock-lineitems-html': 'HTML Vorlage',
        'offerblock-inputdays-header': 'Leistungskonfiguration (pro Tag)',
        'offerblock-inputdays-header-day': 'Leistungskonfiguration',
        'offerblock-inputdays-hint': 'Dynamischer Text (HTML)',
        'offerblock-inputdays-html': 'HTML Vorlage',
        'offerblock-lineitems-data-tab': 'Angebotszeilen',
        'offerblock-lineitems-template-tab': 'HTML Vorlage',
        'offerblock-lineitem-header': 'Überschrift',
        'offerblock-lineitem-details': 'Beschreibungstext',
        'offerblock-lineitem-count': 'Anzahl',
        'offerblock-lineitem-priceitem': 'Einzelpreis',
        'offerblock-lineitem-priceitem-net': 'Einzelpreis (Netto)',
        'offerblock-lineitem-priceitem-gross': 'Einzelpreis (Brutto)',
        'offerblock-lineitem-pricenet': 'Gesamtpreis Netto',
        'offerblock-lineitem-pricegross': 'Gesamtpreis Brutto',
        'offerblock-lineitem-delete': 'Angebotszeile Löschen',
        'offerblock-lineitem-components': 'Steuerklassen',
        'offerblock-formfield-append': 'Eingabefeld hinzufügen',
        'offerblock-formfield-delete': 'Eingabefeld löschen',
        'offerblock-formfield-type': 'Feldtyp',
        'offerblock-formfield-name': 'Feldname',
        'offerblock-formfield-label': 'Bezeichnung',
        'offerblock-formfield-placeholder': 'Platzhalter',
        'offerblock-formfield-options': 'Einstellungen',
        'offerblock-formfield-helptext': 'Hilfetext',
        'offerblock-formfield-defaultvalue': 'Standardwert',
        'offerblock-formfield-required': 'Pflichtfeld',
        'offerblock-formfield-usage': 'Verwendung als: ',
        'offerblock-customform-header': 'Texteingabeblock',
        'offerblock-customform-hint': '',
        'offerblock-customform-html': 'HTML Vorlage',
        'offerblock-customform-data-tab': 'Eingabefelder',
        'offerblock-customform-template-tab': 'HTML Vorlage',
        'offerblock-formfield-type-textarea-richtext': 'Formatierter Text',
        'offerblock-formfield-type-textarea-plaintext': 'Mehrzeiliger Text',
        'offerblock-formfield-type-text': 'Einzeiliger Text',

        'offerblock-lineitem-addsku': 'Neue Angebotszeile (aus Katalog)',
        'offerblock-lineitem-addfree': 'Neue Angebotszeile (Freitext)',
        'offerblock-delete': 'Gesamten Block Löschen',
        'offerblock-description': 'Inhaltsblock-Beschreibung',
        'offerblock-customform-add': 'Texteingabeblock',
        'offerblock-lineitems-add': 'Angebotszeilen',
        'offerblock-plainhtml-add': 'Textblock',
        'offerblock-inputdays-add': 'Leistungskonfiguration',
        'offerblock-contentblock-add': 'Inhaltsblock hinzufügen',

        'offertemplate-advanced': 'Erweiterte Ansicht',

        'offertemplate-add-previousday': 'Vortagesanreise hinzufügen',
        'offertemplate-add-day': 'Zusätzlichen Tag hinzufügen',
        'offerblock-delete-day': 'Tag löschen',

        'offerversion-saved': 'Angebotsversion wurde gespeichert',

        // Prices

        'pricelists-tabs-header-pricelists': 'Preislisten',
        'pricelists-tabs-header-taxtypes': 'Steuerklassen',
        'pricelists-tabs-header-import': 'Dateiimport',

        'taxtypes-list-page-title': 'Steuerklassen',
        'taxtypes-list-header-name': 'Steuerklasse',
        'taxtypes-list-header-sequence': 'Sortierreihenfolge',
        'taxtypes-list-add': 'Neue Steuerklasse anlegen',
        'taxtype-name': 'Bezeichnung',
        'taxtype-sequence': 'Sortierreihenfolge',
        'taxtype-rates-tab': 'Steuersätze',
        'taxtype-rates': 'Keine Steuersätze angelegt',
        'taxtype-rate-name': 'Bezeichnung',
        'taxtype-rate-rate': 'Steuersatz',
        'taxtype-rate-country': 'Land',
        'taxtype-rate-isreversecharge': 'Reverse Charge',
        'taxtype-rate-validfrom': 'Gültig von',
        'taxtype-rate-validto': 'Gültig bis',
        'taxtype-rate-add': 'Neuen Steuersatz anlegen',
        'taxtype-save': 'Steuerklasse speichern',
        'taxtype-saved': 'Steuerklasse gespeichert',

        'taxtype-delete': 'Steuerklasse löschen',
        'taxtype-deleted': 'Steuerklasse gelöscht',
        'taxtype-confirm-delete-title': 'Steuerklasse löschen',
        'taxtype-confirm-delete': 'Soll die Steuerklasse inklusive Steuersätzen wirklich gelöscht werden ?',
        'graphqlerror-TAXTYPE_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Die Steuerklasse {{name}} kann nicht gelöscht werden, da sie noch in Verwendung ist.',

        'pricelists-list-page-title': 'Preislisten',
        'pricelists-list-published': 'Veröffentlichte Preislisten',
        'pricelists-list-notpublished': 'Nicht veröffentliche Preislisten',
        'pricelists-list-header-name': 'Preisliste',
        'pricelists-list-header-refcode': 'Referenzcode',
        'pricelists-list-header-version': 'Version',
        'pricelists-list-header-validity': 'Gültigkeitszeitraum',
        'pricelists-list-header-ispublished': 'Veröffentlicht',
        'pricelists-list-header-isdefault': 'Standard',
        'pricelists-list-header-isvalid': 'Vollständig',
        'pricelists-list-header-hotel': 'Hotel',
        'pricelists-list-add': 'Preisliste anlegen',
        'pricelists-list-import': 'Preisblatt importieren',

        'pricelist-name': 'Bezeichnung',
        'pricelist-refcode': 'Referenzcode',
        'pricelist-version': 'Version',
        'pricelist-isdefault': 'Standard-Preisliste',
        'pricelist-ispublished': 'Veröffentlicht',
        'pricelist-ispricesbrut': 'Preise sind Bruttopreise',
        'pricelist-currency': 'Währung',
        'pricelist-hotel': 'Hotel',
        'pricelist-validfrom': 'Gültig von',
        'pricelist-validto': 'Gültig bis',
        'pricelist-validity-add': 'Gültigkeitszeitraum hinzufügen',

        'pricelist-validation-tab': 'Preisliste unvollständig',
        'pricelist-validation-products': '{{productCount}} Einzelleistung(en) ohne Preis',
        'pricelist-validation-productcomponents': '{{productCount}} Einzelleistung(en) ohne Steuerklassen',
        'pricelist-validation-bundles': '{{bundleCount}} Sammelleistung(en) ohne Preis',
        'pricelist-validation-bundlecomponents': '{{bundleCount}} Sammelleistung(en) ohne Steuerklassen',
        'pricelist-validation-facilities': '{{facilityCount}} Ausstattung(en) ohne Preis',
        'pricelist-validation-facilitycomponents': '{{facilityCount}} Ausstattung(en) ohne Steuerklassen',
        'graphqlerror-PRICELIST_PUBLISH_NOT_POSSIBLE':
          'Die Preisliste {{name}} kann nicht veröffentlicht werden, da nicht für alle Leistungen Preise angegeben sind.',

        'pricelist-selector-page-title': 'Preisauswahl',
        'pricelist-selectors-tab': 'Preisauswahl',
        'pricelist-selector-name': 'Bezeichnung',
        'pricelist-selector-baseprice': 'Basispreis',
        'pricelist-selector-minguestcount': 'Min. Gäste',
        'pricelist-selector-maxguestcount': 'Max. Gäste',
        'pricelist-selector-minitemcount': 'Min. Anzahl',
        'pricelist-selector-maxitemcount': 'Max. Anzahl',
        'pricelist-selector-validfrom': 'Gültig von',
        'pricelist-selector-validto': 'Gültig bis',
        'pricelist-selector-weekdays': 'Wochentage',
        'pricelist-selector-add': 'Neue Preisauswahl anlegen',

        'pricelist-save': 'Preisliste speichern',
        'pricelist-saved': 'Preisliste gespeichert',

        'pricelist-delete': 'Preisliste löschen',
        'pricelist-deleted': 'Preisliste gelöscht',
        'pricelist-confirm-delete-title': 'Preisliste löschen',
        'pricelist-confirm-delete': 'Soll die Preisliste inklusive Preisen wirklich gelöscht werden ?',
        'graphqlerror-PRICELIST_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Die Preisliste {{name}} kann nicht gelöscht werden, da sie durch Angebote in Verwendung ist.',
        'pricelist-confirm-copy-title': 'Preisliste duplizieren',
        'pricelist-space': 'Verwaltungsbereich',
        'pricelist-copy': 'Preisliste duplizieren',
        'pricelist-copied': 'Preisliste wurde dupliziert',

        'selector-backlink': 'Zurück zur Preisliste',
        'selector-name': 'Bezeichnung',
        'selector-isbaseprice': 'Basispreis',
        'selector-minguestcount': 'Min. Gäste',
        'selector-maxguestcount': 'Max. Gäste',
        'selector-minitemcount': 'Min. Anzahl',
        'selector-maxitemcount': 'Max. Anzahl',
        'selector-validfrom': 'Gültig von',
        'selector-validto': 'Gültig bis',
        'selector-weekdays': 'Wochentage',

        'selector-products-tab': 'Preise Einzelleistungen',
        'selector-bundles-tab': 'Preise Sammelleistungen',
        'selector-facilities-tab': 'Preise Ausstattung & Technik',
        'selector-multitax-price': 'Steueranteil',
        'selector-product-price': 'Preis',
        'selector-bundle-price': 'Preis',
        'selector-bundle-pricefromproduct': 'Summe aus Einzelleistungen berechnen',
        'selector-facility-price': 'Preis',
        'selector-save': 'Preise speichern',
        'selector-saved': 'Preise gespeichert',

        'selector-delete': 'Preisauswahl löschen',
        'selector-deleted': 'Preisauswahl gelöscht',
        'selector-confirm-delete-title': 'Preisauswahl löschen',
        'selector-confirm-delete': 'Soll die Preisauswahl inklusive Preisen wirklich gelöscht werden ?',
        'graphqlerror-SELECTOR_DELETE_NOT_POSSIBLE':
          'Die Preisauswahl {{name}} kann nicht gelöscht werden, da die Preisliste dadurch unvollständig sein würde.',

        'selector-copy': 'Preisauswahl duplizieren',
        'selector-copied': 'Preisauswahl wurde dupliziert',

        'prices-import-page-title': 'Dateiimport',
        'prices-import-name': 'Name',
        'prices-import-version': 'Version',
        'prices-import-file': 'Preisliste Datei (*.json, *.xlsx)',
        'prices-import-run': 'Dateiimport starten',
        'prices-import-ready': 'Dateiimport abgeschlossen',
        'prices-import-result-header': 'Ergebnis',
        'prices-import-result-pricelist': 'Preisliste',
        'prices-import-result-version': 'Version',
        'prices-import-result-complete': 'Vollständig',
        'prices-import-result-productnoprice': 'Einzelleistungen ohne Preis',
        'prices-import-result-productnocomponents': 'Einzelleistungen ohne Steuerklassen',
        'prices-import-result-bundlesnoprice': 'Sammelleistungen ohne Preis',
        'prices-import-result-bundlesnocomponents': 'Sammelleistungen ohne Steuerklassen',
        'prices-import-result-facilitiesnoprice': 'Ausstattung ohne Preis',
        'prices-import-result-facilitiesnocomponents': 'Ausstattung ohne Steuerklassen',
        'prices-import-start': 'Zurück',
        'graphqlerror-IMPORT_PRICES_FAILED': 'Dateiimport fehlgeschlagen, bitte Dateiinhalt überprüfen: {{- err}}.',

        'prices-export-run': 'Dateiexport starten',
        'prices-export-ready': 'Dateiexport abgeschlossen',

        // Products

        'productprices-pricelist': 'Preisliste',
        'productprices-selector': 'Preisauswahl',
        'productprices-ispublished': 'Veröffentlicht',
        'productprices-version': 'Version',
        'productprices-price': 'Preis',

        'products-tabs-header-products': 'Einzelleistungen',
        'products-tabs-header-bundles': 'Sammelleistungen',
        'products-tabs-header-facilities': 'Ausstattung & Technik',
        'products-tabs-header-servicetypes': 'Leistungspakete',
        'products-tabs-header-import': 'Dateiimport',

        'products-list-page-title': 'Einzelleistungen',
        'products-list-header-product': 'Leistung',
        'products-list-header-sku': 'SKU',
        'products-list-header-recurring': 'Verrechnung',
        'products-list-header-isaddon': 'Zusatzleistung',
        'products-list-header-isdeduction': 'Abschlag',
        'products-list-add': 'Leistung hinzufügen',
        'products-list-import': 'Leistungs-Liste importieren',

        'product-name': 'Bezeichnung',
        'product-sku': 'SKU',
        'product-sku-not-unique': 'SKU "{{sku}}" bereits vergeben',
        'product-lookupcode': 'SEM-Referenzcode',
        'product-extrefcode': 'Externer Referenzcode',
        'product-recurring': 'Verrechnung',
        'product-isdeduction': 'Verrechnung als Abschlag',
        'product-isaddon': 'Optionale Zusatzleistung',
        'product-components': 'Steuerklasse(n)',
        'product-component-taxtype': 'Steuerklasse',
        'product-components-not-100': 'Steuerklassenanteile müssen sich auf 100% summieren',
        'product-prices-tab': 'Preislisten',
        'product-bundles-tab': 'Sammelleistungen',
        'product-bundles-bundle': 'Sammelleistung',
        'product-bundles-included': 'Anzahl inkludiert',
        'product-text-tab': 'Textbausteine',
        'product-availability-tab': 'Verfügbarkeit',
        'product-save': 'Leistung speichern',
        'product-save-text': 'Textbausteine speichern',
        'product-saved': 'Leistung gespeichert',
        'product-delete': 'Leistung löschen',
        'product-deleted': 'Leistung gelöscht',
        'product-confirm-delete-title': 'Leistung löschen',
        'product-confirm-delete': 'Soll die Leistung inklusive Preisen wirklich gelöscht werden ?',
        'product-confirm-copy-title': 'In welchen Verwaltungsbereich soll die Leistung dupliziert werden?',
        'product-copy-space': 'Verwaltungsbereich',
        'graphqlerror-PRODUCT_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Die Leistung {{name}} kann nicht gelöscht werden, da es durch Sammelleistungen und/oder Leistungspakete in Verwendung ist.',

        'product-copy': 'Leistung duplizieren',
        'product-copied': 'Leistung wurde dupliziert',

        'productbundles-list-page-title': 'Sammelleistungen',
        'productbundles-list-header-bundle': 'Leistung',
        'productbundles-list-header-sku': 'SKU',
        'productbundles-list-header-recurring': 'Verrechnung',
        'productbundles-list-add': 'Sammelleistung hinzufügen',

        'productbundle-name': 'Bezeichnung',
        'productbundle-sku': 'SKU',
        'productbundle-sku-not-unique': 'SKU "{{sku}}" bereits vergeben',
        'productbundle-recurring': 'Verrechnung',
        'productbundle-lookupcode': 'SEM-Referenzcode',
        'productbundle-extrefcode': 'Externer Referenzcode',
        'productbundle-components': 'Steuerklasse(n)',
        'productbundle-items-tab': 'Einzelleistungsanteile',
        'productbundle-item-product': 'Einzelleistung',
        'productbundle-item-includedcount': 'Inkludierte Einheiten',
        'productbundle-item-isoptional': 'Optional',
        'productbundle-item-add': 'Zusätzlicher Leistungsanteil',
        'productbundle-prices-tab': 'Preislisten',
        'productbundle-text-tab': 'Textbausteine',
        'productbundle-availability-tab': 'Verfügbarkeit',
        'productbundle-save': 'Sammelleistung speichern',
        'productbundle-save-text': 'Textbausteine speichern',
        'productbundle-saved': 'Sammelleistung gespeichert',
        'productbundle-delete': 'Sammelleistung löschen',
        'productbundle-deleted': 'Sammelleistung gelöscht',
        'productbundle-confirm-delete-title': 'Sammelleistung löschen',
        'productbundle-confirm-delete': 'Soll die Sammelleistung inklusive Preisen wirklich gelöscht werden ?',
        'graphqlerror-BUNDLE_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Das Sammelleistung {{name}} kann nicht gelöscht werden, da es durch Leistungspakete in Verwendung ist.',
        'productbundle-confirm-copy-title': 'In welchen Verwaltungsbereich soll die Sammelleistung dupliziert werden?',
        'productbundle-copy-space': 'Verwaltungsbereich',
        'productbundle-copy': 'Sammelleistung duplizieren',
        'productbundle-copied': 'Sammelleistung wurde dupliziert',

        'facilities-list-page-title': 'Ausstattung & Technik',
        'facilities-list-header-facility': 'Ausstattung',
        'facilities-list-header-sku': 'SKU',
        'facilities-list-header-recurring': 'Verrechnung',
        'facilities-list-add': 'Ausstattung hinzufügen',
        'facilities-list-header-isaddon': 'Zusatzaustattung',

        'facility-name': 'Bezeichnung',
        'facility-sku': 'SKU',
        'facility-sku-not-unique': 'SKU "{{sku}}" bereits vergeben',
        'facility-lookupcode': 'SEM-Referenzcode',
        'facility-extrefcode': 'Externer Referenzcode',
        'facility-recurring': 'Verrechnung',
        'facility-isaddon': 'Optionale Zusatzausstattung',
        'facility-components': 'Steuerklasse(n)',
        'facility-prices-tab': 'Preislisten',
        'facility-text-tab': 'Textbausteine',
        'facility-availability-tab': 'Verfügbarkeit',
        'facility-save': 'Ausstattung speichern',
        'facility-save-text': 'Textbausteine speichern',
        'facility-saved': 'Ausstattung gespeichert',
        'facility-delete': 'Ausstattung löschen',
        'facility-deleted': 'Ausstattung gelöscht',
        'facility-confirm-delete-title': 'Ausstattung löschen',
        'facility-confirm-delete': 'Soll die Ausstattung inklusive Preisen wirklich gelöscht werden ?',
        'graphqlerror-FACILITY_DELETE_NOT_POSSIBLE_STILL_ASSIGNED':
          'Die Ausstattung {{name}} kann nicht gelöscht werden, da es durch Leistungspakete in Verwendung ist.',
        'facility-confirm-copy-title': 'In welchen Verwaltungsbereich soll die Ausstattung dupliziert werden?',
        'facility-copy-space': 'Verwaltungsbereich',
        'facility-copy': 'Ausstattung duplizieren',
        'facility-copied': 'Ausstattung wurde dupliziert',

        'servicetypes-list-page-title': 'Leistungspakete',
        'servicetypes-list-header-type': 'Leistungspaket',
        'servicetypes-list-header-sku': 'SKU',
        'servicetypes-list-header-sequence': 'Sortierreihenfolge',
        'servicetypes-list-header-ispublished': 'Veröffentlicht',
        'servicetypes-list-add': 'Leistungspaket hinzufügen',

        'servicetype-name': 'Bezeichnung',
        'servicetype-sku': 'SKU',
        'servicetype-sku-not-unique': 'SKU "{{sku}}" bereits vergeben',
        'servicetype-ispublished': 'Veröffentlicht',
        'servicetype-sequence': 'Sortierreihenfolge',
        'servicetype-hotel': 'Hotel',
        'servicetype-hotel-empty': 'Keine Auswahl (Für alle Hotels verfügbar)',
        'servicetype-pricelist': 'Preisliste',
        'servicetype-pricelist-empty': 'Keine Auswahl (Standard-Preisliste verwenden)',
        'servicetype-products-tab': 'Inkludierte Leistungen',
        'servicetype-products-product': 'Leistung',
        'servicetype-products-isfordayvisitor': 'Für Tagesgäste',
        'servicetype-products-isforovernight': 'Für Übernachtungsgäste',
        'servicetype-products-isforroom': 'Nur Übernachtung',
        'servicetype-products-isfordeparture': 'Für Abreisegäste',
        'servicetype-products-isforsemidayvisitor': 'Für Halbtagesgäste',
        'servicetype-products-isforsemidayovernight': 'Für Halbtages-Übernachtungsgäste',
        'servicetype-products-isforsemidaydeparture': 'Für Halbtages-Abreisegäste',
        'servicetype-products-isforprevday': 'Für Vortagsanreise',
        'servicetype-products-isfornextday': 'Für Verlängerungsnacht',
        'servicetype-product-add': 'Einzelleistung hinzufügen',
        'servicetype-productbundle-add': 'Sammelleistung hinzufügen',
        'servicetype-product-or-bundle': 'Keine Einzelleistung oder Sammelleistung ausgewählt',
        'servicetype-products-or-facilities': 'Keine Leistungen oder Ausstattung ausgewählt',
        'servicetype-usage-not-selected': 'Keine Verrechnung ausgewählt',
        'servicetype-facilities-tab': 'Inkludierte Ausstattung & Technik',
        'servicetype-facility-name': 'Ausstattung',
        'servicetype-facility-includedcount': 'Inkludierte Anzahl',
        'servicetype-facility-isforday': 'Für Ganztagesbelegung',
        'servicetype-facility-isforsemiday': 'Für Halbtagesbelegung',
        'servicetype-facility-add': 'Ausstattung hinzufügen',
        'servicetype-addons-tab': 'Optionale Zusatzpakete',
        'servicetype-addons-facilities': 'Optionale Ausstattung & Technik',
        'servicetype-addons-products': 'Optionale Zusatzleistungen',
        'servicetype-text-tab': 'Textbausteine',
        'servicetype-save': 'Leistungspaket speichern',
        'servicetype-save-text': 'Textbausteine speichern',
        'servicetype-saved': 'Leistungspaket gespeichert',
        'servicetype-delete': 'Leistungspaket löschen',
        'servicetype-deleted': 'Leistungspaket gelöscht',
        'servicetype-confirm-delete-title': 'Leistungspaket löschen',
        'servicetype-confirm-delete': 'Soll das Leistungspaket wirklich gelöscht werden ?',
        'servicetype-copy': 'Leistungspaket duplizieren',
        'servicetype-confirm-copy-title': 'In welchen Verwaltungsbereich soll das Leistungspaket dupliziert werden?',
        'servicetype-copy-space': 'Verwaltungsbereich',
        'servicetype-copied': 'Leistungspaket wurde dupliziert',

        'products-import-page-title': 'Dateiimport',
        'products-import-mode': 'Import-Modus',
        'products-import-file': 'Leistungeskatalog Datei (*.json)',
        'products-import-run': 'Dateiimport starten',
        'products-import-ready': 'Dateiimport abgeschlossen',
        'products-import-result-header': 'Ergebnis',
        'products-import-result-products': '{{productCount}} Einzelleistungen importiert',
        'products-import-result-bundles': '{{bundleCount}} Sammelleistungen importiert',
        'products-import-result-facilities': '{{facilityCount}} Ausstattungen importiert',
        'products-import-result-servicetypes': '{{serviceTypeCount}} Leistungspakete importiert',
        'products-import-start': 'Zurück',
        'graphqlerror-IMPORT_PRODUCTS_FAILED': 'Dateiimport fehlgeschlagen, bitte Dateiinhalt überprüfen: {{- err}}.',

        'products-export-run': 'Dateiexport starten',
        'products-export-ready': 'Dateiexport abgeschlossen',

        // Offers
        'offers-list-open': 'In Bearbeitung',
        'offers-list-approved': 'Bestätigt',
        'offers-list-rejected': 'Abgelehnt',
        'offers-list-closed': 'Abgeschlossen',

        'offers-list-open-waitforhotelapproval': 'Vom Hotel zu bestätigende Kundenanfragen ({{count}})',
        'offers-list-open-editing': 'Vom Hotel in Bearbeitung befindliche Kundenanfragen ({{count}})',
        'offers-list-open-waitforclientapproval': 'Offene Reservierungsbestätigungen ({{count}})',

        'offer-list-page-title': 'Angebote',
        'offers-list-header-createdat': 'Eingelangt',
        'offers-list-header-from-to': 'Anreise/Abreise',
        'offers-list-header-refcode': 'Referenz-Code',
        'offers-list-header-offer': 'Angebot',
        'offers-list-header-sku': 'SKU',
        'offers-list-header-hotel': 'Hotel',
        'offers-list-header-client': 'Kunde',
        'offers-list-header-email': 'E-Mail',
        'offers-list-header-version': 'Version',
        'offers-list-header-status': 'Status',
        'offers-list-header-updatedat': 'Letzte Änderung',
        'offers-list-header-delete-reason': 'Grund der Archivierung',
        'offers-list-edit': 'Bearbeiten',
        'offers-list-view': 'Öffnen',
        'offers-list-show': 'Öffnen',
        'offers-list-delete': 'Archivieren',
        'offers-list-deleted': 'Archiviert',

        'offer-process-create': 'Angebot erzeugen',
        'offer-create-header': 'Neues Angebot',
        'offer-create-step1-header': '1. Hotelauswahl',
        'offer-create-step2-header': '2. Eingabe der Kundendaten',
        'offer-create-step3-header': '3. Eingabe des Leistungszeitraums',
        'offer-create-step4-header': '4. Auswahl des Leistungspakets',
        'offer-create-step5-header': '5. Eingabe der Leistungskonfiguration',
        'offer-create-preday-header': 'Vortag',
        'offer-create-day-header': 'Tag',
        'offer-create-hotel': 'Hotel',
        'offer-create-client-firstname': 'Vorname',
        'offer-create-client-lastname': 'Nachname',
        'offer-create-client-email': 'E-Mail',
        'offer-create-client-phone': 'Telefonnummer',
        'offer-create-client-company': 'Firmenname',
        'offer-create-client-address': 'Adresse',
        'offer-create-client-city': 'Stadt',
        'offer-create-client-zip': 'PLZ',
        'offer-create-client-country': 'Land',
        'offer-create-input-startDate': 'Anreise',
        'offer-create-input-endDate': 'Abreise',
        'offer-create-service-type': 'Leistungspaket',
        'offer-create-input-prevdayGuests': 'Vortagsgäste',
        'offer-create-input-days-dayGuests': 'Tagesgäste',
        'offer-create-input-days-overnightGuests': 'Übernachtungsgäste',
        'offer-create-input-day-noguests': 'Gästeanzahl ist ein Pflichtfeld',
        'offer-create-input-days-occupancy-roomcount': '# Räume',
        'offer-create-input-days-occupancy': 'Belegung',
        'offer-create-input-days-seating': 'Bestuhlung',
        'offer-create-input-days-facility-name': 'Ausstattung',
        'offer-create-input-days-facility-recurring': 'Anzahl / Bezahlung',
        'offer-create-input-days-facility-sku': 'SKU',
        'offer-create-input-days-facility-count': 'Anzahl',
        'offer-create-input-days-facility-count-bycount': 'per Anzahl',
        'offer-create-input-days-facility-count-byguest': 'per Gast',
        'offer-create-input-days-facility-count-byday': 'per Tag',
        'offer-create-input-days-product-name': 'Leistung',
        'offer-create-input-days-product-recurring': 'Anzahl / Bezahlung',
        'offer-create-input-days-product-sku': 'SKU',
        'offer-create-input-days-product-count': 'Anzahl',
        'offer-create-input-days-product-count-bycount': 'per Anzahl',
        'offer-create-input-days-product-count-byguest': 'per Gast',
        'offer-create-input-days-product-count-byguestandday': 'per Gast und Tag',
        'offernew-create': 'Angebot erzeugen',
        'offernew-create-text': 'Bist du sicher, dass du dieses Angebot erzeugen möchtest?',
        'offernew-status-created': 'Angebot wurde erzeugt',
        'offernew-validation-error': 'Bitte Pflichtfelder ausfüllen',
        'offer-input-tab': 'Eingegangene Kundenanfrage',
        'offer-editor-tab': 'Angebotsbearbeitung',
        'offer-confirmation-editor-tab': 'Reservierungsbestätigung',
        'offer-integration-tab': 'PMS Verlauf',
        'offer-advancededitor-tab': 'Expertenmodus',
        'offer-history-tab': 'Verlauf',
        'offer-history-edit': 'Bearbeiten',
        'offer-history-view': 'Angebotsversion anzeigen',
        'offer-reminder-email-sent': 'Erinnerungsmail erfolgreich versendet!',
        'offer-reminder-email-send': 'Erinnerungsmail senden',
        'graphqlerror-NO_PRICELIST': 'Keine Preisliste gefunden',
        'graphqlerror-BAD_PRICELIST': 'Konfigurationsfehler in der Preisliste',
        'graphqlerror-BAD_PRODUCT': 'Produkt nicht gefunden',

        'offer-history-email-sent': 'E-Mail wurde versendet',
        'offer-history-email-failed': 'E-Mail wurde nicht versendet',
        'offer-history-email-subject': 'Betreff',
        'offer-history-email-from': 'Absender',
        'offer-history-email-to': 'Empfänger',
        'offer-history-email-err': 'Fehlermeldung',

        'offer-versions-header-createdat': 'Zeitpunkt',
        'offer-versions-header-version': 'Versionskommentar',
        'offer-versions-header-status': 'Status',
        'offer-versions-header-user': 'Benutzer',

        'offer-input-header-company-data': 'Firmendaten',
        'offer-input-header-billing-data': 'Rechnungsdaten',
        'offer-input-header-name': 'Name',
        'offer-input-header-email': 'E-Mail',
        'offer-input-header-phone': 'Telefonnummer',
        'offer-input-header-address': 'Adresse',
        'offer-input-header-company': 'Firmenname',
        'offer-input-header-start': 'Start',
        'offer-input-header-end': 'Ende',
        'offer-input-header-servicetype': 'Leistungspaket',
        'offer-input-header-comment': 'Kommentar',
        'offer-input-header-preday': 'Vortag',
        'offer-input-header-day': 'Tag',
        'offer-input-header-guests': 'Gäste',
        'offer-input-header-overnightguests': 'Übernachtungsgäste',
        'offer-input-header-rooms': 'Räume',
        'offer-input-header-addons': 'Zusatzleistungen',
        'offer-input-no-addons': 'Keine Zusatzleistungen',

        'offer-occupancy-header-room': 'Raumnummer',
        'offer-occupancy-header-occupancy': 'Dauer',
        'offer-occupancy-header-seating': 'Bestuhlung',

        'offer-addons-header-name': 'Extra',
        'offer-addons-header-count': 'Anzahl',

        'offer-process-approve': 'Angebot bestätigen',
        'offer-process-approve-as-client': 'Angebot bestätigen (als Kunde)',
        'offer-confirmation-process-download': 'Reservierungsbestätigung herunterladen',
        'offer-process-reject': 'Angebot ablehnen',
        'offer-process-edit': 'Angebot ändern',
        'offer-confirmation-process-edit': 'Reservierungsbestätigung ändern',
        'offer-process-delete': 'Angebot löschen',
        'offer-process-download': 'Angebot herunterladen',
        'offer-process-extrefcode': 'Externer Referenzcode',
        'offer-process-extrefdata': 'Externe Referenzdaten',
        'offer-process-openbooking': 'Im PMS öffnen',
        'offer-process-openbooking-nohref': 'Öffnen aus SEM nicht möglich, bitte direkt im PMS öffnen',
        'offer-process-syncbooking': 'Neu übertragen',
        'offer-process-syncbooking-success': 'Neue PMS-Buchung erstellt',
        'offer-process-confirmbooking': 'Im PMS bestätigen',
        'offer-process-confirmbooking-success': 'PMS-Buchung bestätigt',
        'offer-process-deletebooking': 'Im PMS stornieren',
        'offer-process-deletebooking-success': 'PMS-Buchung storniert',
        'offer-status-approved': 'Angebot bestätigt',
        'offer-status-rejected': 'Angebot abgelehnt',

        'offerconfirm-version-save': 'Angebot für später speichern',
        'offerconfirm-version-save-and-send': 'Geändertes Angebot speichern und bestätigen',
        'offerconfirm-version-send': 'Geändertes Angebot bestätigen',

        'offerconfirm-version-save-and-send-title': 'Geändertes Angebot speichern und bestätigen',
        'offerconfirm-version-save-and-send-question': 'Das geänderte Angebot wird gespeichert und eine Bestätigung an den Kunden versendet.',
        'offerconfirm-version-confirmation-save-and-send-title': 'Geänderte Reservierungsbestätigung speichern und bestätigen',
        'offerconfirm-version-confirmation-save-and-send-question': 'Die geänderte Reservierungsbestätigung wird gespeichert und eine Bestätigung an den Kunden versendet.',

        'offerconfirm-version-new': 'Angebot für später speichern',
        'offerconfirm-version-new-and-send': 'Geändertes Angebot speichern und senden',
        'offerconfirm-version-confirmation-new': 'Reservierungsbestätigungg für später speichern',
        'offerconfirm-version-confirmation-new-and-send': 'Geänderte Reservierungsbestätigung speichern und senden',
        'offerconfirm-version-confirmation-send': 'Geänderte Reservierungsbestätigung senden',
        'offerconfirm-version-confirmation-save': 'Geänderte Reservierungsbestätigung speichern',

        'offerconfirm-version-title': 'Geändertes Angebot speichern',
        'offerconfirm-version-and-send-title': 'Geändertes Angebot speichern und bestätigen',
        'offerconfirm-version-and-send-question': 'Das geänderte Angebot wird gespeichert und eine Bestätigung wird an den Kunden versendet.',
        'offerconfirm-version-version': 'Versionskommentar',
        'offerconfirm-version-confirmation-title': 'Geänderte Reservierungsbestätigung speichern',
        'offerconfirm-version-confirmation-and-send-title': 'Geänderte Reservierungsbestätigung speichern und bestätigen',
        'offerconfirm-versionv-and-send-question': 'Die geänderte Reservierungsbestätigung wird gespeichert und eine Bestätigung wird an den Kunden versendet.',

        'offerconfirm-approve-title': 'Angebot bestätigen',
        'offerconfirm-approve-text': 'Soll das Angebot wirklich bestätigt werden?',
        'offerconfirm-approve-as-client-text': 'Sie sind im Begriff das Angebot im Namen des Kunden zu bestätigen. Drücken Sie nur auf Fortsetzen, wenn dies so mit dem Kunden vereinbart wurde.',
        'offerconfirm-delete-title': 'Angebot löschen',
        'offerconfirm-delete-text': 'Soll das Angebot wirklich gelöscht werden?',
        'offerconfirm-reject-title': 'Angebot ablehnen',
        'offerconfirm-reject-text': 'Soll das Angebot wirklich abgelehnt werden?',
        'offerconfirm-delete-reason': 'Grund der Archivierung',
        'offerconfirm-delete-success': 'Angebot wurde archiviert',
        'offerconfirm-approve-success': 'Angebot wurde bestätigt',
        'offerconfirm-reject-success': 'Angebot wurde abgelehnt',

        // Security

        'list-header-space': 'Verwaltungsbereich',
        'field-space': 'Verwaltungsbereich',
        'space-seminargo': 'seminargo',

        'security-tabs-header-users': 'Benutzer',
        'security-tabs-header-spaces': 'Verwaltungsbereiche',

        'users-list-page-title': 'Benutzer',
        'users-list-header-name': 'Name',
        'users-list-header-username': 'Benutzername',
        'users-list-header-email': 'E-Mail-Adresse',
        'users-list-header-isadmin': 'Administratorzugriff',
        'users-list-header-islocked': 'Gesperrt',
        'users-list-header-lastseen': 'Zuletzt gesehen',
        'users-list-header-memberships': 'Weitere Verwaltungsbereiche',
        'users-list-add': 'Benutzer anlegen',

        'user-name': 'Name',
        'user-username': 'Benutzername',
        'user-email': 'E-Mail-Adresse',
        'user-isadmin': 'Administratorzugriff',
        'user-islocked': 'Benutzer ist gesperrt',

        'user-memberships-tab': 'Weitere Verwaltungsbereiche',
        'user-membership-add': 'Weiteren Verwaltungsbereich hinzufügen',
        'user-membership-isadmin': 'Administratorzugriff',
        'user-membership-space': 'Verwaltungsbereich',

        'user-create': 'Benutzer anlegen und Passwort versenden',
        'user-created': 'Benutzer wurde angelegt, das Passwort wurde an die E-Mail-Adresse versendet',
        'user-update': 'Benutzer speichern',
        'user-updated': 'Benutzer gespeichert',
        'user-confirm-islocked': 'Der Benutzer wird sich nicht (mehr) anmelden können - sind Sie sicher ?',
        'user-confirm-islocked-title': 'Benutzer sperren',
        'user-reset': 'Passwort zurücksetzen',
        'user-resetted': 'Das Passwort wurde zurückgesetzt und an die E-Mail-Adresse versendet',
        'user-confirm-reset': 'Das Passwort wird auf einen zufälligen Wert gesetzt und an die E-Mail-Adresse versendet.',
        'user-confirm-reset-title': 'Passwort zurücksetzen',
        'user-sudo': 'Als dieser Benutzer anmelden',
        'user-confirm-sudo':
          'Der aktuelle Benutzer wird auf diesen Benutzer umgestellt. Sie müssen sich danach wieder neu anmelden, um als Root-Benutzer zu arbeiten.',
        'user-confirm-sudo-title': 'Anmeldung als anderer Benutzer',
        'user-sudo-failed': 'Anmeldung als anderer Benutzer fehlgeschlagen',
        'graphqlerror-USER_USERNAME_UNIQUE': 'Der Benutzername {{username}} ist bereits in Verwendung.',
        'graphqlerror-USER_PASSWORD_TOO_SHORT': 'Das gewählte Passwort ist zu kurz (mindestens {{minLength}} Zeichen erforderlich).',

        'spaces-list-page-title': 'Verwaltungsbereiche',
        'spaces-list-header-name': 'Name',
        'spaces-list-header-country': 'Land',
        'spaces-list-header-users': 'Benutzer',
        'spaces-list-header-license': 'Abo',
        'spaces-list-add': 'Verwaltungsbereich provisionieren',

        'space-name': 'Name',
        'space-country': 'Land',
        'space-parentspace': 'Übergeordneter Verwaltungsbereich',
        'space-license': 'Abo',
        'space-update': 'Verwaltungsbereich speichern',
        'space-updated': 'Verwaltungsbereich gespeichert',
        'space-confirm-update': 'Beim Ändern des Verwaltungsbereichs können sich Änderungen in der Berechtigungsstruktur ergeben.',
        'space-confirm-update-title': 'Warnung - Ändern des Verwaltungsbereichs',
        'space-importoffertemplates': 'Standard-Angebotsvorlagen übernehmen',
        'space-offertemplatesimported': 'Standard-Angebotsvorlagen wurden übernommen',
        'space-importemailtemplates': 'Standard-E-Mail-Vorlagen übernehmen',
        'space-emailtemplatesimported': 'Standard-E-Mail-Vorlagen wurden übernommen',
        'space-confirm-importemailtemplates': 'Bestehende E-Mail-Vorlagen werden überschrieben, eventuelle Änderungen daran gehen verloren.',
        'space-confirm-importemailtemplates-title': 'Warnung - Standard-E-Mail-Vorlagen übernehmen',

        'space-settings-ci-logofilename-enabled': 'Logo setzen / überschreiben',
        'space-settings-ci-logofilename': 'Logo',
        'space-settings-ci-logofilename-helper': 'Das Logo kann in den Email-Vorlagen verwendet werden. Platzhalter: {{{ciSettings.logoUrl}}}',
        'space-settings-ci-primarycolor': 'Primärfarbe',
        'space-settings-ci-primarycolor-helper':
          'Die Primärfarbe kann in den Email-Vorlagen verwendet werden. Platzhalter: {{{ciSettings.primaryColor}}}',
        'space-settings-ci-primarycolor-enabled': 'Primärfarbe setzen / überschreiben',
        'space-settings-ci-signature': 'Signatur',
        'space-settings-ci-signature-helper': 'Die Signatur kann in den Email-Vorlagen verwendet werden. Platzhalter: {{{ciSettings.signature}}}',
        'space-settings-ci-signature-enabled': 'Signatur setzen / überschreiben',
        'space-settings-ci-tab': 'Corporate Identity',
        'space-settings-ci-save': 'CI-Einstellungen speichern',
        'space-settings-ci-saved': 'CI-Einstellungen gespeichert',
        'space-settings-ci-page-title': 'Corporate Identity',

        'newspace-page-title': 'Verwaltungsbereich provisionieren',
        'newspace-spaceName': 'Verwaltungsbereich',
        'newspace-spaceCountry': 'Land',
        'newspace-parentspace': 'Übergeordneter Verwaltungsbereich',
        'newspace-hotelName': 'Hotelname',
        'newspace-hotelRefCode': 'Hotel Referenzcode',
        'newspace-import-file': 'Hotel Logo (*.png)',
        'newspace-userid': 'Administratorrechte für Benutzer',
        'newspace-newuser': 'Neuen Benutzer anlegen',
        'newspace-userName': 'Name des Benutzers',
        'newspace-userUsername': 'Benutzername',
        'newspace-userEmail': 'E-Mail-Adresse',
        'newspace-userPassword': 'Passwort setzen',
        'newspace-license': 'Abo',
        'newspace-importDefaultEmailTemplates': 'Standard-E-Mail-Vorlagen einrichten',
        'newspace-importDefaultOfferTemplates': 'Standard-Angebotsvorlagen einrichten',
        'newspace-importDefaultProducts': 'Standard-Leistungspalette einrichten',
        'newspace-importDefaultPriceslist': 'Standard-Preislisten einrichten',
        'newspace-save': 'Verwaltungsbereich provisionieren',
        'newspace-ready': 'Verwaltungsbereich wurde provisioniert und kann nun verwendet werden',
        'newspace-result-email': 'Anmeldung am System mit E-Mail-Adresse (wenn einmalig verwendet)',
        'newspace-result-username': 'Anmeldung am System mit Benutzername',
        'newspace-result-password': 'Anmeldung mit Initial-Passwort',

        'integration-tabs-header-secrets': 'API-Schlüssel',
        'integration-tabs-header-hotels': 'PMS-Verknüpfungen',
        'integration-secrets-list-page-title': 'Schnittstellen',
        'integration-secrets-list-header-name': 'Name',
        'integration-secrets-list-header-system': 'Fremdsystem',
        'integration-secrets-list-header-key': 'API-Schlüssel',
        'integration-secrets-list-add': 'Neuer API-Schlüssel',

        'integration-secret-name': 'Name',
        'integration-secret-system': 'Fremdsystem',
        'integration-apaleo-key': 'Apaleo Client Id',
        'integration-apaleo-secretchange': 'Apaleo Client Secret ändern',
        'integration-apaleo-secret': 'Apaleo Client Secret',
        'integration-elitefo-secret': 'Authentifizierungsschlüssel (von Elitesoftware)',
        'integration-elitefo-secretchange': 'Authentifizierungsschlüssel ändern',
        'integration-elitefo-htuser': 'HTTP-Basic Auth User (festlegen)',
        'integration-elitefo-htpasswd': 'HTTP-Basic Auth Passwort (festlegen)',
        'integration-protelids-pushurl': 'Push URL',
        'integration-protelids-user': 'Auth User (festlegen)',
        'integration-protelids-passwd': 'Auth Passwort (festlegen)',

        'integration-secret-save': 'API-Schlüssel speichern',
        'integration-secret-saved': 'API-Schlüssel gespeichert',
        'integration-secret-confirm-delete-title': 'API-Schlüssel löschen',
        'integration-secret-confirm-delete': 'Soll der API-Schlüssel wirklich gelöscht werden ? Die Verknüpfung mit dem PMS wird daraufhin entfernt.',
        'integration-secret-deleted': 'API-Schlüssel gelöscht',
        'integration-secret-delete': 'API-Schlüssel löschen',

        'integration-secret-test': 'Verbindungstest',
        'integration-secret-test-title': 'Verbindungstest',
        'integration-secret-test-empty': 'Die Verbindung ist technisch funktionstüchtig, aber die Liste der Hotel-Standorts ist leer.',
        'integration-secret-test-result': 'Die Verbindung ist technisch funktionstüchtig. Es wurde eine Liste von Hotel-Standorten ausgelesen:',

        'integration-hotels-list-header-hotel': 'Hotel',
        'integration-hotels-list-header-system': 'Fremdsystem',
        'integration-hotels-list-header-name': 'API-Schlüssel',
        'integration-hotels-list-add': 'Neue PMS-Verknüpfung',

        'integration-hotels-list-page-title': 'PMS-Verknüpfungen',
        'integration-hotel-secret': 'API-Schlüssel',
        'integration-hotel-pmsproperty': 'PMS Hotel',
        'integration-hotel-new': 'Neues SEM Hotel anlegen und verknüpfen',
        'integration-hotel-hotel': 'Verknüpftes Hotel',
        'integration-hotel-extrefcode': 'Externer Referenzcode',
        'integration-hotel-hotel-select': 'Bereits angelegtes SEM Hotel auswählen',
        'integration-hotel-hotel-help':
          'Eine eventuell vorhandene Verknüpfung mit einem anderen SEM Hotel im gleichen Verwaltungsbereich wird gelöst',
        'integration-hotel-space-help':
          'Eine eventuell vorhandene Verknüpfung mit einem anderen SEM Hotel im gleichen Verwaltungsbereich wird gelöst',
        'integration-hotel-pms-product-name': 'PMS-Produkt',
        'integration-hotel-pms-facility-name': 'PMS-Ausstattung',
        'integration-hotel-pms-pricelist': 'PMS-Preisliste',
        'integration-hotel-pms-include': '',

        'integration-hotel-syncproperties': 'Synchronisierung der Hotelstammdaten',
        'integration-hotel-syncproducts': 'Synchronisierung des Produktkatalogs',
        'integration-hotel-syncprices': 'Synchronisierung der Preislisten',
        'integration-hotel-syncpricesdays': 'Anzahl der Tage',
        'integration-hotel-syncavailability': 'Synchronisierung der Verfügbarkeiten',
        'integration-hotel-syncavailabilitydays': 'Anzahl der Tage',
        'integration-hotel-directbooking': 'Direktbuchungen',

        'integration-hotel-log-tab': 'Datenabgleich',
        'integration-hotel-log-status': 'Status',
        'integration-hotel-log-action': 'Aktion(en)',
        'integration-hotel-log-trigger': 'Auslöser',
        'integration-hotel-log-offer': 'Buchung',
        'integration-hotel-log-startdate': 'Beginn',
        'integration-hotel-log-enddate': 'Ende',
        'integration-hotel-log-showresult': 'Protokoll anzeigen',
        'integration-hotel-log-showresult-title': 'Protokoll',

        'integration-hotel-create': 'PMS-Verknüpfung anlegen',
        'integration-hotel-created': 'PMS-Verknüfpung wurde angelegt',
        'integration-hotel-save': 'PMS-Verknüpfung speichern',
        'integration-hotel-saved': 'PMS-Verknüpfung gespeichert',
        'integration-hotel-sync': 'Datenabgleich starten',
        'integration-hotel-sync-triggered': 'Datenabgleich gestartet',

        'integration-hotel-confirm-delete-title': 'PMS-Verknüpfung löschen',
        'integration-hotel-confirm-delete': 'Soll die PMS-Verknüpfung mit dem Hotel wirklich gelöscht werden ?',
        'integration-hotel-deleted': 'PMS-Verknüpfung gelöscht',
        'integration-hotel-delete': 'PMS-Verknüpfung löschen',

        // Generic / Reusable

        'generic-no-option': 'Keine Auswahlmöglichkeit vorhanden',
        'generic-selection-empty': 'Keine Auswahl',
        'generic-unsaved-changes': 'Deine Änderungen werden nicht gespeichert. Fortfahren ?',

        // Login / Signin
        'login-title': 'Anmeldung bei SEM',
        'login-email': 'E-Mail oder Benutzername',
        'login-password': 'Passwort',
        'login-login': 'Anmelden',
        'login-ready': 'Angemeldet',
        'login-failed': 'Anmeldung fehlgeschlagen, bitte Passwort überprüfen',
        'login-no-account': 'Noch kein Konto? Registrieren',
        'login-forgot-password': 'SEM-Passwort vergessen?',
        'login-forgot-send': 'Passwort zurücksetzen',
        'login-forgot-ready':
          'Falls ein passender Benutzer gefunden werden konnte, erhalten Sie eine E-Mail mit einer Anleitung zum Zurücksetzen des Passworts.',
        'login-reset-title': 'Neues SEM-Passwort',
        'login-reset': 'Neues Passwort setzen',
        'login-reset-ready': 'Das Passwort wurde zurückgesetzt. Sie können sich jetzt bei SEM mit dem neuen Passwort anmelden.',
        'login-reset-failed': 'Das Passwort konnte nicht zurückgesetzt werden',

        'my-account': 'Mein Konto',
        logout: 'Abmelden',

        'main-filter-select': 'Hotel/Verwaltungsbereich auswählen',
        'main-filter-select-hotel': 'Hotel auswählen',
        'main-filter-empty': 'Alle anzeigen',
        'main-usermenu-account': 'Mein Konto',
        'main-usermenu-logout': 'Abmelden',

        'me-page-title': 'Mein Konto',
        'me-password-header': 'Passwort ändern',
        'me-password-oldpassword': 'Aktuelles Passwort',
        'me-password-newpassword1': 'Neues Passwort',
        'me-password-newpassword2': 'Neues Passwort Wiederholen',
        'me-password-not-matching': 'Die Passwörter stimmen nicht überein',
        'me-confirm-password':
          'Das neue Passwort wird sofort aktiv, Sie werden zur Anmeldeseite weitergeleitet und das aktuelle Passwort kann nicht mehr zur Anmeldung verwendet werden',
        'me-confirm-password-title': 'Passwort ändern',
        'me-password': 'Passwort ändern',
        'me-password-changed': 'Das Passwort wurde geändert',
        'me-password-notchanged': 'Das Passwort wurde nicht geändert, bitte überprüfen Sie das aktuelle Passwort',

        'media-selection-dialog-title': 'Medienauswahl',
        'contentmedia-name': 'Name',
        'contentmedia-type': 'Typ',

        'refresh': 'Aktualisieren',

        // Error page
        'error-generic': 'Die Datenbankverbindung wurde unterbrochen.',
        'error-refresh': 'Verbindung neu laden',
        'error-back-to-start': 'Zurück zur Startseite',

        'sem-version-dialog-title': 'Versionsinformation',
      },
    },
  },
};
