import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

interface CloseSnackbarAction {
  payload: {
    key: string;
    dismissAll?: boolean;
  };
}

export interface EnqueueSnackbarArgs {
  message: string;
  key?: string;
  options?: any;
}

export interface CloseSnackbarArgs {
  key: string;
  dismissAll?: boolean;
}

interface Notification {
  key: string;
  message: string;
  dismissed?: boolean;
}

interface EnqueueSnackbarActionPayload {
  notification: Notification;
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { notifications: [] as Notification[] },
  reducers: {
    [ENQUEUE_SNACKBAR]: {
      reducer: (state, { payload }: PayloadAction<Notification>) => {
        state.notifications.push(payload);
      },
      prepare(notification) {
        const payload = {
          ...notification,
          key: new Date().getTime() + Math.random(),
        };
        return { payload };
      },
    },
    [CLOSE_SNACKBAR]: {
      reducer: (state, action: CloseSnackbarAction) => {
        const { payload } = action;
        state.notifications = state.notifications.map(notification => {
          const shouldDismiss = payload.dismissAll || notification.key === payload.key;
          return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
        });
      },
      prepare: key => ({ payload: { key, dismissAll: !key } }),
    },
    [REMOVE_SNACKBAR]: (state, { payload }) => {
      state.notifications = state.notifications.filter(notification => notification && notification.key !== payload);
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;

export default snackbarSlice;
