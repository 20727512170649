import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, CircularProgress } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';

import SimpleTable from 'components/table/SimpleTable';

import { TAXTYPES_LIST_QUERY } from './gql';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';
import { ErrorAlert } from 'pages/error';

export default function TaxTypesList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(TAXTYPES_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });

  return (
    <>
      <Helmet>
        <title>{i18next.t('taxtypes-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('taxtypes-list-header-name'),
                  i18next.t('taxtypes-list-header-sequence'),
                  ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                ]}
                rows={data.listTaxTypes.map(row => [
                  <Link to={`/pricelists/taxtypes/${row.id}`}>{row.name}</Link>,
                  row.sequence,
                  ...(user.isSingleSpace ? [] : [row.space?.name || i18next.t('space-seminargo')]),
                ])}
              />
            </Grid>
            {hasAnyAdminSpace(user) && (
              <Grid item xs={12}>
                <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/pricelists/taxtypes/_create')}>
                  {i18next.t('taxtypes-list-add')}
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
