import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const PRODUCTDETAIL_LIST_QUERY = gql(`
  query ListContentProductForSKU($sku: String) {
    listContentProductDetails(sku: $sku) {
      id
      sku
      position
      hotel {
        id
        name
      }
      space { ...SpaceShort }
      texts {
        id
        language
        sequence
        text
      }
    }
  }
`);

export const CONTENTPRODUCTS_LIST_SHORT_FRAGMENT = gql(`
  fragment ContentProductDetailListShort on ContentProductDetailListOutput {
    id
    sku
    position
    hotel {
      id
      name
    }
    product {
      id
      name
    }
    productBundle {
      id
      name
    }
    serviceType {
      id
      name
    }
    facility {
      id
      name
    }
    space { ...SpaceShort }
  }
`);

export const CONTENTPRODUCTS_LIST_VIEW_FRAGMENT = gql(`
  fragment ContentProductDetailListView on ContentProductDetailListOutput {
    id
    sku
    position
    hotel {
      id
      name
    }
    texts {
      id
      language
      sequence
      text
    }
    product {
      id
      name
    }
    productBundle {
      id
      name
    }
    serviceType {
      id
      name
    }
    facility {
      id
      name
    }
    space { ...SpaceShort }
  }
`);

export const CONTENTPRODUCTS_LIST_QUERY = gql(`
  query ListContentProductDetails($spaceId: Int, $hotelId: Int, $sku: String, $position: EContentProductDetailPosition, $skip: Int, $take: Int) {
    listContentProductDetails(spaceId: $spaceId, hotelId: $hotelId, sku: $sku, position: $position, skip: $skip, take: $take) {
      ...ContentProductDetailListShort
    }
  }
`);

export const CONTENTPRODUCT_VIEW_QUERY = gql(`
  query ViewContentProductDetails($id: Int!) {
    viewContentProductDetails(id: $id) {
      ...ContentProductDetailListView
    }
  }
`);

export const CREATE_CONTENTPRODUCT_MUTATION = gql(`
  mutation CreateContentProduct($spaceId: Int!, $data: ContentProductDetailCreateInput!) {
    createContentProduct(spaceId: $spaceId, data: $data) {
      ...ContentProductDetailListView
    }
  }
`);

export const UPDATE_CONTENTPRODUCT_MUTATION = gql(`
  mutation UpdateContentProduct($id: Int!, $data: ContentProductDetailInput!) {
    updateContentProduct(id: $id, data: $data) {
      ...ContentProductDetailListView
    }
  }
`);

export const EVICT_CONTENTPRODUCTS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listContentProductDetails' });
  cache.gc();
};

export const REFETCH_CONTENTPRODUCTS_QUERIES = (contentProductId?: number | undefined) => [
  ...(contentProductId
    ? [
        {
          query: CONTENTPRODUCT_VIEW_QUERY,
          variables: { id: contentProductId },
        },
      ]
    : []),
];

export const EMAILTEMPLATES_LIST_SHORT_FRAGMENT = gql(`
  fragment EmailTemplatesListShort on EmailTemplateListOutput {
    id
    createdAt
    updatedAt
    usage
    subject
    language
    space { ...SpaceShort }
  }
`);

export const EMAILTEMPLATES_LIST_VIEW_FRAGMENT = gql(`
  fragment EmailTemplatesListView on EmailTemplateListOutput {
    id
    createdAt
    updatedAt
    usage
    subject
    body
    language
    space { ...SpaceShort }
  }
`);

export const EMAILTEMPLATES_LIST_QUERY = gql(`
  query ListEmailTemplates($spaceId: Int, $skip: Int, $take: Int) {
    listEmailTemplates(spaceId: $spaceId, skip: $skip, take: $take) {
      ...EmailTemplatesListShort
    }
  }
`);

export const EMAILTEMPLATE_VIEW_QUERY = gql(`
  query ViewEmailTemplate($id: Int!) {
    viewEmailTemplate(id: $id) {
      ...EmailTemplatesListView
    }
  }
`);

export const CREATE_EMAILTEMPLATE_MUTATION = gql(`
  mutation CreateEmailTemplate($spaceId: Int!, $data: EmailTemplateCreateInput!) {
    createEmailTemplate(spaceId: $spaceId, data: $data) {
      ...EmailTemplatesListView
    }
  }
`);

export const UPDATE_EMAILTEMPLATE_MUTATION = gql(`
  mutation UpdateEmailTemplate($id: Int!, $data: EmailTemplateInput!) {
    updateEmailTemplate(id: $id, data: $data) {
      ...EmailTemplatesListView
    }
  }
`);

export const PREVIEW_EMAILTEMPLATE_QUERY = gql(`
  query PreviewEmailTemplate($spaceId: Int!, $usage: EContentEmailTemplate!, $subject: String!, $body: String!) {
    previewEmailTemplate(spaceId: $spaceId, usage: $usage, subject: $subject, body: $body) {
      subject
      body
    }
  }
`);

export const DELETE_EMAILTEMPLATE_MUTATION = gql(`
  mutation DeleteEmailTemplate($id: Int!) {
    deleteEmailTemplate(id: $id)
  }
`);

export const COPY_EMAILTEMPLATE_MUTATION = gql(`
  mutation CopyEmailTemplate($id: Int!) {
    copyEmailTemplate(id: $id) {
      ...EmailTemplatesListView
    }
  }
`);

export const IMPORT_EMAILTEMPLATE_MUTATION = gql(`
  mutation ImportEmailTemplates($spaceId: Int!, $jsonText: String!) {
    importEmailTemplates(spaceId: $spaceId, jsonText: $jsonText) {
      emailTemplates {
        ...EmailTemplatesListShort
      }
    }
  }
`);

export const EXPORT_EMAILTEMPLATE_MUTATION = gql(`
  mutation ExportEmailTemplates($spaceId: Int, $id: Int) {
    exportEmailTemplates(spaceId: $spaceId, id: $id)
  }
`);

export const EVICT_EMAILTEMPLATES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listEmailTemplates' });
  cache.gc();
};

export const REFETCH_EMAILTEMPLATES_QUERIES = (emailTemplateId?: number | undefined) => [
  ...(emailTemplateId
    ? [
        {
          query: EMAILTEMPLATE_VIEW_QUERY,
          variables: { id: emailTemplateId },
        },
      ]
    : []),
];

export const CONTENTMEDIA_LIST_SHORT_FRAGMENT = gql(`
  fragment ContentMediaListShort on ContentMediaListOutput {
    id
    createdAt
    updatedAt
    name
    mimeType
    previewUrl
    space { ...SpaceShort }
  }
`);

export const CONTENTMEDIAS_LIST_QUERY = gql(`
  query ListContentMedias($spaceId: Int, $skip: Int, $take: Int) {
    listContentMedia(spaceId: $spaceId, skip: $skip, take: $take) {
      ...ContentMediaListShort
    }
  }
`);

export const UPLOAD_CONTENTMEDIA_MUTATION = gql(`
  mutation UploadMedia($spaceId: Int!, $filename: String!, $mimeType: String!, $base64: String!) {
    uploadMedia(spaceId: $spaceId, filename: $filename, mimeType: $mimeType, base64: $base64) {
      ...ContentMediaListShort

    }
  }
`);

export const DELETE_CONTENTMEDIA_MUTATION = gql(`
  mutation DeleteMedia($id: Int!) {
    deleteMedia(id: $id)
  }
`);

export const IMPORT_CONTENTMEDIA_MUTATION = gql(`
  mutation ImportMedias($spaceId: Int!, $jsonText: String!) {
    importMedias(spaceId: $spaceId, jsonText: $jsonText) {
      medias {
        ...ContentMediaListShort
      }
    }
  }
`);

export const EXPORT_CONTENTMEDIA_MUTATION = gql(`
  mutation ExportMedias($spaceId: Int, $id: Int) {
    exportMedias(spaceId: $spaceId, id: $id)
  }
`);

export const EVICT_CONTENTMEDIA_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listContentMedia' });
  cache.gc();
};

export const REFETCH_CONTENTMEDIA_QUERIES = (contentMedia?: number | undefined) => [
  {
    query: CONTENTMEDIAS_LIST_QUERY,
  },
];

export const OFFERTEMPLATES_LIST_SHORT_FRAGMENT = gql(`
  fragment OfferTemplateListShort on ContentOfferTemplateListOutput {
    id
    createdAt
    updatedAt    
    name
    title
    isPublished
    type
    space { ...SpaceShort }
  }
`);

export const OFFERTEMPLATES_LIST_VIEW_FRAGMENT = gql(`
  fragment OfferTemplateListView on ContentOfferTemplateListOutput {
    id
    createdAt
    updatedAt
    name
    title
    filename
    isPublished
    type
    includeEmptyLineItems
    space { ...SpaceShort }
    cssStyles
    marginTop
    marginBottom
    marginLeft
    marginRight
    headerTemplate
    footerTemplate
    contentBlocks {
      id
      template
      sequence
      removable
      type
      blockDescription
      formFields {
        id
        sequence
        type
        label
        name
        defaultValue
        placeholder
        required
        helpText
      }
    }
  }
`);

export const OFFERTEMPLATES_LIST_QUERY = gql(`
  query ListOfferTemplates($spaceId: Int, $skip: Int, $take: Int) {
    listOfferTemplates(spaceId: $spaceId, skip: $skip, take: $take) {
      ...OfferTemplateListShort
    }
  }
`);

export const OFFERTEMPLATE_VIEW_QUERY = gql(`
  query ViewOfferTemplate($id: Int!) {
    viewOfferTemplate(id: $id) {
      ...OfferTemplateListView
    }
  }
`);

export const UPDATE_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation UpdateContentOfferTemplate($id: Int!, $data: ContentOfferTemplateDetailInput!) {
    updateContentOfferTemplate(id: $id, data: $data) {
      ...OfferTemplateListView
    }
  }
`);

export const CREATE_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation CreateContentOfferTemplate($spaceId: Int!, $data: ContentOfferTemplateDetailCreateInput!) {
    createContentOfferTemplate(spaceId: $spaceId, data: $data) {
      ...OfferTemplateListView
    }
  }
`);

export const DELETE_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation DeleteOfferTemplate($id: Int!) {
    deleteOfferTemplate(id: $id)
  }
`);

export const COPY_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation CopyOfferTemplate($id: Int!) {
    copyOfferTemplate(id: $id) {
      ...OfferTemplateListView
    }
  }
`);

export const IMPORT_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation ImportOfferTemplates($spaceId: Int!, $jsonText: String!) {
    importOfferTemplates(spaceId: $spaceId, jsonText: $jsonText) {
      offerTemplates {
        ...OfferTemplateListView
      }
    }
  }
`);

export const EXPORT_CONTENTOFFERTEMPLATE_MUTATION = gql(`
  mutation ExportOfferTemplates($spaceId: Int, $id: Int) {
    exportOfferTemplates(spaceId: $spaceId, id: $id)
  }
`);

export const EVICT_OFFERTEMPLATES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listOfferTemplates' });
  cache.gc();
};

export const REFETCH_OFFERTEMPLATES_QUERIES = (offerTemplateId?: number | undefined) => [
  ...(offerTemplateId
    ? [
        {
          query: OFFERTEMPLATE_VIEW_QUERY,
          variables: { id: offerTemplateId },
        },
      ]
    : []),
];
