import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, IconButton, Tooltip, Grid, CircularProgress, MenuItem, Select } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import PlusIcon from '@mui/icons-material/AddShoppingCart';
import MinusIcon from '@mui/icons-material/RemoveShoppingCart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputDropdown, SimpleDropdown, buildMenuItemsFromOptions } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { TaxComponentSelectionInput } from 'components/tax/TaxComponentSelectionInput';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { buildGroupedSpaceSelectionOptions } from 'components/security/GroupedSpaceSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpace, filterForSelectableRecords } from 'helper/security';
import { filterSelector } from 'helper/filter';

import ProductDetailsFlat, {
  ProductDetailsFlatContentSchema,
  ProductDetailsFlatFromTypeToSchema,
  ProductDetailsFlatFromSchemaToType,
} from '../../content/products/productdetailsflat_embedded';
import PriceTableView from '../pricetable_embedded';
import { AvailabilityEmbeddedTable } from '../../availability/availability';

import {
  PRODUCTBUNDLE_VIEW_QUERY,
  PRODUCTS_LIST_QUERY,
  UPDATE_PRODUCTBUNDLE_MUTATION,
  CREATE_PRODUCTBUNDLE_MUTATION,
  DELETE_PRODUCTBUNDLE_MUTATION,
  SET_PRODUCTDETAILS_MUTATION,
  COPY_PRODUCTBUNDLE_MUTATION,
  REFETCH_PRODUCTBUNDLES_QUERIES,
  EVICT_PRODUCTBUNDLES_QUERIES,
} from '../gql';
import { TAXTYPES_LIST_FULL_QUERY } from '../../prices/gql';
import {
  TaxTypeListOutput,
  ProductListOutput,
  ProductBundleListOutput,
  EProductPayment,
  EProductLookupCode,
  ProductBundleComponentListOutput,
} from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { isProductionRelease } from 'helper/deployment';
import { ISpace } from 'types';
import { RedirectError } from 'pages/error';

interface ProductBundleProps {
  id: number;
}
interface ProductBundleCreateProps {}
interface ProductBundleFormProps {
  data: ProductBundleListOutput;
  products: ProductListOutput[];
  taxTypesData: TaxTypeListOutput[];
}

const bundleItemsSchema = yup.object().shape({
  includedCount: yup.number().min(0).required().label(i18next.t('productbundle-item-includedcount')),
  isOptional: yup.boolean().required(),
  productId: yup.number().required(),
});

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  name: yup.string().required().label(i18next.t('productbundle-name')),
  extRefCode: yup.string().nullable(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  sku: yup.string(),
  recurring: yup.string().required(),
  lookupCode: yup.string().nullable(),
  components: yup.array().required().min(1).of(yup.number().required()).label(i18next.t('productbundle-components')),
  bundleItems: yup.array().required().of(bundleItemsSchema),
  content: yup.array().required().of(ProductDetailsFlatContentSchema),
});

function ProductBundleForm(props: ProductBundleFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewQuery] = useLazyQuery(PRODUCTBUNDLE_VIEW_QUERY);
  const [updateMutateFunction] = useMutation(UPDATE_PRODUCTBUNDLE_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_PRODUCTBUNDLE_MUTATION);
  const [setTextMutateFunction] = useMutation(SET_PRODUCTDETAILS_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);
  const canEditText = user.isAdmin;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/products/bundles/${createdId}`);
  }, [createdId]);

  const [selectedSpace, setSelectedSpace] = useState<ISpace | null>(props.data.space);

  type ProductBundleFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: ProductBundleListOutput): ProductBundleFormType => ({
    ...obj,
    isCreate: props.data.id > 0 ? false : true,
    spaceId: obj.space.id,
    lookupCode: obj.lookupCode || undefined,
    components: obj.components.map(tt => tt.taxType.id),
    bundleItems: obj.bundleItems.map(bi => ({
      isOptional: bi.isOptional,
      includedCount: bi.includedCount,
      productId: bi.product.id,
    })),
    content: obj.content.map(ProductDetailsFlatFromTypeToSchema),
    extRefCode: [obj.extRefCode, obj.extPricelistRefCode].filter(r => r).join(' / '),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient(), products: props.products },
    defaultValues: toFormSchema((props.data || {}) as ProductBundleListOutput),
  });

  const {
    fields: bundleItemsField,
    append: bundleItemAppend,
    remove: bundleItemRemove,
  } = useFieldArray({
    control,
    name: 'bundleItems',
  });
  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({
    control,
    name: 'content',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: ProductBundleFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              recurring: values.recurring as EProductPayment,
              bundleItems: values.bundleItems.map(bi => ({
                productId: bi.productId,
                includedCount: bi.includedCount,
                isOptional: !!bi.isOptional,
              })),
              components: values.components.map(c => ({ taxTypeId: c })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
            },
          },
          update: cache => EVICT_PRODUCTBUNDLES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRODUCTBUNDLES_QUERIES(props.data.id, values.sku),
        });
        reset(toFormSchema((res.data!.updateProductBundle || {}) as ProductBundleListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              lookupCode: values.lookupCode ? (values.lookupCode as EProductLookupCode) : null,
              recurring: values.recurring as EProductPayment,
              bundleItems: values.bundleItems.map(bi => ({
                productId: bi.productId,
                includedCount: bi.includedCount,
                isOptional: !!bi.isOptional,
              })),
              components: values.components.map(c => ({ taxTypeId: c })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType),
            },
          },
          update: cache => EVICT_PRODUCTBUNDLES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRODUCTBUNDLES_QUERIES(),
        });
        reset(toFormSchema((res.data!.createProductBundle || {}) as ProductBundleListOutput));
        setCreatedId(res.data!.createProductBundle.id);
      }
      dispatchMessage(dispatch, i18next.t('productbundle-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onSubmitText = async (values: ProductBundleFormType) => {
    if (props.data.id <= 0 || !canEditText) return;
    try {
      const res = await setTextMutateFunction({
        variables: {
          sku: props.data.sku,
          content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
        },
        update: cache => EVICT_PRODUCTBUNDLES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRODUCTBUNDLES_QUERIES(props.data.id, values.sku),
      });
      const bundle = await viewQuery({ variables: { id: props.data.id } });
      reset(toFormSchema((bundle.data!.viewProductBundle || {}) as ProductBundleListOutput));
      dispatchMessage(dispatch, i18next.t('productbundle-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const initialTabSelected = (location.hash === '#texts' && 2) || (location.hash === '#prices' && 1) || 0;

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('productbundles-list-page-title')} {props.data.id > 0 ? props.data.name : ''}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="name" control={control} label={i18next.t('productbundle-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            onSpaceSelected={space => setSelectedSpace(space)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="recurring"
            control={control}
            label={i18next.t('productbundle-recurring')}
            options={Object.keys(EProductPayment).map(p => ({
              value: p,
              label: `${i18next.t('enums-EProductPayment-' + p)}`,
            }))}
            required
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="sku" control={control} label={i18next.t('productbundle-sku')} disabled />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={3}>
          <FormInputDropdown
            name="lookupCode"
            control={control}
            label={i18next.t('productbundle-lookupcode')}
            options={[
              {
                label: <em>{i18next.t('generic-selection-empty')}</em>,
                value: undefined,
              },
              ...Object.keys(EProductLookupCode).map(p => ({
                value: p,
                label: `${p}`,
              })),
            ]}
            disabled={!canEdit || props.data.id > 0}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormInputText name="extRefCode" control={control} label={i18next.t('productbundle-extrefcode')} disabled />
        </Grid>
        <Grid item xs={12}>
          <TaxComponentSelectionInput
            name="components"
            control={control}
            label={i18next.t('productbundle-components')}
            country={selectedSpace ? selectedSpace.country : null}
            taxTypesData={props.taxTypesData}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            initialSelected={initialTabSelected}
            headers={[
              i18next.t('productbundle-items-tab'),
              i18next.t('productbundle-prices-tab'),
              i18next.t('productbundle-text-tab'),
              i18next.t('productbundle-availability-tab'),
            ]}
            icons={[
              validationErrors.bundleItems ? <ErrorIcon /> : undefined,
              undefined,
              validationErrors.content ? <ErrorIcon /> : undefined,
              undefined,
            ]}
            hidden={[false, false, false, isProductionRelease()]}
            disabled={props.data.id > 0 ? [false, false, false, false] : [false, true, true, true]}
            tabs={[
              <>
                <SimpleTable
                  headers={[i18next.t('productbundle-item-product'), '', '', '']}
                  rows={bundleItemsField.map((field, index) => {
                    const product = props.products.find(p => p.id === field.productId);

                    return [
                      <Link to={`/products/products/${field.productId}`}>
                        {product?.name}
                        {product?.extRefCode && ` (${product?.extRefCode})`}
                      </Link>,
                      <FormInputNumber
                        key={`${field.id}.includedCount`}
                        name={`bundleItems.${index}.includedCount`}
                        control={control}
                        label={i18next.t('productbundle-item-includedcount')}
                        textFieldProps={{ fullWidth: false }}
                        required
                        disabled={!canEdit}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isOptional`}
                        name={`bundleItems.${index}.isOptional`}
                        control={control}
                        label={i18next.t('productbundle-item-isoptional')}
                        disabled={!canEdit}
                      />,
                      props.products.find(p => p.id === field.productId)?.isDeduction ? <MinusIcon /> : <PlusIcon />,
                      canEdit && (
                        <IconButton onClick={() => bundleItemRemove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      ),
                    ];
                  })}
                />
                {canEdit && (
                  <Select
                    value={0}
                    displayEmpty
                    onChange={event =>
                      event.target.value &&
                      bundleItemAppend({
                        includedCount: 1,
                        isOptional: false,
                        productId: event.target.value as number,
                      })
                    }
                  >
                    {buildMenuItemsFromOptions(
                      buildGroupedSpaceSelectionOptions(
                        user,
                        filterForSelectableRecords(user, props.products.filter(p => getValues('bundleItems').findIndex(bi => bi.productId === p.id) < 0), getValues('spaceId'), false, false),
                        `${i18next.t('productbundle-item-add')}`,
                        0,
                      ),
                    )}
                  </Select>
                )}
              </>,
              <PriceTableView prices={props.data.prices} sku={props.data.sku} />,
              <ProductDetailsFlat
                control={control}
                baseSpaceId={watch('spaceId')}
                fields={contentFields}
                append={contentAppend}
                remove={contentRemove}
                watch={(index, field) => watch(`content.${index}.${field}`)}
                validationErrors={validationErrors}
              />,
              props.data.id > 0 && !isProductionRelease() ? <AvailabilityEmbeddedTable bundleId={props.data.id} /> : null,
            ]}
          />
        </Grid>
        {canEdit && (
          <Grid item xs={12}>
            {showValidationAlert(validationErrors)}
          </Grid>
        )}
        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('productbundle-save')}
            </Button>
          )}
          {props.data.id > 0 && !canEdit && canEditText && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={!isDirty || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitText)();
                }
              }}
            >
              {i18next.t('productbundle-save-text')}
            </Button>
          )}
          {canEdit && props.data.id > 0 && <ProductBundleDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <ProductBundleCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      </Grid>
    </>
  );
}

export default function ProductBundle(props: ProductBundleProps) {
  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);
  const bundleQuery = useQuery(PRODUCTBUNDLE_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = productsQuery.loading || taxTypesQuery.loading || bundleQuery.loading;
  const error = productsQuery.error || taxTypesQuery.error || bundleQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ProductBundleForm
        data={bundleQuery.data!.viewProductBundle! as ProductBundleListOutput}
        products={productsQuery.data!.listProducts as ProductListOutput[]}
        taxTypesData={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
}

export function ProductBundleCreate(props: ProductBundleCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);

  const loading = taxTypesQuery.loading || productsQuery.loading;
  const error = taxTypesQuery.error || productsQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ProductBundleForm
        data={{
          id: -1,
          name: '',
          sku: '',
          recurring: EProductPayment.BYGUESTANDDAY,
          bundleItems: [],
          prices: [],
          content: [],
          components: [
            {
              id: -1,
              taxType: taxTypesQuery.data!.listTaxTypes.find(t => t.lookupCode === 'DEFAULT2') as TaxTypeListOutput,
            } as ProductBundleComponentListOutput,
          ],
          space: initialSpace(user, filter) as any,
        }}
        products={productsQuery.data!.listProducts as ProductListOutput[]}
        taxTypesData={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
}

interface ProductBundleCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function ProductBundleCopyButton(props: ProductBundleCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isRoot || (user.isAdmin && user.space);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [spaceId, setSpaceId] = useState<number>(props.spaceId);

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_PRODUCTBUNDLE_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id, spaceId: spaceId },
        update: cache => EVICT_PRODUCTBUNDLES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRODUCTBUNDLES_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('productbundle-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <>
        <IconButton disabled={!canEdit || copyMutateLoading} onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}>
          <Tooltip title={i18next.t('productbundle-copy')}>
            <ContentCopyIcon />
          </Tooltip>
        </IconButton>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('productbundle-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('productbundle-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          disabled={!canEdit || copyMutateLoading}
          startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
          onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}
        >
          {i18next.t('productbundle-copy')}
        </Button>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('productbundle-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('productbundle-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  }
}

interface ProductBundleDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function ProductBundleDeleteButton(props: ProductBundleDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_PRODUCTBUNDLE_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('productbundle-confirm-delete')}
      confirmationTitle={i18next.t('productbundle-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_PRODUCTBUNDLES_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_PRODUCTBUNDLES_QUERIES(),
          });
          navigate('/products/bundles');
          dispatchMessage(dispatch, i18next.t('productbundle-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('productbundle-delete')}
    </ConfirmationButton>
  );
}
