import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Grid, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import PagedTable from 'components/table/PagedTable';
import SimpleAccordion from 'components/SimpleAccordion';
import { FormatDateRange } from 'components/DateTime';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PRICELISTS_LIST_QUERY } from './gql';

import { PriceListCopyButton, PriceListDeleteButton } from './pricelist';

export default function PriceListsList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [publishedQuery, { data: publishedData, loading: publishedLoading, error: publishedError }] = useLazyQuery(PRICELISTS_LIST_QUERY);
  const [unpublishedQuery, { data: unpublishedData, loading: unpublishedLoading, error: unpublishedError }] = useLazyQuery(PRICELISTS_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('pricelists-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SimpleAccordion header={`${i18next.t('pricelists-list-published')}`} autoOpen={true}>
            <PagedTable
              filterKey={`${JSON.stringify(filter)}`}
              refetchPage={(skip, take) =>
                publishedQuery({
                  variables: {
                    spaceId: (filter && filter.spaceId) || null,
                    hotelId: (filter && filter.hotelId) || null,
                    isPublished: true,
                    skip,
                    take,
                  },
                })
              }
              rowsLoading={publishedLoading}
              rowsErr={publishedError}
              headers={[
                i18next.t('pricelists-list-header-name'),
                i18next.t('pricelists-list-header-refcode'),
                i18next.t('pricelists-list-header-version'),
                i18next.t('pricelists-list-header-validity'),
                i18next.t('pricelists-list-header-ispublished'),
                i18next.t('pricelists-list-header-isdefault'),
                i18next.t('pricelists-list-header-hotel'),
                ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                '',
              ]}
              rows={(publishedData?.listPriceLists || []).map(row => [
                <Link to={`/pricelists/pricelists/${row.id}`}>{row.name}</Link>,
                row.refCode,
                row.version,
                row.validity.length === 0 ? (
                  <FormatDateRange from={null} to={null} />
                ) : (
                  row.validity.map((v, index) => (
                    <React.Fragment key={index}>
                      <FormatDateRange from={v.validFrom} to={v.validTo} />
                      <br />
                    </React.Fragment>
                  ))
                ),
                row.isPublished ? <CheckIcon /> : null,
                row.isDefault ? <CheckIcon /> : null,
                row.hotel ? <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link> : '',
                ...(user.isSingleSpace ? [] : [row.space.name]),
                <ButtonGroup>
                  <PriceListCopyButton id={row.id} spaceId={row.space.id} icon />
                  <PriceListDeleteButton id={row.id} spaceId={row.space.id} icon />
                </ButtonGroup>,
              ])}
            />
          </SimpleAccordion>
        </Grid>
        <Grid item xs={12}>
          {hasAnyAdminSpace(user) && (
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/pricelists/pricelists/_create')}>
              {i18next.t('pricelists-list-add')}
            </Button>
          )}
          {user.isAdmin && (
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<ImportExportIcon />} onClick={() => navigate('/pricelists/import')}>
              {i18next.t('pricelists-list-import')}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <SimpleAccordion header={`${i18next.t('pricelists-list-notpublished')}`} autoOpen={true}>
            <PagedTable
              filterKey={`${JSON.stringify(filter)}`}
              refetchPage={(skip, take) =>
                unpublishedQuery({
                  variables: {
                    spaceId: (filter && filter.spaceId) || null,
                    hotelId: (filter && filter.hotelId) || null,
                    isPublished: false,
                    skip,
                    take,
                  },
                })
              }
              rowsLoading={unpublishedLoading}
              rowsErr={unpublishedError}
              headers={[
                i18next.t('pricelists-list-header-name'),
                i18next.t('pricelists-list-header-refcode'),
                i18next.t('pricelists-list-header-version'),
                i18next.t('pricelists-list-header-validity'),
                i18next.t('pricelists-list-header-ispublished'),
                i18next.t('pricelists-list-header-isdefault'),
                i18next.t('pricelists-list-header-hotel'),
                ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                '',
              ]}
              rows={(unpublishedData?.listPriceLists || []).map(row => [
                <Link to={`/pricelists/pricelists/${row.id}`}>{row.name}</Link>,
                row.refCode,
                row.version,
                row.validity.length === 0 ? (
                  <FormatDateRange from={null} to={null} />
                ) : (
                  row.validity.map((v, index) => (
                    <React.Fragment key={index}>
                      <FormatDateRange from={v.validFrom} to={v.validTo} />
                      <br />
                    </React.Fragment>
                  ))
                ),
                row.isPublished ? <CheckIcon /> : null,
                row.isDefault ? <CheckIcon /> : null,
                row.hotel ? <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link> : '',
                ...(user.isSingleSpace ? [] : [row.space.name]),
                <ButtonGroup>
                  <PriceListCopyButton id={row.id} spaceId={row.space.id} icon />
                  <PriceListDeleteButton id={row.id} spaceId={row.space.id} icon />
                </ButtonGroup>,
              ])}
            />
          </SimpleAccordion>
        </Grid>
      </Grid>
    </>
  );
}
