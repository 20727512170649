import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Alert, Grid, CircularProgress, Button, IconButton, Tooltip, Divider, InputLabel, Typography } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { allCountriesSorted } from 'languages';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId, filterForSelectableRecords } from 'helper/security';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { FormInputMultiCheckbox } from 'components/form/FormInputMultiCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import {
  HOTEL_VIEW_QUERY,
  UPDATE_HOTEL_MUTATION,
  CREATE_HOTEL_MUTATION,
  DELETE_HOTEL_MUTATION,
  COPY_HOTEL_MUTATION,
  REFETCH_HOTELS_QUERIES,
  EVICT_HOTELS_QUERIES,
  COORDINATES_BY_ADDRESS_QUERY,
} from '../gql';
import { OFFERTEMPLATES_LIST_QUERY } from '../../content/gql';
import { SERVICETYPES_LIST_QUERY } from '../../products/gql';

import {
  HotelListOutput,
  ServiceTypeListOutput,
  ContentOfferTemplateListOutput,
  EHotelWidgetStatusType,
  HotelMeetingRoomListOutput,
  HotelMeetingRoomInput,
  ContentMediaListOutput,
  EContentHotelAttribute,
  EContentHotelCancellationDetailRule,
  EContentHotelTextType,
} from '__generated__/graphql';
import CustomTabs from 'components/Tabs';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormColorPicker } from 'components/form/FormInputColorPicker';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import { GroupedSpaceMultiCheckboxInput, GroupedSpaceSelectionInput, buildGroupedSpaceSelectionOptions } from 'components/security/GroupedSpaceSelectionInput';
import { filterSelector } from 'helper/filter';
import SimpleTable from 'components/table/SimpleTable';
import MediaSelectionDialog, { MediaSelectionDialogOutput } from 'components/dialogs/MediaSelectionDialog';
import SelectInput from '@mui/material/Select/SelectInput';
import { RedirectError } from 'pages/error';
import { isProductionRelease } from 'helper/deployment';

interface HotelProps {
  id: number;
}
interface HotelCreateProps {}
interface HotelFormProps {
  data: HotelListOutput;
  serviceTypes: ServiceTypeListOutput[];
  offerTemplates: ContentOfferTemplateListOutput[];
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  name: yup.string().required().label(i18next.t('hotel-name')),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  slug: yup.string(),
  refCode: yup.string(),
  extRefCode: yup.string().nullable(),
  businessName: yup.string().nullable(),
  businessAddress1: yup.string().nullable(),
  businessAddress2: yup.string().nullable(),
  businessAddress3: yup.string().nullable(),
  businessAddress4: yup.string().nullable(),
  businessCountry: yup.string().required().label(i18next.t('hotel-businesscountry')),
  businessEmail: yup.string().nullable(),
  notificationEmail: yup.string().nullable(),
  widgetUrl: yup.string().nullable(),
  widgetColor: yup.string().required(),
  widgetColorEnabled: yup.boolean().required(),
  widgetPrivacyUrl: yup.string().nullable(),
  widgetTermsUrl: yup.string().nullable(),
  widgetNoRooms: yup.boolean(),
  widgetExpandedMode: yup.boolean(),
  widgetSlimMode: yup.boolean(),
  offerTemplateId: yup.number(),
  offerNotApprovedReminderHotel: yup.number().nullable(),
  offerNotApprovedReminderClient: yup.number().nullable(),
  depositReminderHotel: yup.number().nullable(),
  depositReminderCheckHotel: yup.number().nullable(),
  offerExpirationDays: yup.number().nullable(),
  enableAvailabilityCheck: yup.boolean(),
  serviceTypeIds: yup.array().required().of(yup.number().required()),
  locationLatitude: yup.number().nullable(),
  locationLongitude: yup.number().nullable(),
  showInLister: yup.boolean().required(),
  rating: yup.number().nullable(),
  meetingRooms: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      area: yup.number().required(),
      capacityUForm: yup.number().nullable(),
      capacityTheater: yup.number().nullable(),
      capacityParlament: yup.number().nullable(),
      capacityCircle: yup.number().nullable(),
      capacityBankett: yup.number().nullable(),
      capacityCocktail: yup.number().nullable(),
      capacityBlock: yup.number().nullable(),
    }),
  ),
  medias: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
      previewUrl: yup.string().required(),
    }),
  ),
  attributes: yup.array().of(yup.string().required()),
  cancellationDetails: yup.array().of(
    yup.object().shape({
      rule: yup.mixed<EContentHotelCancellationDetailRule>().required(),
      rate: yup.number().required(),
      cancellationDays: yup.number().required(),
    }),
  ),
  texts: yup.array().of(
    yup.object().shape({
      details: yup.string().nullable(),
      type: yup.mixed<EContentHotelTextType>().nullable(),
      language: yup.string().nullable(),
    }),
  ),
});

function HotelForm(props: HotelFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showMediaSelectionDialog, setShowMediaSelectionDialog] = useState(false);

  const [updateMutateFunction] = useMutation(UPDATE_HOTEL_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_HOTEL_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);
  const spaces = user.spaces;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/settings/hotels/${createdId}`);
  }, [createdId]);

  type HotelFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: HotelListOutput): HotelFormType => ({
    isCreate: props.data.id > 0 ? false : true,
    spaceId: obj.space.id,
    name: obj.name,
    slug: obj.slug,
    rating: obj.rating,
    refCode: obj.refCode,
    extRefCode: obj.extRefCode,
    businessName: obj.businessName,
    businessAddress1: obj.businessAddress1,
    businessAddress2: obj.businessAddress2,
    businessAddress3: obj.businessAddress3,
    businessAddress4: obj.businessAddress4,
    businessCountry: obj.businessCountry,
    businessEmail: obj.businessEmail,
    notificationEmail: obj.notificationEmail,
    widgetUrl: obj.widgetUrl || '',
    widgetColor: obj.widgetColor || '',
    widgetColorEnabled: obj.widgetColorEnabled || false,
    widgetPrivacyUrl: obj.widgetPrivacyUrl || '',
    widgetTermsUrl: obj.widgetTermsUrl || '',
    widgetNoRooms: obj.widgetNoRooms || false,
    widgetExpandedMode: obj.widgetExpandedMode || false,
    widgetSlimMode: obj.widgetSlimMode || false,
    offerTemplateId: obj.offerTemplate ? obj.offerTemplate.id : 0,
    offerNotApprovedReminderHotel: obj.offerNotApprovedReminderHotel || null,
    offerNotApprovedReminderClient: obj.offerNotApprovedReminderClient || null,
    depositReminderHotel: obj.depositReminderHotel || null,
    depositReminderCheckHotel: obj.depositReminderCheckHotel || null,
    offerExpirationDays: obj.offerExpirationDays || null,
    enableAvailabilityCheck: obj.enableAvailabilityCheck || false,
    serviceTypeIds: obj.serviceTypes ? obj.serviceTypes.map(s => s.id) : [],
    locationLatitude: obj.locationLatitude,
    locationLongitude: obj.locationLongitude,
    showInLister: obj.showInLister,
    meetingRooms: obj.meetingRooms
      ? obj.meetingRooms.map(mr => ({
          name: mr.name,
          area: mr.area,
          capacityUForm: mr.capacityUForm,
          capacityTheater: mr.capacityTheater,
          capacityParlament: mr.capacityParlament,
          capacityCircle: mr.capacityCircle,
          capacityBankett: mr.capacityBankett,
          capacityCocktail: mr.capacityCocktail,
          capacityBlock: mr.capacityBlock,
        }))
      : [],
    medias: obj.medias,
    attributes: obj.attributes?.map(f => f.attribute),
    cancellationDetails: obj.cancellationDetails?.map(cd => ({
      rule: cd.rule as EContentHotelCancellationDetailRule,
      rate: cd.rate,
      cancellationDays: cd.cancellationDays,
    })),
    texts: obj.texts
  });

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as HotelListOutput),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    formState: { isDirty, isValidating, isSubmitting, isValid },
  } = formMethods;
  const formValues = getValues();

  const {
    fields: meetingRoomsFields,
    append: meetingRoomsAppend,
    remove: meetingRoomsRemove,
  } = useFieldArray({
    control,
    name: 'meetingRooms',
  });

  const {
    fields: mediasFields,
    append: mediasAppend,
    remove: mediasRemove,
  } = useFieldArray({
    control,
    name: 'medias',
  });

  const {
    fields: cancellationDetailsFields,
    append: cancellationDetailsAppend,
    remove: cancellationDetailsRemove,
  } = useFieldArray({
    control,
    name: 'cancellationDetails',
  });

  const {
    fields: textsFields,
    append: textsAppend,
    remove: textsRemove,
  } = useFieldArray({
    control,
    name: 'texts',
  });

  const getPreviewParams = () => {
    const formVals = watch();

    const params = [
      `&seminargoColorEnabled=${encodeURIComponent(formVals.widgetColorEnabled)}`,
      `&seminargoColor=${encodeURIComponent(formVals.widgetColor)}`,
      `&seminargoTermsUrl=${encodeURIComponent(formVals.widgetTermsUrl || '')}`,
      `&seminargoPrivacyUrl=${encodeURIComponent(formVals.widgetPrivacyUrl || '')}`,
      `&seminargoNoRooms=${encodeURIComponent(formVals.widgetNoRooms || 'false')}`,
      `&seminargoExpandedMode=${encodeURIComponent(formVals.widgetExpandedMode || 'false')}`,
      `&seminargoSlimMode=${encodeURIComponent(formVals.widgetSlimMode || 'false')}`,
    ];
    return params.join('');
  };

  const onSubmit = async (values: HotelFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              slug: values.slug,
              rating: values.rating || null,
              businessName: values.businessName || null,
              businessAddress1: values.businessAddress1 || null,
              businessAddress2: values.businessAddress2 || null,
              businessAddress3: values.businessAddress3 || null,
              businessAddress4: values.businessAddress4 || null,
              businessCountry: values.businessCountry,
              businessEmail: values.businessEmail || null,
              notificationEmail: values.notificationEmail || null,
              widgetUrl: values.widgetUrl || null,
              widgetColor: values.widgetColor,
              widgetColorEnabled: values.widgetColorEnabled,
              widgetPrivacyUrl: values.widgetPrivacyUrl || null,
              widgetTermsUrl: values.widgetTermsUrl || null,
              widgetNoRooms: values.widgetNoRooms || false,
              widgetExpandedMode: values.widgetExpandedMode || false,
              widgetSlimMode: values.widgetSlimMode || false,
              offerTemplateId: values.offerTemplateId && values.offerTemplateId > 0 ? values.offerTemplateId : null,
              offerNotApprovedReminderHotel: values.offerNotApprovedReminderHotel || null,
              offerNotApprovedReminderClient: values.offerNotApprovedReminderClient || null,
              depositReminderHotel: values.depositReminderHotel || null,
              depositReminderCheckHotel: values.depositReminderCheckHotel || null,
              offerExpirationDays: values.offerExpirationDays || null,
              enableAvailabilityCheck: values.enableAvailabilityCheck || false,
              serviceTypeIds: values.serviceTypeIds,
              locationLatitude: values.locationLatitude,
              locationLongitude: values.locationLongitude,
              showInLister: values.showInLister,
              meetingRooms: values.meetingRooms?.map((mr: HotelMeetingRoomInput) => ({
                name: mr.name,
                area: mr.area,
                capacityUForm: mr.capacityUForm,
                capacityTheater: mr.capacityTheater,
                capacityParlament: mr.capacityParlament,
                capacityCircle: mr.capacityCircle,
                capacityBankett: mr.capacityBankett,
                capacityCocktail: mr.capacityCocktail,
                capacityBlock: mr.capacityBlock,
              })),
              medias: values.medias?.map((m: any) => ({
                id: m.id,
              })),
              attributes: values.attributes?.map((f: any) => ({
                attribute: f,
              })),
              cancellationDetails: values.cancellationDetails?.map((cd: any) => ({
                rule: cd.rule,
                rate: cd.rate,
                cancellationDays: cd.cancellationDays,
              })),
              texts: values.texts?.map((t: any) => ({
                details: t.details,
                type: t.type,
                language: t.language,
              })),
            },
          },
          update: cache => EVICT_HOTELS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_HOTELS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateHotel || {}) as HotelListOutput));
        dispatchMessage(dispatch, i18next.t('hotel-updated'));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              businessName: values.businessName || null,
              businessAddress1: values.businessAddress1 || null,
              businessAddress2: values.businessAddress2 || null,
              businessAddress3: values.businessAddress3 || null,
              businessAddress4: values.businessAddress4 || null,
              businessCountry: values.businessCountry,
              businessEmail: values.businessEmail || null,
              notificationEmail: values.notificationEmail || null,
              widgetUrl: values.widgetUrl || null,
              widgetColor: values.widgetColor,
              widgetColorEnabled: values.widgetColorEnabled,
              widgetPrivacyUrl: values.widgetPrivacyUrl || null,
              widgetTermsUrl: values.widgetTermsUrl || null,
              widgetNoRooms: values.widgetNoRooms || false,
              widgetExpandedMode: values.widgetExpandedMode || false,
              widgetSlimMode: values.widgetSlimMode || false,
              offerTemplateId: values.offerTemplateId && values.offerTemplateId > 0 ? values.offerTemplateId : null,
              offerNotApprovedReminderHotel: values.offerNotApprovedReminderHotel || null,
              offerNotApprovedReminderClient: values.offerNotApprovedReminderClient || null,
              depositReminderHotel: values.depositReminderHotel || null,
              depositReminderCheckHotel: values.depositReminderCheckHotel || null,
              offerExpirationDays: values.offerExpirationDays || null,
              enableAvailabilityCheck: values.enableAvailabilityCheck || false,
              serviceTypeIds: values.serviceTypeIds,
            },
          },
          update: cache => EVICT_HOTELS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_HOTELS_QUERIES(),
        });
        reset(toFormSchema((res.data!.createHotel || {}) as HotelListOutput));
        setCreatedId(res.data!.createHotel.id);
        dispatchMessage(dispatch, i18next.t('hotel-created'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const [coordinatesByAddress] = useLazyQuery(COORDINATES_BY_ADDRESS_QUERY)

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('hotels-list-page-title')} {props.data.id > 0 ? props.data.name : ''}
        </title>
      </Helmet>
      <FormProvider {...formMethods}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <CustomTabs
          headers={[
            i18next.t('hotel-settings-tab'),
            i18next.t('hotel-offers-tab'),
            i18next.t('hotel-notifications-tab'),
            i18next.t('hotel-service-tab'),
            i18next.t('hotel-widget-tab'),
          ]}
          icons={[
            undefined,
            undefined,
            undefined,
            undefined,
            props.data.id < 0 || props.data.widgetStatus === EHotelWidgetStatusType.READY ? undefined : <ErrorIcon />,
          ]}
          tabs={[
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormInputText name="name" control={control} label={i18next.t('hotel-name')} disabled={!canEdit} required />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="refCode" control={control} label={i18next.t('hotel-refcode')} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="extRefCode" control={control} label={i18next.t('hotel-extrefcode')} disabled />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SpaceSelectionInput
                  checkAdmin
                  name="spaceId"
                  control={control}
                  disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <FormInputText name="slug" control={control} label={i18next.t('hotel-slug')} />
              </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="businessName" control={control} label={i18next.t('hotel-businessname')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="businessEmail" control={control} label={i18next.t('hotel-businessemail')} disabled={!canEdit} />
                  </Grid>
                  {!isProductionRelease() && <Grid item xs={12}>
                    <FormInputCheckbox name="showInLister" control={control} label={i18next.t('hotel-showinlister')} disabled={!canEdit} />
                  </Grid>}
                  {!isProductionRelease() && <Grid item xs={12}>
                    <FormInputText name="rating" control={control} label={i18next.t('hotel-rating')} disabled={!canEdit} />
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
 
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CustomTabs
                  headers={[
                    i18next.t('hotel-settings-location-tab'),
                    i18next.t('hotel-settings-texts-tab'),
                    i18next.t('hotel-settings-meeting-rooms-tab'),
                    i18next.t('hotel-settings-attributes-tab'),
                    i18next.t('hotel-settings-cancellation-details-tab'),
                    i18next.t('hotel-settings-media-tab'),
                  ]}
                  hidden={[false, ...(new Array(5).fill(isProductionRelease() ? true : false))]}
                  tabs={[
                    <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormInputText name="businessAddress1" control={control} label={i18next.t('hotel-businessaddress1')} disabled={!canEdit} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputText name="businessAddress2" control={control} label={i18next.t('hotel-businessaddress2')} disabled={!canEdit} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputText name="businessAddress3" control={control} label={i18next.t('hotel-businessaddress3')} disabled={!canEdit} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputText name="businessAddress4" control={control} label={i18next.t('hotel-businessaddress4')} disabled={!canEdit} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputText name="locationLatitude" control={control} label={i18next.t('hotel-location-latitude')} disabled={!canEdit} />
                    <Button color="secondary" variant='contained' onClick={async () => {
                        const lat = getValues('locationLatitude');
                        const lon = getValues('locationLongitude');
                        const res = await coordinatesByAddress({ variables: { address: `${getValues('businessAddress1')} ${getValues('businessAddress2')} ${getValues('businessAddress3')} ${getValues('businessAddress4')}` } });
                        if (res.data && res.data.coordinatesByAddress) {
                          const { lat, lng } = res.data.coordinatesByAddress;
                          formMethods.setValue('locationLatitude', lat);
                          formMethods.setValue('locationLongitude', lng);
                        }
                      } }>{i18next.t('hotel-location-fetch-coords')}</Button>
                      <Button color="secondary" variant='contained' onClick={() => {
                        const lat = getValues('locationLatitude');
                        const lon = getValues('locationLongitude');
                        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`);

                      }} >{i18next.t('hotel-location-openmap')}</Button>
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputText name="locationLongitude" control={control} label={i18next.t('hotel-location-longitude')} disabled={!canEdit} />
                    </Grid>
                    
                    <Grid item xs={6}>
                      <FormInputDropdown
                        name="businessCountry"
                        control={control}
                        label={i18next.t('hotel-businesscountry')}
                        options={[...allCountriesSorted.map(c => (c ? { value: c.code, label: c.label } : ({ divider: true } as any)))]}
                        disabled={!canEdit}
                        required
                      />
                    </Grid>
                  </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="texts"
                          headers={[i18next.t('hotel-settings-texts-type'), i18next.t('hotel-settings-texts-details'), i18next.t('hotel-settings-texts-language'), '']}
                          rowIds={textsFields.map((mr, index: number) => mr.id)}
                          rows={textsFields.map((mr, index: number) => [
                            <FormInputDropdown name={`texts.${index}.type`} control={control} options={Object.keys(EContentHotelTextType).map(k => ({
                              value: k,
                              label: i18next.t(`enums-EContentHotelTextType-${k}`),
                            }))} disabled={!canEdit} />,
                            <FormInputText textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }} name={`texts.${index}.details`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`texts.${index}.language`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => textsRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            textsAppend({
                              details: '',
                              type: EContentHotelTextType.HOTEL_DESCRIPTION,
                              language: 'de',
                            })
                          }
                        >
                          {i18next.t('hotel-settings-texts-add')}
                        </Button>
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="meetingRooms"
                          headers={[
                            i18next.t('hotel-settings-meeting-rooms-name'),
                            i18next.t('hotel-settings-meeting-rooms-area'),
                            i18next.t('hotel-settings-meeting-rooms-capacityUForm'),
                            i18next.t('hotel-settings-meeting-rooms-capacityTheater'),
                            i18next.t('hotel-settings-meeting-rooms-capacityParlament'),
                            i18next.t('hotel-settings-meeting-rooms-capacityCircle'),
                            i18next.t('hotel-settings-meeting-rooms-capacityBankett'),
                            i18next.t('hotel-settings-meeting-rooms-capacityCocktail'),
                            i18next.t('hotel-settings-meeting-rooms-capacityBlock'),
                            '',
                          ]}
                          rowIds={meetingRoomsFields.map((mr, index: number) => mr.id)}
                          rows={meetingRoomsFields.map((mr, index: number) => [
                            <FormInputText name={`meetingRooms.${index}.name`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.area`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityUForm`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityTheater`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityParlament`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityCircle`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityBankett`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityCocktail`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`meetingRooms.${index}.capacityBlock`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => meetingRoomsRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            meetingRoomsAppend({
                              name: '',
                              area: 0,
                            })
                          }
                        >
                          {i18next.t('hotel-settings-meeting-rooms-add')}
                        </Button>
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                    {['CATEGORY', 'LOCATION', 'SEMINAR', 'ROOM', 'DESIGN', 'ACTIVITY', 'CATERING', 'WELLNESS', 'HOTELFACILITY'].map(f => (
                      <Grid item xs={12}>
                      <FormInputMultiCheckbox
                        name="attributes"
                        control={control}
                        label={i18next.t(`enums-EContentHotelAttribute-${f}`)}
                        options={Object.keys(EContentHotelAttribute).filter(k => k.startsWith(f)).map(k => ({
                          value: k,
                          label: i18next.t(`enums-EContentHotelAttribute-${k}`),
                        }))}
                        disabled={!canEdit}
                      /></Grid>)
                    )}</Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <SimpleTable
                          name="cancellationDetails"
                          headers={[
                            i18next.t('hotel-settings-cancellation-details-rule'), 
                            i18next.t('hotel-settings-cancellation-details-cancellation-days'),
                            i18next.t('hotel-settings-cancellation-details-rate'),
                            '']}
                          rowIds={cancellationDetailsFields.map((mr, index: number) => mr.id)}
                          rows={cancellationDetailsFields.map((mr, index: number) => [
                            <FormInputDropdown name={`cancellationDetails.${index}.rule`} control={control} options={[
                              { value: EContentHotelCancellationDetailRule.BEFORE, label: i18next.t('enums-EContentHotelCancellationDetailRule-BEFORE') },
                              { value: EContentHotelCancellationDetailRule.AFTER, label: i18next.t('enums-EContentHotelCancellationDetailRule-AFTER') },
                            ]} disabled={!canEdit} />,
                            <FormInputText name={`cancellationDetails.${index}.cancellationDays`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`cancellationDetails.${index}.rate`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => cancellationDetailsRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => {
                          cancellationDetailsAppend({
                            rule: EContentHotelCancellationDetailRule.BEFORE,
                            rate: 0,
                            cancellationDays: 0,
                          });
                        }}>
                          {i18next.t('hotel-settings-cancellation-details-add')}
                        </Button>
                      </Grid>
                    </Grid>
                    ,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MediaSelectionDialog
                          open={showMediaSelectionDialog}
                          setOpen={setShowMediaSelectionDialog}
                          onSelect={(medias: MediaSelectionDialogOutput[]) => {
                            medias.forEach(media => {
                              if (!mediasFields.find(mr => mr.name === media.name)) {
                                mediasAppend(media);
                              }
                            });
                          }}
                        />
                        <SimpleTable
                          name="medias"
                          headers={[i18next.t('hotel-settings-medias-name'), '', '']}
                          rowIds={mediasFields.map((mr, index: number) => mr.id)}
                          rows={mediasFields.map((mr, index: number) => [
                            mr.name,
                            <img src={mr.previewUrl} width={150} />,
                            <IconButton onClick={() => mediasRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setShowMediaSelectionDialog(true)}>
                          {i18next.t('hotel-settings-medias-add')}
                        </Button>
                      </Grid>
                    </Grid>,
                  ]}
                />
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <GroupedSpaceSelectionInput
                  name="offerTemplateId"
                  control={control}
                  label={i18next.t('hotel-offertemplate')}
                  list={props.offerTemplates}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormInputNumber name="offerExpirationDays" control={control} label={i18next.t('hotel-offerexpirationdays')} disabled={!canEdit} />
              </Grid>
              {!isProductionRelease() && <Grid item xs={12} sm={3}>
                <FormInputCheckbox
                  name="enableAvailabilityCheck"
                  control={control}
                  label={i18next.t('hotel-enableavailabilitycheck')}
                  disabled={!canEdit}
                />
              </Grid>}
            </Grid>,
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormInputText name="notificationEmail" control={control} label={i18next.t('hotel-notificationemail')} disabled={!canEdit} />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="offerNotApprovedReminderHotel"
                  control={control}
                  label={i18next.t('hotel-offernotapprovedreminderhotel')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="offerNotApprovedReminderClient"
                  control={control}
                  label={i18next.t('hotel-offernotapprovedreminderclient')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber name="depositReminderHotel" control={control} label={i18next.t('hotel-depositreminderhotel')} disabled={!canEdit} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="depositReminderCheckHotel"
                  control={control}
                  label={i18next.t('hotel-depositremindercheckhotel')}
                  disabled={!canEdit}
                />
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <GroupedSpaceMultiCheckboxInput
                  name="serviceTypeIds"
                  list={filterForSelectableRecords(user, props.serviceTypes, getValues('spaceId'), false, true)}
                  control={control}
                  label={i18next.t('hotel-servicetypes')}
                  mapElement={(s) => <Link to={`/servicetypes/${s.id}`}>{s.name}</Link>}
                  disabled={!canEdit}
                />
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              {props.data.id > 0 && (
                <Grid item xs={12}>
                  {props.data.widgetStatus === EHotelWidgetStatusType.READY && (
                    <Alert severity="success">{i18next.t('hotel-widget-status-READY')}</Alert>
                  )}
                  {props.data.widgetStatus !== EHotelWidgetStatusType.READY && (
                    <Alert severity="warning">{i18next.t(`hotel-widget-status-${props.data.widgetStatus}`)}</Alert>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <FormInputCheckbox name="widgetColorEnabled" control={control} label={i18next.t('hotel-widget-color-enabled')} />
                <FormColorPicker
                  name="widgetColor"
                  control={control}
                  label={i18next.t('hotel-widget-color')}
                  required={watch().widgetColorEnabled}
                  helperText={i18next.t('hotel-widget-color-helper')}
                  disabled={!canEdit || !watch().widgetColorEnabled}
                />
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={7}>
                <InputLabel>{i18next.t('hotel-widget-code')}</InputLabel>
                <pre>
                  {`<script defer="defer" src="${props.data.widgetBaseUrl!}/widget.js"></script>`}
                  <br />
                  {`<div id="seminargo-widget" data-hotel-ref="${props.data.refCode}"></div>`}
                </pre>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputText
                  name="widgetPrivacyUrl"
                  control={control}
                  label={i18next.t('hotel-widget-privacy-url')}
                  helperText={i18next.t('hotel-widget-privacy-url-helper')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputText
                  name="widgetTermsUrl"
                  control={control}
                  label={i18next.t('hotel-widget-terms-url')}
                  helperText={i18next.t('hotel-widget-terms-url-helper')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SimpleDropdown
                  name="widgetMode"
                  label={i18next.t('hotel-widget-display-mode')}
                  disabled={!canEdit}
                  value={watch().widgetExpandedMode ? 'expanded' : 'slim'}
                  options={[
                    { value: 'slim', label: i18next.t('enums-EHotelWidgetModeType-SLIM') },
                    { value: 'expanded', label: i18next.t('enums-EHotelWidgetModeType-EXPANDED') },
                  ]}
                  onChange={async (e) => {
                    formMethods.setValue('widgetExpandedMode', e.target.value === 'expanded', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                    formMethods.setValue('widgetSlimMode', e.target.value === 'slim', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputCheckbox
                  name="widgetNoRooms"
                  control={control}
                  label={i18next.t('hotel-widget-no-rooms')}
                  helperText={i18next.t('hotel-widget-no-rooms-helper')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputText
                  name="widgetUrl"
                  control={control}
                  label={i18next.t('hotel-widgeturl')}
                  helperText={i18next.t('hotel-widgeturl-helper')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {props.data.id > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">{i18next.t('hotel-widget-preview')}</Typography>
                  <iframe src={`${props.data.testUrl!}${getPreviewParams()}`} style={{ width: '100%', height: '800px', border: 'none' }} />
                </Grid>
              )}
            </Grid>,
          ]}
        />
      </FormProvider>
      {canEdit && (
        <Grid item xs={12}>
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
            disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating || !isValid}
            onClick={async () => {
              const valid = await trigger();
              if (valid) {
                handleSubmit(onSubmit)();
              }
            }}
          >
            {i18next.t('hotel-save')}
          </Button>
          {props.data.id > 0 && <HotelCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <HotelDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      )}
    </>
  );
}

export default function Hotel(props: HotelProps) {
  const hotelQuery = useQuery(HOTEL_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const serviceTypesQuery = useQuery(SERVICETYPES_LIST_QUERY);
  const offerTemplatesQuery = useQuery(OFFERTEMPLATES_LIST_QUERY);

  const loading = hotelQuery.loading || serviceTypesQuery.loading || offerTemplatesQuery.loading;
  const error = hotelQuery.error || serviceTypesQuery.error || offerTemplatesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <HotelForm
        data={hotelQuery.data!.viewHotel as HotelListOutput}
        serviceTypes={serviceTypesQuery.data!.listServiceTypes as ServiceTypeListOutput[]}
        offerTemplates={offerTemplatesQuery.data!.listOfferTemplates as ContentOfferTemplateListOutput[]}
      />
    );
}

export function HotelCreate(props: HotelCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const serviceTypesQuery = useQuery(SERVICETYPES_LIST_QUERY);
  const offerTemplatesQuery = useQuery(OFFERTEMPLATES_LIST_QUERY);

  const loading = serviceTypesQuery.loading || offerTemplatesQuery.loading;
  const error = serviceTypesQuery.error || offerTemplatesQuery.error;



  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <HotelForm
        data={
          {
            id: -1,
            name: '',
            refCode: '',
            businessCountry: 'AT',
            widgetColor: '#000000',
            widgetExpandedMode: true,
            space: { id: initialSpaceId(user, filter) },
            serviceTypes: serviceTypesQuery.data!.listServiceTypes
          } as HotelListOutput
        }
        serviceTypes={serviceTypesQuery.data!.listServiceTypes as ServiceTypeListOutput[]}
        offerTemplates={offerTemplatesQuery.data!.listOfferTemplates as ContentOfferTemplateListOutput[]}
      />
    );
}

interface HotelCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function HotelCopyButton(props: HotelCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_HOTEL_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id },
        update: cache => EVICT_HOTELS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_HOTELS_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('hotel-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon) {
    return (
      <IconButton disabled={!canEdit || copyMutateLoading} onClick={__do}>
        <Tooltip title={i18next.t('hotel-copy')}>
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    );
  } else {
    return (
      <Button
        sx={{ marginRight: 2 }}
        variant="contained"
        color="secondary"
        disabled={!canEdit || copyMutateLoading}
        startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
        onClick={__do}
      >
        {i18next.t('hotel-copy')}
      </Button>
    );
  }
}

interface HotelDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function HotelDeleteButton(props: HotelDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_HOTEL_MUTATION);

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('hotel-confirm-delete')}
      confirmationTitle={i18next.t('hotel-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_HOTELS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_HOTELS_QUERIES(),
          });
          navigate('/settings/hotels');
          dispatchMessage(dispatch, i18next.t('hotel-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('hotel-delete')}
    </ConfirmationButton>
  );
}
