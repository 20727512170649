import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormSelectIconInputProps, FormSelectIconOption } from './FormInputProps';

export const FormInputMultiCheckbox = ({ name, control, label, options, disabled }: FormSelectIconInputProps) => {
  const isSelected = (values: any[], optionValue: any) => {
    return values && values.length > 0 && values.indexOf(optionValue) >= 0;
  };

  const handleSelect = (values: any[], optionValue: any) => {
    if (!values) values = [];
    const isPresent = values.indexOf(optionValue);
    if (isPresent !== -1) {
      const remaining = values.filter((item: any) => item !== optionValue);
      return remaining;
    } else {
      return [...values, optionValue];
    }
  };
  return (
    <FormControl>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <div>
        {options.map((option, index) => {
          if (option.divider) {
            return (
              <FormLabel key={index} component="legend">
                {option.label}
              </FormLabel>
            );
          } else if ('icon' in option) {
            return (
              <Controller
                name={name}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      checked={isSelected(value, option.value)}
                      onChange={() => onChange(handleSelect(value, option.value))}
                      disabled={disabled}
                      icon={option.icon}
                      checkedIcon={option.checkedIcon}
                    />
                  );
                }}
                control={control}
              />
            );
          } else {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Controller
                    name={name}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Checkbox
                          checked={isSelected(value, option.value)}
                          onChange={() => onChange(handleSelect(value, option.value))}
                          disabled={disabled}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                label={option.label}
              />
            );
          }
        })}
      </div>
    </FormControl>
  );
};
