import React from 'react';
import { Button } from '@mui/material';
import { arrayBufferToBase64 } from './form/FormInputFileUpload';

interface FileUploadButtonProps {
  name: string;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean | null;
  accept?: string | null;
  binary?: (name: string, type: string) => boolean;
  onChange: (name: string, type: string, content: any) => void;
  startIcon?: any;
  buttonProps?: {
    [key: string]: any;
  };
}

export default function FileUploadButton(props: FileUploadButtonProps) {
  const { name, accept, disabled, children, binary, onChange, startIcon, buttonProps, ...rest } = props;

  return (
    <label htmlFor={name}>
      <input
        style={{ display: 'none' }}
        id={name}
        name={name}
        type="file"
        {...(accept ? { accept } : {})}
        onChange={e => {
          const formfile = (e.target as any).files[0];
          if (!formfile) return;

          const asBinary = binary ? binary(formfile.name, formfile.type) : false;

          const reader = new FileReader();
          reader.onload = async eo => {
            onChange(formfile.name, formfile.type, asBinary ? arrayBufferToBase64((eo.target as any).result) : (eo.target as any).result);
          };
          if (asBinary) {
            reader.readAsArrayBuffer(formfile);
          } else {
            reader.readAsText(formfile);
          }
        }}
      />
      <Button color="secondary" variant="contained" component="span" disabled={!!disabled} startIcon={startIcon} {...buttonProps}>
        {children}
      </Button>{' '}
    </label>
  );
}
