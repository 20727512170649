import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';

import { userSelector, hasAnyAdminSpace } from 'helper/security';
import PriceLists from './pricelists';
import PriceList from './pricelist';
import { PriceListCreate } from './pricelist';
import PriceSelector from './selector';
import { PriceSelectorCreate } from './selector';
import TaxTypes from './taxtypes';
import TaxType from './taxtype';
import { TaxTypeCreate } from './taxtype';
import Import from './import';

export default function PriceIndex() {
  const location = useLocation();
  const user = userSelector()!;

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs value={location.pathname === '/pricelists' ? '/pricelists/pricelists' : location.pathname.split('/').slice(0, 3).join('/')}>
            <Tab value="/pricelists/pricelists" label={i18next.t('pricelists-tabs-header-pricelists')} component={Link} to="/pricelists/pricelists" />
            <Tab value="/pricelists/taxtypes" label={i18next.t('pricelists-tabs-header-taxtypes')} component={Link} to="/pricelists/taxtypes" />
            {hasAnyAdminSpace(user) && (
              <Tab value="/pricelists/import" label={i18next.t('pricelists-tabs-header-import')} component={Link} to="/pricelists/import" />
            )}
          </Tabs>

          <Box p={2}>
            <Routes>
              <Route index element={<PriceLists />} />
              <Route
                path="pricelists/:priceListId/selectors/_create"
                Component={() => <PriceSelectorCreate priceListId={parseInt(useParams().priceListId!)} />}
              />
              <Route
                path="pricelists/:priceListId/selectors/:id"
                Component={() => <PriceSelector priceListId={parseInt(useParams().priceListId!)} id={parseInt(useParams().id!)} />}
              />
              <Route path="pricelists/_create" Component={() => <PriceListCreate />} />
              <Route path="pricelists/:id" Component={() => <PriceList id={parseInt(useParams().id!)} />} />
              <Route path="pricelists" Component={() => <PriceLists />} />
              <Route path="taxtypes/_create" Component={() => <TaxTypeCreate />} />
              <Route path="taxtypes/:id" Component={() => <TaxType id={parseInt(useParams().id!)} />} />
              <Route path="taxtypes" Component={() => <TaxTypes />} />
              <Route path="import" element={<Import />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
