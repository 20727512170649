import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, Grid, CircularProgress, Typography } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import CustomTabs from 'components/Tabs';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId } from 'helper/security';
import { getFullBackendUrl } from 'helper/download';

import {
  UPDATE_INTEGRATION_HOTEL_MUTATION,
  DELETE_INTEGRATION_HOTEL_MUTATION,
  REFETCH_INTEGRATIONS_HOTELS_QUERIES,
  EVICT_INTEGRATION_HOTELS_QUERIES,
  TRIGGER_INTEGRATION_HOTEL_MUTATION,
  INTEGRATION_HOTEL_VIEW_QUERY,
  EVICT_INTEGRATION_HOTELLOG_QUERIES,
} from './gql';
import yup from 'validation';
import { IntegrationHotelViewFragment, EIntBookingMode } from '__generated__/graphql';
import { IntegrationLogsTable } from './logs';

import {
  canPMSSyncAvailability,
  canPMSSyncBookings,
  canPMSConfirmBookings,
  canPMSSyncInventory,
  canPMSSyncPrices,
  canPMSSyncProperty,
  canPMSTriggerSync,
} from './pmssupport';
import { RedirectError } from 'pages/error';

interface HotelProps {
  id: number;
}
interface HotelFormProps {
  data: IntegrationHotelViewFragment;
}

const validationSchema = yup.object().shape({
  secretName: yup.string(),
  hotelName: yup.string(),
  hotelExtRefCode: yup.string().nullable(),
  syncProperties: yup.boolean().required(),
  syncProducts: yup.boolean().required(),
  syncAvailability: yup.boolean().required(),
  syncAvailabilityDays: yup.number().required(),
  syncPrices: yup.boolean().required(),
  syncPricesDays: yup.number().required(),
  directBooking: yup.string().required(),
});

function HotelForm(props: HotelFormProps) {
  const dispatch = useDispatch();

  const [logUpdateCounter, setLogUpdateCounter] = useState(0);

  const [updateMutateFunction] = useMutation(UPDATE_INTEGRATION_HOTEL_MUTATION);
  const [triggerMutateFunction, { loading: triggerMutateLoading }] = useMutation(TRIGGER_INTEGRATION_HOTEL_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  type HotelFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: IntegrationHotelViewFragment): HotelFormType => ({
    ...obj,
    hotelExtRefCode: obj.hotel.extRefCode,
    hotelName: obj.hotel.name,
    secretName: `${obj.secret.name} - ${i18next.t('enums-EIntSystem-' + obj.secret.system)}`,
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema(props.data),
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const _canPMSSyncAvailability = canPMSSyncAvailability(props.data.secret.system);
  const _canPMSSyncBookings = canPMSSyncBookings(props.data.secret.system);
  const _canPMSConfirmBookings = canPMSConfirmBookings(props.data.secret.system);
  const _canPMSSyncInventory = canPMSSyncInventory(props.data.secret.system);
  const _canPMSSyncPrices = canPMSSyncPrices(props.data.secret.system);
  const _canPMSSyncProperty = canPMSSyncProperty(props.data.secret.system);
  const _canPMSTriggerSync = canPMSTriggerSync(props.data.secret.system);

  const onSubmit = async (values: HotelFormType) => {
    try {
      const res = await updateMutateFunction({
        variables: {
          id: props.data.id,
          data: {
            syncAvailability: _canPMSSyncAvailability ? !!values.syncAvailability : false,
            syncAvailabilityDays: _canPMSSyncAvailability ? values.syncAvailabilityDays : 0,
            syncPrices: _canPMSSyncPrices ? !!values.syncPrices : false,
            syncPricesDays: _canPMSSyncPrices ? values.syncPricesDays : 0,
            syncProducts: _canPMSSyncInventory ? !!values.syncProducts : false,
            syncProperties: _canPMSSyncProperty ? !!values.syncProperties : false,
            directBooking: _canPMSSyncBookings ? (values.directBooking as EIntBookingMode) : EIntBookingMode.SEM,
          },
        },
        update: cache => EVICT_INTEGRATION_HOTELS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_INTEGRATIONS_HOTELS_QUERIES(props.data.id),
      });
      reset(toFormSchema((res.data!.updateIntegrationHotel || {}) as IntegrationHotelViewFragment));
      dispatchMessage(dispatch, i18next.t('integration-hotel-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('integration-hotels-list-page-title')} {props.data.hotel.name}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={4}>
          <FormInputText name="secretName" control={control} label={i18next.t('integration-hotel-secret')} disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputText name="hotelName" control={control} label={i18next.t('integration-hotel-hotel')} disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputText name="hotelExtRefCode" control={control} label={i18next.t('integration-hotel-extrefcode')} disabled />
        </Grid>
        <Grid item sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormInputCheckbox
                name="syncProperties"
                control={control}
                label={i18next.t('integration-hotel-syncproperties')}
                disabled={!canEdit || !_canPMSSyncProperty}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputCheckbox
                name="syncProducts"
                control={control}
                label={i18next.t('integration-hotel-syncproducts')}
                disabled={!canEdit || !_canPMSSyncInventory}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormInputCheckbox
                name="syncPrices"
                control={control}
                label={i18next.t('integration-hotel-syncprices')}
                disabled={!canEdit || !_canPMSSyncPrices}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputNumber
                name="syncPricesDays"
                control={control}
                label={i18next.t('integration-hotel-syncpricesdays')}
                required
                disabled={!canEdit || !watch('syncPrices') || !_canPMSSyncPrices}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormInputCheckbox
                name="syncAvailability"
                control={control}
                label={i18next.t('integration-hotel-syncavailability')}
                disabled={!canEdit || !_canPMSSyncAvailability}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputNumber
                name="syncAvailabilityDays"
                control={control}
                label={i18next.t('integration-hotel-syncavailabilitydays')}
                required
                disabled={!canEdit || !watch('syncAvailability') || !_canPMSSyncAvailability}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputDropdown
                name="directBooking"
                control={control}
                label={i18next.t('integration-hotel-directbooking')}
                options={
                  [
                    { value: EIntBookingMode.SEM, label: `${i18next.t('enums-EIntBookingMode-' + EIntBookingMode.SEM)}` },
                    { value: EIntBookingMode.DIRECT_OFFER, label: `${i18next.t('enums-EIntBookingMode-' + EIntBookingMode.DIRECT_OFFER)}` },
                    _canPMSConfirmBookings
                      ? { value: EIntBookingMode.DIRECT_CONFIRM, label: `${i18next.t('enums-EIntBookingMode-' + EIntBookingMode.DIRECT_CONFIRM)}` }
                      : null,
                  ].filter(o => !!o) as any[]
                }
                required
                disabled={!canEdit || !_canPMSSyncBookings}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          {props.data.secret.system === 'ELITEFO' && (
            <Grid container>
              <Grid item sm={12}>
                <h1>Einrichtung in Elite Hotel Software</h1>
                <ul>
                  <li>
                    SEM-Endpoint: <b>{getFullBackendUrl(`/backend/api/pms/elitefo/${props.data.hotel.refCode}`, true)}</b>
                  </li>
                  <li>HTTP-Basic Authentifizierung wie im API-Schlüssel angegeben</li>
                  <li>
                    RequestorID: <b>{props.data.hotel.refCode}</b>
                  </li>
                  <li>
                    HotelCode: <b>{props.data.hotel.refCode}</b>
                  </li>
                  <li>
                    Kategorie: entpricht der <b>SKU</b> der jeweiligen SEM-Leistungen
                  </li>
                  <li>
                    Verpflegung: entpricht der <b>SKU</b> der jeweiligen SEM-Leistungen
                  </li>
                  <li>
                    Arracode: entpricht dem <b>Referenzcode</b> der SEM-Preisliste
                  </li>
                </ul>
              </Grid>
            </Grid>
          )}
          {props.data.secret.system === 'PROTELIDS' && (
            <Grid container>
              <Grid item sm={12}>
                <h1>Einrichtung in Protel IDS Software</h1>
                <ul>
                  <li>
                    SEM-Endpoint: <b>{getFullBackendUrl(`/backend/api/pms/protelids/${props.data.hotel.refCode}`, true)}</b>
                  </li>
                  <li>Authentifizierung wie im API-Schlüssel angegeben</li>
                  <li>
                    Property Code: <b>{props.data.hotel.refCode}</b>
                  </li>
                  <li>
                    Room Code: entpricht der <b>SKU</b> der jeweiligen SEM-Leistungen
                  </li>
                  <li>
                    Rate Code: entpricht dem <b>Referenzcode</b> der SEM-Preisliste
                  </li>
                </ul>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            headers={[i18next.t('integration-hotel-log-tab')]}
            tabs={[<IntegrationLogsTable key={logUpdateCounter} variables={{ spaceId: props.data.space.id, integrationId: props.data.id }} />]}
          />
        </Grid>
        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={!isDirty || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('integration-hotel-save')}
            </Button>
          )}
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={<SyncIcon />}
            disabled={triggerMutateLoading || !_canPMSTriggerSync}
            onClick={async () => {
              try {
                await triggerMutateFunction({
                  variables: {
                    id: props.data.id,
                  },
                  update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                });
                setLogUpdateCounter(logUpdateCounter + 1);
                dispatchMessage(dispatch, i18next.t('integration-hotel-sync-triggered'));
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {i18next.t('integration-hotel-sync')}
          </Button>
          {canEdit && <HotelDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      </Grid>
    </>
  );
}

export default function Hotel(props: HotelProps) {
  const hotelQuery = useQuery(INTEGRATION_HOTEL_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = hotelQuery.loading;
  const error = hotelQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <HotelForm data={hotelQuery.data!.viewIntegrationHotel as IntegrationHotelViewFragment} />;
}

interface HotelDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function HotelDeleteButton(props: HotelDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_INTEGRATION_HOTEL_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('integration-hotel-confirm-delete')}
      confirmationTitle={i18next.t('integration-hotel-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_INTEGRATION_HOTELS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_INTEGRATIONS_HOTELS_QUERIES(),
          });
          navigate('/settings/integration/hotels');
          dispatchMessage(dispatch, i18next.t('integration-hotel-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('integration-hotel-delete')}
    </ConfirmationButton>
  );
}
