import React from 'react';
import { FormInputMultiCheckbox } from '../form/FormInputMultiCheckbox';
import { FormInputProps } from '../form/FormInputProps';
import { TaxTypeListOutput } from '__generated__/graphql';

interface TaxComponentSelectionInputProps extends FormInputProps {
  taxTypesData: TaxTypeListOutput[];
  country?: string | null;
}

export const formatTaxTypeName = (taxTypesData: TaxTypeListOutput[], id: number, country?: string | null) => {
  const tt = taxTypesData.find(t => t.id === id);
  if (!tt) return `${id}`;

  if (country) {
    const tr = tt.rates.find(r => r.country === country);
    if (tr) return tr.name;
  }
  const trNoCountry = tt.rates.find(r => !r.country);
  if (trNoCountry) return trNoCountry.name;
  return tt.name;
};

export const filterTaxTypes = (taxTypesData: TaxTypeListOutput[], country?: string | null) =>
  taxTypesData.filter(tt => tt.rates.findIndex(r => !r.country) >= 0 || (country && tt.rates.findIndex(r => r.country === country) >= 0));

export const TaxComponentSelectionInput = (props: TaxComponentSelectionInputProps) => {
  const { taxTypesData, country, ...rest } = props;

  return (
    <FormInputMultiCheckbox
      {...rest}
      options={filterTaxTypes(props.taxTypesData, props.country).map(t => ({
        label: formatTaxTypeName(props.taxTypesData, t.id, country),
        value: t.id,
      }))}
    />
  );
};
