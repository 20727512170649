import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';
import EmailTemplatesList from './emailtemplates';
import EmailTemplate, { EmailTemplateCreate } from './emailtemplate';
import OfferTemplatesList from './offertemplates';
import OfferTemplate, { OfferTemplateCreate } from './offertemplate';

export default function ProductsIndex() {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs
            value={
              location.pathname === '/content/templates' ? '/content/templates/emailtemplates' : location.pathname.split('/').slice(0, 4).join('/')
            }
          >
            <Tab
              value="/content/templates/emailtemplates"
              label={i18next.t('content-tabs-header-emailtemplates')}
              component={Link}
              to="/content/templates/emailtemplates"
            />
            <Tab
              value="/content/templates/offertemplates"
              label={i18next.t('content-tabs-header-offertemplates')}
              component={Link}
              to="/content/templates/offertemplates"
            />
          </Tabs>

          <Box p={2}>
            <Routes>
              <Route path="emailtemplates" index element={<EmailTemplatesList />} />
              <Route path="emailtemplates/_create" element={<EmailTemplateCreate />} />
              <Route path="emailtemplates/:id" Component={() => <EmailTemplate id={parseInt(useParams().id!)} />} />
              <Route path="offertemplates" element={<OfferTemplatesList />} />
              <Route path="offertemplates/_create" element={<OfferTemplateCreate />} />
              <Route path="offertemplates/:id" Component={() => <OfferTemplate id={parseInt(useParams().id!)} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
