import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';

import { userSelector, hasAnyAdminSpace } from 'helper/security';
import i18next from 'i18next';
import Products from './products';
import Product from './product';
import { ProductCreate } from './product';
import ProductBundles from './bundles/bundles';
import ProductBundle from './bundles/bundle';
import { ProductBundleCreate } from './bundles/bundle';
import Facilities from './facilities';
import Facility from './facility';
import { FacilityCreate } from './facility';
import Import from './import';
import SKU from './sku';

export default function ProductsIndex() {
  const location = useLocation();
  const user = userSelector()!;

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Tabs value={location.pathname === '/products' ? '/products/products' : location.pathname.split('/').slice(0, 3).join('/')}>
            <Tab value="/products/products" label={i18next.t('products-tabs-header-products')} component={Link} to="/products/products" />
            <Tab value="/products/bundles" label={i18next.t('products-tabs-header-bundles')} component={Link} to="/products/bundles" />
            <Tab value="/products/facilities" label={i18next.t('products-tabs-header-facilities')} component={Link} to="/products/facilities" />
            {hasAnyAdminSpace(user) && (
              <Tab value="/products/import" label={i18next.t('products-tabs-header-import')} component={Link} to="/products/import" />
            )}
          </Tabs>

          <Box p={2}>
            <Routes>
              <Route index element={<Products />} />
              <Route path="products/_create" element={<ProductCreate />} />
              <Route path="products/:id" Component={() => <Product id={parseInt(useParams().id!)} />} />
              <Route path="products" element={<Products />} />
              <Route path="bundles/_create" element={<ProductBundleCreate />} />
              <Route path="bundles/:id" Component={() => <ProductBundle id={parseInt(useParams().id!)} />} />
              <Route path="bundles" element={<ProductBundles />} />
              <Route path="facilities/_create" element={<FacilityCreate />} />
              <Route path="facilities/:id" Component={() => <Facility id={parseInt(useParams().id!)} />} />
              <Route path="facilities" element={<Facilities />} />
              <Route path="import" element={<Import />} />
              <Route path="sku/:sku" Component={() => <SKU sku={useParams().sku!} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
