import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IAccordionProps {
  header: string | JSX.Element;
  icon?: JSX.Element | null;
  children: string | JSX.Element;
  autoOpen?: boolean;
  alwaysOpen?: boolean;
}

export default function ControlledAccordions({ header, icon, children, autoOpen, alwaysOpen }: IAccordionProps) {
  const [expanded, setExpanded] = React.useState<boolean>(alwaysOpen ? true : autoOpen || false);

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => (!alwaysOpen ? setExpanded(!expanded) : null)}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {icon} <Typography variant="h6">{header}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
