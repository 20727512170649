import moment from 'moment';
import { toPricingWeekday } from './date';

export type PriceSelectorInput = {
  priceListId: number;
  bundleId?: number;
  bundleItemId?: number;
  productId?: number;
  facilityId?: number;
  guestCount: number;
  itemCount: number;
  date: Date;
  selectorId?: number;
};

export type PriceSelectorOutput<PriceSelectorType extends IPriceSelector> = {
  price: number | null;
  bundlePriceFromProduct: boolean | null;
  components: IProductPriceComponent[];
  selector: PriceSelectorType;
};

export interface IPriceSelector {
  id: number;
  priceListId: number;
  name: string;
  isBasePrice: boolean;
  minGuestCount: number | null;
  maxGuestCount: number | null;
  minItemCount: number | null;
  maxItemCount: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  weekdays: IPriceSelectorWeekday[];
  prices: IProductPrice[];
}
export interface IPriceSelectorWeekday {
  weekdays: string;
}

export interface IProductPrice {
  price: number | null;
  bundleId: number | null;
  bundlePriceFromProduct: boolean | null;
  bundleItemId: number | null;
  productId: number | null;
  facilityId: number | null;
  components: IProductPriceComponent[];
}
export interface IProductPriceComponent {
  taxTypeId: number;
  price: number;
}

export class PriceSelector<PriceSelectorType extends IPriceSelector> {
  protected priceSelectors: PriceSelectorType[] | null = null;

  public hydrate(json: string) {
    const { priceSelectors } = JSON.parse(json);
    this.priceSelectors = priceSelectors;
  }
  public dehydrate(): string {
    return JSON.stringify({
      priceSelectors: this.priceSelectors,
    });
  }

  public findPrice(input: PriceSelectorInput): PriceSelectorOutput<PriceSelectorType> | null {
    const _findPrice = (prices: IProductPrice[]) =>
      prices.find(p => {
        if (input.bundleId && input.bundleId === p.bundleId) return true;
        if (input.bundleItemId && input.bundleItemId === p.bundleItemId) return true;
        if (input.productId && input.productId === p.productId) return true;
        if (input.facilityId && input.facilityId === p.facilityId) return true;
        return false;
      });

    if (input.selectorId) {
      const selector = this.priceSelectors?.find(s => s.priceListId === input.priceListId && s.id === input.selectorId);
      if (selector) {
        const price = _findPrice(selector.prices);
        if (price) {
          return {
            price: price.price,
            bundlePriceFromProduct: price.bundlePriceFromProduct,
            components: price.components,
            selector: selector,
          };
        }
      }
    } else {
      const possibleSelectors = this.priceSelectors?.filter(s => s.priceListId === input.priceListId && !s.isBasePrice && _findPrice(s.prices)) || [];

      if (possibleSelectors.length > 0) {
        const dateMom = moment(input.date);
        const weekday = toPricingWeekday(input.date);

        const _filterGuestCount = (s: IPriceSelector) =>
          (s.minGuestCount ? input.guestCount >= s.minGuestCount : true) && (s.maxGuestCount ? input.guestCount <= s.maxGuestCount : true);
        const _filterItemCount = (s: IPriceSelector) =>
          (s.minItemCount ? input.itemCount >= s.minItemCount : true) && (s.maxItemCount ? input.itemCount <= s.maxItemCount : true);
        const _filterDate = (s: IPriceSelector) =>
          (s.validFrom ? moment(s.validFrom).startOf('day').isSameOrBefore(dateMom) : true) && (s.validTo ? moment(s.validTo).endOf('day').isSameOrAfter(dateMom) : true);
        const _filterWeekday = (s: IPriceSelector) => (s.weekdays.length > 0 ? s.weekdays.find((w: any) => w.weekdays === weekday) : true);

        const matchingSelectors = possibleSelectors.filter(s => _filterGuestCount(s) && _filterItemCount(s) && _filterDate(s) && _filterWeekday(s));
        if (matchingSelectors.length === 1) {
          const price = _findPrice(matchingSelectors[0].prices);
          if (price) {
            return {
              price: price.price,
              bundlePriceFromProduct: price.bundlePriceFromProduct,
              components: price.components,
              selector: matchingSelectors[0],
            };
          }
        } else if (matchingSelectors.length > 1) {
          const [maxFilterCount, maxIndex] = matchingSelectors.reduce(
            (agg, selector, i) => {
              const filterCount =
                (selector.minGuestCount ? 1 : 0) +
                (selector.maxGuestCount ? 1 : 0) +
                (selector.minItemCount ? 1 : 0) +
                (selector.maxItemCount ? 1 : 0) +
                (selector.validFrom ? 1 : 0) +
                (selector.validTo ? 1 : 0) +
                (selector.weekdays.length > 0 ? 1 : 0);
              if (agg[0] < filterCount) return [filterCount, i];
              else return agg;
            },
            [Number.MIN_VALUE, -1],
          );

          const price = _findPrice(matchingSelectors[maxIndex].prices);
          if (price) {
            return {
              price: price.price,
              bundlePriceFromProduct: price.bundlePriceFromProduct,
              components: price.components,
              selector: matchingSelectors[maxIndex],
            };
          }
        }
      }
    }

    const baseSelector = this.priceSelectors?.find(s => s.priceListId === input.priceListId && s.isBasePrice);
    if (baseSelector) {
      const price = _findPrice(baseSelector.prices);
      if (price) {
        return {
          price: price.price,
          bundlePriceFromProduct: price.bundlePriceFromProduct,
          components: price.components,
          selector: baseSelector,
        };
      }
    }
    return null;
  }
}
