import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, CircularProgress, IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';

import SimpleTable from 'components/table/SimpleTable';
import { formatDate } from 'components/DateTime';
import { AVAILABILITY_LIST_QUERY } from './gql';
import { AvailabilityListViewFragment } from '../../__generated__/graphql';
import { ErrorAlert } from 'pages/error';

interface AvailabilityListProps {
  hotelId?: number;
  productId?: number | null;
  bundleId?: number | null;
  facilityId?: number | null;
}

export default function Availability(props: AvailabilityListProps) {
  return (
    <>
      <Helmet>
        <title>{i18next.t('availability-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AvailabilityEmbeddedTable {...props} />
        </Grid>
      </Grid>
    </>
  );
}

export function AvailabilityEmbeddedTable(props: AvailabilityListProps) {
  const [validFrom, setValidFrom] = useState(moment().startOf('week').toDate());

  const { loading, error, data, refetch } = useQuery(AVAILABILITY_LIST_QUERY, {
    variables: {
      hotelId: props.hotelId,
      validFrom: validFrom,
      validTo: moment(validFrom).endOf('week').toDate(),
      productId: props.productId,
      bundleId: props.bundleId,
      facilityId: props.facilityId,
    },
  });

  const _groupRows = (output: AvailabilityListViewFragment[]) => {
    const groups = _.groupBy(
      _.sortBy(output, o => `${o.hotel.name}-${o.product?.name}-${o.bundle?.name}-${o.facility?.name}`),
      o => `${o.hotel.id}-${o.product?.id}-${o.bundle?.id}-${o.facility?.id}`,
    );

    return Object.keys(groups).map(k => groups[k]);
  };

  const _drawLeadCell = (group: AvailabilityListViewFragment[]) => {
    if (group[0].product) return <Link to={`/products/products/${group[0].product.id}`}>{group[0].product.name}</Link>;
    if (group[0].facility) return <Link to={`/products/facilities/${group[0].facility.id}`}>{group[0].facility.name}</Link>;
    if (group[0].bundle) return <Link to={`/products/bundles/${group[0].bundle.id}`}>{group[0].bundle.name}</Link>;
    return '';
  };

  const _drawDateCell = (day: number, group: AvailabilityListViewFragment[]) => {
    const from = moment(validFrom).add(day, 'days').startOf('day').toDate();
    const to = moment(validFrom).add(day, 'days').endOf('day').toDate();

    const sameDayEntries = group.filter(g => {
      return moment(g.validFrom).isSameOrAfter(from) && moment(g.validFrom).isSameOrBefore(to);
    });
    if (sameDayEntries.length > 0) {
      return (
        <>
          {sameDayEntries[0].available}
          {sameDayEntries[0].total ? `/${sameDayEntries[0].total}` : ''}
        </>
      );
    }
    return '';
  };

  return (
    <SimpleTable
      headers={[
        '',
        ...(props.hotelId ? [] : ['']),
        <IconButton onClick={() => setValidFrom(moment(validFrom).subtract(1, 'week').startOf('week').toDate())}>
          <ArrowLeftIcon />
        </IconButton>,
        ...[...Array(7).keys()].map(d => formatDate(moment(validFrom).add(d, 'days').toDate())),
        <IconButton onClick={() => setValidFrom(moment(validFrom).add(1, 'week').startOf('week').toDate())}>
          <ArrowRightIcon />
        </IconButton>,
      ]}
      rows={
        data && data?.listAvailability
          ? _groupRows(data?.listAvailability).map(row => [
              ...(props.hotelId ? [] : [<Link to={`/settings/hotels/${row[0].hotel.id}`}>{row[0].hotel.name}</Link>]),
              _drawLeadCell(row),
              null,
              ...[...Array(7).keys()].map(d => _drawDateCell(d, row)),
              null,
            ])
          : loading
            ? [[null, null, ...[...Array(7).keys()].map(d => <CircularProgress />), null]]
            : error
              ? [[null, null, { span: 7, cell: <ErrorAlert err={error} /> }, null]]
              : []
      }
    />
  );
}
