import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Grid, CircularProgress } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';

import SimpleTable from 'components/table/SimpleTable';
import { allCountriesByCode } from 'languages';
import { filterSelector } from 'helper/filter';
import { userSelector } from 'helper/security';

import { HOTEL_LIST_QUERY } from '../settings/gql';
import { RedirectError } from 'pages/error';

export default function AvailabilityHotelSelection() {
  const filter = filterSelector();
  const user = userSelector()!;

  const { loading, error, data } = useQuery(HOTEL_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
    },
  });

  return (
    <>
      <Helmet>
        <title>{i18next.t('availability-hotels-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <RedirectError err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('hotels-list-header-name'),
                  i18next.t('hotels-list-header-businesscountry'),
                  i18next.t('hotels-list-header-refcode'),
                  i18next.t('hotels-list-header-integration'),
                  ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
                ]}
                rows={data.listHotels.map(row => [
                  <Link to={`hotel/${row.id}`}>{row.name}</Link>,
                  (allCountriesByCode[row.businessCountry] || { label: null }).label,
                  row.refCode,
                  row.integrations && row.integrations.length > 0 ? (
                    <Link to={`/settings/integration/hotels/${row.integrations[0].id}`}>{row.extRefCode}</Link>
                  ) : null,
                  ...(user.isSingleSpace ? [] : [row.space.name]),
                ])}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
