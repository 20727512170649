import React from 'react';
import { getEmojiFlag } from 'countries-list';
interface PriceProps {
  price: number;
  currency: string;
}

export function formatPrice(price: number, currency: string) {
  const format = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
  });
  return format.format(price);
}

export default function Price(props: PriceProps) {
  const { price, currency, ...rest } = props;

  return <>{formatPrice(price, currency)}</>;
}
