import _ from 'lodash';
import i18next from 'i18next';

export function extractClientErrors(err: any) {
  const result: string[] = [];

  if (_.isString(err)) {
    result.push(err)
  } else {
    if (err.graphQLErrors) {
      for (const gerr of err.graphQLErrors) {
        if (gerr.extensions && gerr.extensions.code === 'INTERNAL_SERVER_ERROR') {
          if (gerr.extensions.extensions && gerr.extensions.extensions.code) {
            result.push(`${i18next.t(`graphqlerror-${gerr.extensions.extensions.code}`, (gerr.extensions.extensions.args || {}) as any)}`);
          }
        } else {
          if (gerr.extensions && gerr.extensions.code && i18next.exists(`graphqlerror-${gerr.extensions.code}`)) {
            result.push(`${i18next.t(`graphqlerror-${gerr.extensions.code}`, (gerr.extensions.args || {}) as any)}`);
          } else if (gerr.message) {
            result.push(gerr.message);
          }
        }
      }
    }
    if (err.networkError && err.networkError.message) {
      result.push(`${i18next.t('error-generic')}`);
    }
    if (result.length === 0 && err.message) {
      result.push(err.message);
    }
  }
  return result;
}

export function extractClientErrorsSource(err: any) {
  const result: string[] = [];

  if (_.isString(err)) {
    result.push(err)
  } else {
    if (err.graphQLErrors) {
      for (const gerr of err.graphQLErrors) {
        result.push(gerr.message);
      }
    }
    if (err.networkError && err.networkError.message) {
      result.push(err.networkError.message);
    }
    if (result.length === 0 && err.message) {
      result.push(err.message);
    }
  }
  return result;
}
