import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './store/userSlice';
import { snackbarReducer } from './store/snackbarSlice';
import { filterReducer } from './store/filterSlice';

import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['snackbar'],
};
export const rootReducers = combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
  filter: filterReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: dm =>
    dm({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
setupListeners(store.dispatch);
export default store;
