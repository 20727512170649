import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import { Container, Box, Grid, Paper, Button, Toolbar, CircularProgress, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Card, CardContent, CardHeader } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { gql } from '__generated__/gql';

import { dispatchException } from 'helper/snackbar';
import { userSelector } from 'helper/security';
import { filterSelector } from 'helper/filter';
import { exportToFile, exportBase64ToFile } from 'helper/download';
import { formatDate } from 'components/DateTime';
import { formatPrice } from 'components/Price';
import { EExportFileType, EOfferStatus } from '__generated__/graphql';

const CHART_OFFERTIMELINE_QUERY = gql(`
  query ChartOfferTimeline($fromDate: Date!, $toDate: Date!, $spaceId: Int, $hotelId: Int) {
    chartOfferTimeline(fromDate: $fromDate, toDate: $toDate, spaceId: $spaceId, hotelId: $hotelId) {
      date
      offers {
        offerCount
        status
        totalPriceGross
        totalPriceNet
      }
    }
  }
`);

const EXPORT_OFFERLIST_QUERY = gql(`
  query ExportOfferListQuery($fileType: EExportFileType, $spaceId: Int, $hotelId: Int, $fromDate: Date, $toDate: Date) {
    exportOffersList(fileType: $fileType, spaceId: $spaceId, hotelId: $hotelId, fromDate: $fromDate, toDate: $toDate) {
      filecontent
      isBase64
      filename
      contentType
    }
  }
`);

export default function Dashboard() {
  const dispatch = useDispatch();
  const user = userSelector();
  const filter = filterSelector();

  const [exportOfferListQuery, { loading: exportOfferListLoading }] = useLazyQuery(EXPORT_OFFERLIST_QUERY);
  const [offerTimelineQuery] = useLazyQuery(CHART_OFFERTIMELINE_QUERY);

  const [offerTimelineRange, setOfferTimelineRange] = useState('halfyear');
  const [offerTimelineCount, setOfferTimelineCount] = useState(true);

  const [offerTimelineData, setOfferTimelineData] = useState(
    [...Array(30).keys()].map(d => ({
      date: formatDate(moment().subtract(30, 'day').add(d, 'day').toDate()),
      approved: 0,
      rejected: 0,
      pending: 0,
    })),
  );

  const _dateRange = () => {
    const fromDate =
      offerTimelineRange === 'week'
        ? moment().subtract(1, 'week').startOf('day').toDate()
        : offerTimelineRange === 'halfyear'
          ? moment().subtract(6, 'month').startOf('day').toDate()
          : offerTimelineRange === 'year'
            ? moment().subtract(1, 'year').startOf('day').toDate()
            : moment().subtract(1, 'month').startOf('day').toDate();
    const toDate = moment().add(1, 'day').startOf('day').toDate();
    return { fromDate, toDate };
  };

  useEffect(() => {
    const { fromDate, toDate } = _dateRange();

    offerTimelineQuery({
      variables: {
        fromDate,
        toDate,
        spaceId: (filter && filter.spaceId) || null,
        hotelId: (filter && filter.hotelId) || null,
      },
      fetchPolicy: 'network-only',
    }).then(res => {
      if (res.data && res.data.chartOfferTimeline) {
        setOfferTimelineData(
          res.data.chartOfferTimeline.map(tl => ({
            date: formatDate(tl.date),
            approved: offerTimelineCount
              ? tl.offers.find(o => o.status === EOfferStatus.APPROVED)?.offerCount || 0
              : tl.offers.find(o => o.status === EOfferStatus.APPROVED)?.totalPriceGross || 0,
            rejected: offerTimelineCount
              ? tl.offers.find(o => o.status === EOfferStatus.REJECTED)?.offerCount || 0
              : tl.offers.find(o => o.status === EOfferStatus.REJECTED)?.totalPriceGross || 0,
            pending: offerTimelineCount
              ? tl.offers.find(o => o.status === EOfferStatus.PENDING)?.offerCount || 0
              : tl.offers.find(o => o.status === EOfferStatus.PENDING)?.totalPriceGross || 0,
          })),
        );
      }
    });
  }, [offerTimelineRange, offerTimelineCount, filter]);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title={i18next.t('dashboard-offerlist-chart-header')} />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <RadioGroup value={offerTimelineRange} onChange={e => setOfferTimelineRange(e.target.value)} row>
                          <FormControlLabel value="week" control={<Radio />} label={i18next.t('dashboard-offerlist-chart-week')} />
                          <FormControlLabel value="month" control={<Radio />} label={i18next.t('dashboard-offerlist-chart-month')} />
                          <FormControlLabel value="halfyear" control={<Radio />} label={i18next.t('dashboard-offerlist-chart-halfyear')} />
                          <FormControlLabel value="year" control={<Radio />} label={i18next.t('dashboard-offerlist-chart-year')} />
                        </RadioGroup>
                        <RadioGroup
                          value={offerTimelineCount ? 0 : 1}
                          onChange={e => setOfferTimelineCount(e.target.value == '0' ? true : false)}
                          row
                        >
                          <FormControlLabel value={0} control={<Radio />} label={i18next.t('dashboard-offerlist-chart-count')} />
                          <FormControlLabel value={1} control={<Radio />} label={i18next.t('dashboard-offerlist-chart-price')} />
                        </RadioGroup>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <ResponsiveContainer height={500}>
                        <BarChart
                          width={500}
                          height={400}
                          data={offerTimelineData}
                          margin={{
                            top: 20,
                            right: 50,
                            left: 50,
                            bottom: 10,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" tickMargin={10} />
                          {offerTimelineCount && <YAxis />}
                          {!offerTimelineCount && (
                            <YAxis
                              tickFormatter={tick => {
                                return formatPrice(tick, 'EUR');
                              }}
                            />
                          )}
                          {offerTimelineCount && (
                            <Tooltip
                              labelFormatter={date => i18next.t('dashboard-offerlist-chart-tooltip-header', { date })}
                              formatter={(count: number) => `${i18next.t('dashboard-offerlist-chart-tooltip-count', { count })}`}
                            />
                          )}
                          {!offerTimelineCount && (
                            <Tooltip
                              labelFormatter={date => i18next.t('dashboard-offerlist-chart-tooltip-header', { date })}
                              formatter={(price: number) =>
                                `${i18next.t('dashboard-offerlist-chart-tooltip-price', { price: formatPrice(price, 'EUR') })}`
                              }
                            />
                          )}
                          <Legend />
                          <Bar name={`${i18next.t('dashboard-offerlist-chart-pending')}`} dataKey="pending" stackId="a" fill="#ac2a6e" />
                          <Bar name={`${i18next.t('dashboard-offerlist-chart-rejected')}`} dataKey="rejected" stackId="a" fill="#ff0000" />
                          <Bar name={`${i18next.t('dashboard-offerlist-chart-approved')}`} dataKey="approved" stackId="a" fill="#00ff00" />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        startIcon={exportOfferListLoading ? <CircularProgress size={24} /> : <TableChartIcon />}
                        disabled={exportOfferListLoading}
                        onClick={async () => {
                          const { fromDate, toDate } = _dateRange();
                          try {
                            const res = await exportOfferListQuery({
                              variables: {
                                fileType: EExportFileType.XLSX,
                                fromDate,
                                toDate,
                              },
                              fetchPolicy: 'network-only',
                            });
                            if (res.data) {
                              if (res.data.exportOffersList.isBase64) {
                                exportBase64ToFile(
                                  res.data?.exportOffersList.filecontent,
                                  res.data?.exportOffersList.contentType,
                                  res.data?.exportOffersList.filename,
                                );
                              } else {
                                exportToFile(
                                  res.data?.exportOffersList.filecontent,
                                  res.data?.exportOffersList.contentType,
                                  res.data?.exportOffersList.filename,
                                );
                              }
                            }
                          } catch (err) {
                            dispatchException(dispatch, err);
                          }
                        }}
                      >
                        {i18next.t('dashboard-offerlist-export')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
