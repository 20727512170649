import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import i18next from 'i18next';

interface InformationDialogProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  open: boolean;
  onConfirm: () => void;
  [x: string]: any;
}

export default function InformationDialog(props: InformationDialogProps) {
  const { title, children, open, onConfirm, ...rest } = props;
  return (
    <Dialog open={open} onClose={() => onConfirm()} aria-labelledby="confirm-dialog" {...rest}>
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm();
          }}
          color="primary"
        >
          {i18next.t('information-dialog-confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
