import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHotelFilter } from 'types';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    value: null as IHotelFilter | null,
  },
  reducers: {
    setFilter: (state, { payload }: PayloadAction<IHotelFilter>) => {
      state.value = payload;
    },
    resetFilter: state => {
      state.value = null;
    },
  },
});

export const { setFilter, resetFilter } = filterSlice.actions;
export const filterReducer = filterSlice.reducer;

export default filterSlice;
