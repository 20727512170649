import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';

import PagedTable from 'components/table/PagedTable';

import { CONTENTPRODUCTS_LIST_QUERY } from '../gql';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

export default function ContentProductsList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(CONTENTPRODUCTS_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('contentproducts-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  hotelId: (filter && filter.hotelId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('contentproducts-list-header-sku'),
              i18next.t('contentproducts-list-header-product'),
              i18next.t('contentproducts-list-header-position'),
              i18next.t('contentproducts-list-header-hotel'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
            ]}
            rows={(data?.listContentProductDetails || []).map(row => [
              <Link to={`/content/texts/${row.id}`}>{row.sku}</Link>,
              <>
                {row.product && <Link to={`/content/texts/${row.id}`}>{row.product.name}</Link>}
                {row.productBundle && <Link to={`/content/texts/${row.id}`}>{row.productBundle.name}</Link>}
                {row.facility && <Link to={`/content/texts/${row.id}`}>{row.facility.name}</Link>}
                {row.serviceType && <Link to={`/content/texts/${row.id}`}>{row.serviceType.name}</Link>}
              </>,
              `${i18next.t('enums-EContentProductDetailPosition-' + row.position)}`,
              row.hotel ? <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link> : '',
              ...(user.isSingleSpace ? [] : [row.space.name]),
            ])}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/content/texts/_create')}>
              {i18next.t('contentproducts-list-add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
