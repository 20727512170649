export const formatPrice = (price: number, language: string = 'de-AT', currency: string = 'EUR') => {
  if (isNaN(price)) return 'NaN';

  const format = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  });
  return format.format(price);
};
export const formatDate = (date: Date, language: string = 'de-AT') => {
  if (isNaN(date.getTime())) return 'NaD';

  const format = new Intl.DateTimeFormat(language);
  return format.format(date);
};

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}