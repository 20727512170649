import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, CircularProgress, Chip } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import BlockIcon from '@mui/icons-material/Block';

import SimpleTable from 'components/table/SimpleTable';
import { unicodeIndent } from 'components/security/SpaceSelectionInput';
import { FormatFromNow } from 'components/DateTime';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';
import { ISpace } from 'types';

import { USERS_LIST_QUERY } from '../gql';

import { ListUsersQuery } from '__generated__/graphql';
import { ErrorAlert } from 'pages/error';
type TListUsersElement = ListUsersQuery['listUsers'][0];

export default function UsersList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  console.log(filter);

  const { loading, error, data } = useQuery(USERS_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });

  const users = useMemo(() => {
    const users = [] as TListUsersElement[];

    const _addSpace = (space: ISpace, level: number = 0) => {
      const spaceUsers = (data?.listUsers || []).filter(u => u.space && u.space.id === space.id);
      for (const spaceUser of spaceUsers) {
        users.push({
          ...spaceUser,
          name: `${unicodeIndent(level)} ${spaceUser.name}`,
        });
      }
      const childSpaces = (user?.spaces || []).filter(s => s.parent?.id === space.id);
      for (const childSpace of childSpaces) {
        _addSpace(childSpace, level + 1);
      }
    };

    const rootSpaces = (user?.spaces || []).filter(s => !s.parent);
    for (const rootSpace of rootSpaces) {
      _addSpace(rootSpace, 0);
    }

    return [...(data?.listUsers || []).filter(u => users.findIndex(eu => u.id === eu.id) < 0), ...users];
  }, [data, user]);

  return (
    <>
      <Helmet>
        <title>{i18next.t('users-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('users-list-header-name'),
                  i18next.t('users-list-header-username'),
                  i18next.t('users-list-header-email'),
                  ...(user.isSingleSpace ? [i18next.t('users-list-header-isadmin')] : [i18next.t('list-header-space')]),
                  i18next.t('users-list-header-islocked'),
                  i18next.t('users-list-header-lastseen'),
                  ...(user.isSingleSpace ? [] : [i18next.t('users-list-header-memberships')]),
                ]}
                rows={users.map(row => [
                  <Link to={`/settings/security/users/${row.id}`}>{row.name}</Link>,
                  row.username,
                  row.email,
                  ...(user.isSingleSpace
                    ? [row.isAdmin ? <SupervisorAccountIcon /> : null]
                    : [
                        <Chip
                          icon={row.isAdmin ? <SupervisorAccountIcon /> : <PersonIcon />}
                          label={row.space?.name || i18next.t('space-seminargo')}
                          {...(row.space ? { onClick: () => navigate(`/settings/security/spaces/${row.space!.id}`) } : {})}
                        />,
                      ]),
                  row.isLocked && <BlockIcon />,
                  <FormatFromNow date={row.lastSeen} />,
                  ...(user.isSingleSpace
                    ? []
                    : [
                        row.memberships
                          .filter(m => m.space.id !== row.space?.id)
                          .map((m, i) => (
                            <Chip
                              key={i}
                              icon={m.isAdmin ? <SupervisorAccountIcon /> : <PersonIcon />}
                              label={m.space.name}
                              onClick={() => navigate(`/settings/security/spaces/${m.space.id}`)}
                            />
                          )),
                      ]),
                ])}
              />
            </Grid>
            {hasAnyAdminSpace(user) && (
              <Grid item xs={12}>
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/settings/security/users/_create')}
                >
                  {i18next.t('users-list-add')}
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
