import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, IconButton, Button, Grid, CircularProgress } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { allLanguages } from 'languages';
import { userSelector, canEditAdminRecord, initialSpaceId } from 'helper/security';
import { filterSelector } from 'helper/filter';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import { HotelSelectionInput } from 'components/security/HotelSelectionInput';

import {
  CONTENTPRODUCT_VIEW_QUERY,
  UPDATE_CONTENTPRODUCT_MUTATION,
  CREATE_CONTENTPRODUCT_MUTATION,
  REFETCH_CONTENTPRODUCTS_QUERIES,
  EVICT_CONTENTPRODUCTS_QUERIES,
} from '../gql';
import { ContentProductDetailListOutput, HotelListOutput, EContentProductDetailPosition } from '__generated__/graphql';
import { HOTEL_LIST_QUERY } from '../../settings/gql';
import { RedirectError } from 'pages/error';

interface ContentProductProps {
  id: number;
}
interface ContentProductCreateProps {
  sku?: string | null;
}
interface ContentProductFormProps {
  data: ContentProductDetailListOutput;
  hotels: HotelListOutput[];
}

const textSchema = yup.object().shape({
  language: yup.string(),
  text: yup.string().required().label(i18next.t('contentproduct-text-text')),
});

const validationSchema = yup.object().shape({
  spaceId: yup.number().required().label(i18next.t('field-space')),
  sku: yup.string().required().label(i18next.t('contentproduct-sku')),
  position: yup.string().required().label(i18next.t('contentproduct-position')),
  hotelId: yup.number(),
  texts: yup.array().required().min(1).of(textSchema),
});

function ContentProductForm(props: ContentProductFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateMutateFunction] = useMutation(UPDATE_CONTENTPRODUCT_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_CONTENTPRODUCT_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/content/texts/${createdId}`);
  }, [createdId]);

  type ContentProductFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: ContentProductDetailListOutput): ContentProductFormType => ({
    ...obj,
    spaceId: obj.space.id,
    hotelId: obj.hotel ? obj.hotel.id : 0,
    texts: obj.texts.map(tt => ({
      language: tt.language ? `${tt.language}` : '',
      text: tt.text,
    })),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema(props.data),
  });

  const {
    fields: textsFields,
    append: textsAppend,
    remove: textsRemove,
  } = useFieldArray({
    control,
    name: 'texts',
  });
  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: ContentProductFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              sku: values.sku,
              position: values.position as EContentProductDetailPosition,
              hotelId: values.hotelId && values.hotelId > 0 ? values.hotelId! : null,
              texts: values.texts.map(c => ({
                text: c.text,
                language: c.language || null,
              })),
            },
          },
          update: cache => EVICT_CONTENTPRODUCTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_CONTENTPRODUCTS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateContentProduct || {}) as ContentProductDetailListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              sku: values.sku,
              position: values.position as EContentProductDetailPosition,
              hotelId: values.hotelId && values.hotelId > 0 ? values.hotelId! : null,
              texts: values.texts.map(c => ({
                text: c.text,
                language: c.language || null,
              })),
            },
          },
          update: cache => EVICT_CONTENTPRODUCTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_CONTENTPRODUCTS_QUERIES(),
        });
        reset(toFormSchema((res.data!.createContentProduct || {}) as ContentProductDetailListOutput));
        setCreatedId(res.data!.createContentProduct.id);
      }
      dispatchMessage(dispatch, i18next.t('contentproduct-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('contentproducts-list-page-title')} {props.data.id > 0 ? props.data.sku : ''}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="sku" control={control} label={i18next.t('contentproduct-sku')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HotelSelectionInput
            label={i18next.t('contentproduct-hotel')}
            name="hotelId"
            control={control}
            disabled={!canEdit}
            hotels={props.hotels.filter(h => h.space.id === watch('spaceId'))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="position"
            control={control}
            label={i18next.t('contentproduct-position')}
            options={Object.keys(EContentProductDetailPosition).map(p => ({
              value: p,
              label: `${i18next.t('enums-EContentProductDetailPosition-' + p)}`,
            }))}
            required
            disabled={!canEdit}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {props.data.product && <Link to={`/products/products/${props.data.product.id}#texts`}>{props.data.product.name}</Link>}
          {props.data.productBundle && <Link to={`/products/bundles/${props.data.productBundle.id}#texts`}>{props.data.productBundle.name}</Link>}
          {props.data.facility && <Link to={`/products/facilities/${props.data.facility.id}#texts`}>{props.data.facility.name}</Link>}
          {props.data.serviceType && <Link to={`/servicetypes/${props.data.serviceType.id}#texts`}>{props.data.serviceType.name}</Link>}
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            headers={[i18next.t('contentproduct-texts-tab')]}
            tabs={[
              <>
                <SimpleTable
                  rows={textsFields.map((field, index) => [
                    <FormInputDropdown
                      key={`${field.id}.language`}
                      name={`texts.${index}.language`}
                      control={control}
                      label={i18next.t('contentproduct-text-language')}
                      options={[
                        {
                          label: <em>{i18next.t('generic-selection-empty')}</em>,
                          value: '',
                        },
                        ...allLanguages.map(l => (l ? { value: l.code, label: l.label } : ({ divider: true } as any))),
                      ]}
                      disabled={!canEdit}
                    />,
                    <FormInputText
                      key={`${field.id}.text`}
                      name={`texts.${index}.text`}
                      control={control}
                      label={i18next.t('contentproduct-text-text')}
                      textFieldProps={watch('position') === 'DETAILS' ? { fullWidth: true, multiline: true, rows: 5 } : { fullWidth: true }}
                      required
                      disabled={!canEdit}
                    />,
                    ...(canEdit
                      ? [
                          <IconButton onClick={() => textsRemove(index)}>
                            <DeleteIcon />
                          </IconButton>,
                        ]
                      : []),
                  ])}
                />
                {canEdit && (
                  <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => textsAppend({ language: '', text: '' })}>
                    {i18next.t('contentproduct-text-add')}
                  </Button>
                )}
              </>,
            ]}
          />
        </Grid>
        {canEdit && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('contentproduct-save')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default function ContentProduct(props: ContentProductProps) {
  const contentQuery = useQuery(CONTENTPRODUCT_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);

  const loading = contentQuery.loading || hotelsQuery.loading;
  const error = contentQuery.error || hotelsQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ContentProductForm
        data={contentQuery.data!.viewContentProductDetails as ContentProductDetailListOutput}
        hotels={hotelsQuery.data!.listHotels as HotelListOutput[]}
      />
    );
}

export function ContentProductCreate(props: ContentProductCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);

  const loading = hotelsQuery.loading;
  const error = hotelsQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ContentProductForm
        data={
          {
            id: -1,
            sku: props.sku || '',
            position: EContentProductDetailPosition.HEADER,
            medias: [],
            texts: [{ id: -1, language: '', text: '' }],
            space: { id: initialSpaceId(user, filter) } as any,
          } as any
        }
        hotels={hotelsQuery.data!.listHotels as HotelListOutput[]}
      />
    );
}
