import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';

import { useQuery } from '@apollo/client';
import { SKU_QUERY } from './gql';
import { filterSelector } from 'helper/filter';
import { ErrorAlert, RedirectError } from 'pages/error';

interface SKUProps {
  sku: string;
}

export default function SKU(props: SKUProps) {
  const navigate = useNavigate();

  const skuQuery = useQuery(SKU_QUERY, {
    variables: {
      sku: props.sku,
    },
  });

  const loading = skuQuery.loading;
  const error = skuQuery.error;

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;
  else if (skuQuery.data!.listProducts.length > 0) {
    navigate(`/products/products/${skuQuery.data!.listProducts[0].id}`);
    return null;
  } else if (skuQuery.data!.listProductBundles.length > 0) {
    navigate(`/products/bundles/${skuQuery.data!.listProductBundles[0].id}`);
    return null;
  } else if (skuQuery.data!.listFacilities.length > 0) {
    navigate(`/products/facilities/${skuQuery.data!.listFacilities[0].id}`);
    return null;
  } else if (skuQuery.data!.listServiceTypes.length > 0) {
    navigate(`/servicetypes/${skuQuery.data!.listServiceTypes[0].id}`);
    return null;
  } else {
    return <ErrorAlert err={`SKU {props.sku} invalid`} />;
  }
}
