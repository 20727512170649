import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

export const AVAILABILITY_LIST_VIEW_FRAGMENT = gql(`
  fragment AvailabilityListView on AvailabilityListOutput {
    id
    validFrom
    validTo
    available
    total
    range
    hotel { id name extRefCode }
    bundle { id name extRefCode }
    product { id name extRefCode }
    facility { id name extRefCode }
    space { ...SpaceShort }
  }
`);

export const AVAILABILITY_LIST_QUERY = gql(`
  query ListAvailability($hotelId: Int, $validFrom: Date, $validTo: Date, $productId: Int, $bundleId: Int, $facilityId: Int) {
    listAvailability(hotelId: $hotelId, validFrom: $validFrom, validTo: $validTo, productId: $productId, bundleId: $bundleId, facilityId: $facilityId) {
      ...AvailabilityListView
    }
  }
`);

export const AVAILABILITY_VIEW_QUERY = gql(`
  query ViewAvailability($id: Int!) {
    viewAvailability(id: $id) {
      ...AvailabilityListView
    }
  }
`);

export const UPDATE_AVAILABILTIY_MUTATION = gql(`
  mutation UpdateAvailability($id: Int!, $data: AvailabilityInput!) {
    updateAvailability(id: $id, data: $data) {
      ...AvailabilityListView
    }
  }
`);

export const EVICT_AVAILABILTIY_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listAvailability' });
  cache.gc();
};

export const REFETCH_AVAILABILTIY_QUERIES = (id?: number | undefined) => [
  ...(id
    ? [
        {
          query: AVAILABILITY_VIEW_QUERY,
          variables: { id },
        },
      ]
    : []),
];
