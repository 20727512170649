import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Grid, CircularProgress, Card, CardContent, CardHeader } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import LockResetIcon from '@mui/icons-material/LockReset';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputPassword } from 'components/form/FormInputText';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import { CHANGE_USERPASSWORD_MUTATION } from '../gql';
import { ME_QUERY } from 'layout/login';

import { User } from '__generated__/graphql';
import { RedirectError } from 'pages/error';

interface MeFormProps {
  data: User;
}

const changePasswordValidationSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword1: yup.string().required(),
  newPassword2: yup
    .string()
    .oneOf([yup.ref('newPassword1')], () => `${i18next.t('me-password-not-matching')}`)
    .required(),
});

function MeForm(props: MeFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [changePasswordMutateFunction] = useMutation(CHANGE_USERPASSWORD_MUTATION);

  type ChangePasswordFormType = yup.InferType<typeof changePasswordValidationSchema>;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { isDirty, isValidating, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(changePasswordValidationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: { oldPassword: '', newPassword1: '', newPassword2: '' },
  });

  const onSubmit = async (values: ChangePasswordFormType) => {
    try {
      const res = await changePasswordMutateFunction({
        variables: {
          id: props.data.id,
          password: values.oldPassword,
          newPassword: values.newPassword1,
        },
      });
      if (res.data?.changeUserPassword) {
        reset({ oldPassword: '', newPassword1: '', newPassword2: '' });
        dispatchMessage(dispatch, i18next.t('me-password-changed'));
        navigate('/login');
      } else {
        dispatchException(dispatch, i18next.t('me-password-notchanged'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>{i18next.t('me-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={i18next.t('me-password-header')} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormInputPassword name="oldPassword" control={control} label={i18next.t('me-password-oldpassword')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputPassword name="newPassword1" control={control} label={i18next.t('me-password-newpassword1')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputPassword name="newPassword2" control={control} label={i18next.t('me-password-newpassword2')} required />
                </Grid>
                <Grid item xs={12}>
                  <ConfirmationButton
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    startIcon={isSubmitting ? <CircularProgress size={24} /> : <LockResetIcon />}
                    disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating || !isValid}
                    confirmationQuestion={i18next.t('me-confirm-password')}
                    confirmationTitle={i18next.t('me-confirm-password-title')}
                    onConfirm={async () => {
                      const valid = await trigger();
                      if (valid) {
                        handleSubmit(onSubmit)();
                      }
                    }}
                  >
                    {i18next.t('me-password')}
                  </ConfirmationButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default function Me() {
  const meQuery = useQuery(ME_QUERY);

  const loading = meQuery.loading;
  const error = meQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <MeForm data={meQuery.data!.me as User} />;
}
