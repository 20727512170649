import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Typography } from '@mui/material';

import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { extractClientErrors } from 'helper/apollo';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';

import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputFileUpload } from 'components/form/FormInputFileUpload';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import SimpleTable from 'components/table/SimpleTable';

import { IMPORT_PRODUCTS_MUTATION, EXPORT_PRODUCTS_MUTATION, REFETCH_IMPORT_PRODUCTS_QUERIES, EVICT_IMPORT_PRODUCTS_QUERIES } from './gql';
import {
  ProductListOutput,
  ProductBundleListOutput,
  FacilityListOutput,
  ServiceTypeListOutput,
  EProductImportMergeMode,
} from '__generated__/graphql';
import { filterSelector } from 'helper/filter';
import { userSelector, initialSpaceId } from 'helper/security';

interface ImportFormProps {}

const validationSchema = yup.object().shape({
  mode: yup.string().required(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  file: yup.mixed().required(),
});

export function ImportForm(props: ImportFormProps) {
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const [importMutateFunction] = useMutation(IMPORT_PRODUCTS_MUTATION);
  const [exportMutateFunction] = useMutation(EXPORT_PRODUCTS_MUTATION);

  const [importResult, setImportResult] = useState({
    importMsg: undefined as string | undefined,
    products: [] as ProductListOutput[],
    bundles: [] as ProductBundleListOutput[],
    facilities: [] as FacilityListOutput[],
    serviceTypes: [] as ServiceTypeListOutput[],
  });

  type ImportFormType = yup.InferType<typeof validationSchema>;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mode: EProductImportMergeMode.UPDATE,
      spaceId: initialSpaceId(user, filter) || 0,
    },
  });

  const onSubmit = async (values: ImportFormType) => {
    try {
      const res = await importMutateFunction({
        variables: {
          spaceId: values.spaceId,
          mode: values.mode as EProductImportMergeMode,
          jsonText: (values.file as any).content,
        },
        update: cache => EVICT_IMPORT_PRODUCTS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_IMPORT_PRODUCTS_QUERIES(),
      });
      reset({ mode: values.mode, file: null } as any);
      setImportResult({
        importMsg: `${i18next.t('products-import-ready')}`,
        products: (res.data?.importProducts?.products as ProductListOutput[]) || [],
        bundles: (res.data?.importProducts?.bundles as ProductBundleListOutput[]) || [],
        facilities: (res.data?.importProducts?.facilities as FacilityListOutput[]) || [],
        serviceTypes: (res.data?.importProducts?.serviceTypes as ServiceTypeListOutput[]) || [],
      });
      dispatchMessage(dispatch, i18next.t('products-import-ready'));
    } catch (err) {
      dispatchException(dispatch, err);
      const errs = extractClientErrors(err);
      setImportResult({
        importMsg: errs.join('\r\n'),
        products: [],
        bundles: [],
        facilities: [],
        serviceTypes: [],
      });
    }
  };

  if (importResult.importMsg) {
    return (
      <>
        <Helmet>
          <title>{i18next.t('products-import-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{i18next.t('products-import-result-header')}</Typography>
          </Grid>
          <Grid item xs={12}>
            {importResult.importMsg}
          </Grid>
          <Grid item xs={12} sm={3}>
            {i18next.t('products-import-result-products', {
              productCount: `${importResult.products.length}`,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {i18next.t('products-import-result-bundles', {
              bundleCount: `${importResult.bundles.length}`,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {i18next.t('products-import-result-facilities', {
              facilityCount: `${importResult.facilities.length}`,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {i18next.t('products-import-result-servicetypes', {
              serviceTypeCount: `${importResult.serviceTypes.length}`,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            <SimpleTable rows={importResult.products.map(row => [<Link to={`/products/products/${row.id}`}>{row.name}</Link>, row.sku])} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SimpleTable rows={importResult.bundles.map(row => [<Link to={`/products/bundles/${row.id}`}>{row.name}</Link>, row.sku])} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SimpleTable rows={importResult.facilities.map(row => [<Link to={`/products/facilities/${row.id}`}>{row.name}</Link>, row.sku])} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SimpleTable rows={importResult.serviceTypes.map(row => [<Link to={`/servicetypes/${row.id}`}>{row.name}</Link>, row.sku])} />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<ImportExportIcon />}
              onClick={async () => {
                setImportResult({
                  importMsg: undefined,
                  products: [],
                  bundles: [],
                  facilities: [],
                  serviceTypes: [],
                });
              }}
            >
              {i18next.t('products-import-start')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>{i18next.t('products-import-page-title')}</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormInputDropdown
              name="mode"
              control={control}
              label={i18next.t('products-import-mode')}
              options={Object.keys(EProductImportMergeMode).map(p => ({
                value: p,
                label: `${i18next.t('enums-EProductImportMergeMode-' + p)}`,
              }))}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SpaceSelectionInput checkAdmin name="spaceId" control={control} disabled={user.isSingleAdminSpace} required />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <FormInputFileUpload
              control={control}
              label={i18next.t('products-import-file')}
              name="file"
              accept="application/json"
              binary={() => false}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <ImportExportIcon />}
              disabled={!isDirty || isSubmitting || isValidating || !((filter && filter.spaceId) || user.space?.id)}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('products-import-run')}
            </Button>
            <Button
              variant="contained"
              startIcon={<ImportExportIcon />}
              onClick={async () => {
                try {
                  const res = await exportMutateFunction({
                    variables: {
                      spaceId: (filter && filter.spaceId) || null,
                    },
                  });
                  if (res.data?.exportProducts) {
                    exportToFile(res.data?.exportProducts, 'application/json', 'seminargo-products.json');
                  }
                  dispatchMessage(dispatch, i18next.t('products-export-ready'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('products-export-run')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default function Import() {
  return <ImportForm />;
}
