import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'types';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null as IUser | null,
  },
  reducers: {
    setUser: (state, { payload }: PayloadAction<IUser>) => {
      state.value = payload;
    },
    resetUser: state => {
      state.value = null;
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export const userReducer = userSlice.reducer;

export default userSlice;
