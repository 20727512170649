import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { EnqueueSnackbarArgs, removeSnackbar } from 'store/snackbarSlice';

let displayed = [] as any[];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector<any, any>(store => store.snackbar.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications
      .filter((n: Notification) => n)
      .forEach(({ key, message, options = {} }: EnqueueSnackbarArgs) => {
        // if (dismissed) {
        //     // dismiss snackbar using notistack
        //     closeSnackbar(key);
        //     return;
        // }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
